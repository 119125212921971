export const actionTypes = {
  START_NEW_SUCCESS: 's-START_NEW_SUCCESS',
  START_NEW_FAILURE: 's-START_NEW_FAILURE',

  SET_META_DATA: 's-SET_META_DATA',
  SET_META_DATA_FIELD: 's-SET_META_DATA_FIELD',
  RESET_META_DATA: 's-RESET_META_DATA',
  UPDATE_TIME_SLOT_SELECTION: 's-UPDATE_TIME_SLOT_SELECTION',

  ADD_NEW_START: 's-ADD_NEW_START',
  ADD_NEW_SUCCESS: 's-ADD_NEW_SUCCESS',
  ADD_NEW_FAILURE: 's-ADD_NEW_FAILURE',

  LOAD_START: 's-LOAD_START',
  LOAD_SUCCESS: 's-LOAD_SUCCESS',
  LOAD_FAILURE: 's-LOAD_FAILURE',

  LOAD_JOURNAL_START: 's-LOAD_JOURNAL_START',
  LOAD_JOURNAL_SUCCESS: 's-LOAD_JOURNAL_SUCCESS',
  LOAD_JOURNAL_FAILURE: 's-LOAD_JOURNAL_FAILURE',

  RESET: 's-RESET',
  RESET_ID: 's-RESET_ID',

  CANCEL_START: 's-CANCEL_START',
  CANCEL_SUCCESS: 's-CANCEL_SUCCESS',
  CANCEL_FAILURE: 's-CANCEL_FAILURE',

  SYNC_START: 's-SYNC_START',
  SYNC_SUCCESS: 's-SYNC_SUCCESS',
  SYNC_FAILURE: 's-SYNC_FAILURE',

  EIDR_FETCH_START: 'S-EIDR_FETCH_START',
  EIDR_FETCH_SUCCESS: 'S-EIDR_FETCH_SUCCESS',
  EIDR_FETCH_FAILURE: 'S-EIDR_FETCH_FAILURE',
  EIDR_CLEAR: 'S-EDIR_CLEAR',

  LOAD_PRODUCT_TREE_START: 's-LOAD_PRODUCT_TREE_START',
  LOAD_PRODUCT_TREE_SUCCESS: 's-LOAD_PRODUCT_TREE_SUCCESS',
  LOAD_PRODUCT_TREE_SUCCESS_V2: 's-LOAD_PRODUCT_TREE_SUCCESS_V2',
  LOAD_PRODUCT_TREE_FAILURE: 's-LOAD_PRODUCT_TREE_FAILURE',

  GET_CERTIFICATE_START: 'GET_CERTIFICATE_START',
  GET_CERTIFICATE_SUCCESS: 'GET_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_FAILURE: 'GET_CERTIFICATE_FAILURE',

  GET_CERTIFICATE_LIST_START: 'GET_CERTIFICATE_LIST_START',
  GET_CERTIFICATE_LIST_SUCCESS: 'GET_CERTIFICATE_LIST_SUCCESS',
  GET_CERTIFICATE_LIST_FAILURE: 'GET_CERTIFICATE_LIST_FAILURE',

  GET_VIDEO_ASSET_LIST_START: 'GET_VIDEO_ASSET_LIST_START',
  GET_VIDEO_ASSET_LIST_SUCCESS: 'GET_VIDEO_ASSET_LIST_SUCCESS',
  GET_VIDEO_ASSET_LIST_FAILURE: 'GET_VIDEO_ASSET_LIST_FAILURE',

  DOWNLOAD_VIDEO_ASSET_START: 'DOWNLOAD_VIDEO_ASSET_START',
  DOWNLOAD_VIDEO_ASSET_SUCCESS: 'DOWNLOAD_VIDEO_ASSET_SUCCESS',
  DOWNLOAD_VIDEO_ASSET_FAILURE: 'DOWNLOAD_VIDEO_ASSET_FAILURE',

  SIGNAL_START: 's-SIGNAL_START',
  SIGNAL_SUCCESS: 's-SIGNAL_SUCCESS',
  SIGNAL_FAILURE: 's-SIGNAL_FAILURE',

  GET_UPDATABLE_META_FIELDS_START: 's-GET_UPDATABLE_META_FIELDS_START',
  GET_UPDATABLE_META_FIELDS_SUCCESS: 's-GET_UPDATABLE_META_FIELDS_SUCCESS',
  GET_UPDATABLE_META_FIELDS_FAILURE: 's-GET_UPDATABLE_META_FIELDS_FAILURE',

  VALIDATE_META: 's-VALIDATE_META',
  VALIDATE_META_FIELD: 's-VALIDATE_META_FIELD',
  VALIDATE_EPISODES_META: 's-VALIDATE_EPISODES_META',
  VALIDATE_EPISODE_META_FIELD: 's-VALIDATE_EPISODE_META_FIELD',

  LOAD_PRODUCT_PARAMS_START: 's-LOAD_PRODUCT_PARAMS_START',
  LOAD_PRODUCT_PARAMS_SUCCESS: 's-LOAD_PRODUCT_PARAMS_SUCCESS',
  LOAD_PRODUCT_PARAMS_FAILURE: 's-LOAD_PRODUCT_PARAMS_FAILURE',

  START_FETCH_SUBMISSION_ADDITION_INFORMATIONS: 'START_FETCH_SUBMISSION_ADDITION_INFORMATIONS',
  SUCCESS_FETCH_SUBMISSION_ADDITION_INFORMATIONS: 'SUCCESS_FETCH_SUBMISSION_ADDITION_INFORMATIONS',
  FAILED_FETCH_SUBMISSION_ADDITION_INFORMATIONS: 'FAILED_FETCH_SUBMISSION_ADDITION_INFORMATIONS',

  INCREMENT_EPISODE: 'INCREMENT_EPISODE',
  INCREMENT_VERSION_NUMBER: 'INCREMENT_VERSION_NUMBER',

  CHECK_META_MODIFICATION_START: 's-CHECK_META_MODIFICATION_START',
  CHECK_META_MODIFICATION_SUCCESS: 's-CHECK_META_MODIFICATION_SUCCESS',
  CHECK_META_MODIFICATION_FAILURE: 's-CHECK_META_MODIFICATION_FAILURE',

  GET_INVOICE_PDF_START: 'GET_INVOICE_PDF_START',
  GET_INVOICE_PDF_SUCCESS: 'GET_INVOICE_PDF_SUCCESS',
  GET_INVOICE_PDF_FAILURE: 'GET_INVOICE_PDF_FAILURE',

  ADD_EPISODE: 'ADD_EPISODE',
  DELETE_EPISODE: 'DELETE_EPISODE',
  DELETE_EPISODES: 'DELETE_EPISODES',
  SET_EPISODE_METADATA: 'SET_EPISODE_METADATA',
};

export const setMetaData = metaData => ({
  type: actionTypes.SET_META_DATA,
  payload: { meta: metaData },
});
export const resetMetaData = () => ({
  type: actionTypes.RESET_META_DATA,
});
export const resetSubmissionId = () => ({
  type: actionTypes.RESET_ID,
});
export const updateTimeSlotSelection = (taskOutput) => ({
  type: actionTypes.UPDATE_TIME_SLOT_SELECTION,
  payload: taskOutput,
});
export const setMetaDataField = data => ({
  type: actionTypes.SET_META_DATA_FIELD,
  payload: data,
});

export const startNewSuccess = (clientOrderId) => ({
  type: actionTypes.START_NEW_SUCCESS,
  payload: { clientOrderId },
});

export const startNewFailure = () => ({
  type: actionTypes.START_NEW_FAILURE,
});

export const addNewStart = () => ({
  type: actionTypes.ADD_NEW_START,
});
export const addNewSuccess = submission => ({
  type: actionTypes.ADD_NEW_SUCCESS,
  payload: { submission, updateOriginal: true },
});
export const addNewFailure = () => ({
  type: actionTypes.ADD_NEW_FAILURE,
});

export const loadStart = (id, options) => ({
  type: actionTypes.LOAD_START,
  payload: { id, options },
});
export const loadSuccess = (submission) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: { submission, updateOriginal: true },
});
export const loadFailure = (payload) => ({
  type: actionTypes.LOAD_FAILURE,
  payload,
});

export const loadJournalStart = (id) => ({
  type: actionTypes.LOAD_JOURNAL_START,
  payload: { id },
});
export const loadJournalSuccess = (journal) => ({
  type: actionTypes.LOAD_JOURNAL_SUCCESS,
  payload: { journal },
});
export const loadJournalFailure = (payload) => ({
  type: actionTypes.LOAD_JOURNAL_FAILURE,
  payload,
});

export const reset = () => ({
  type: actionTypes.RESET,
});

export const cancelStart = id => ({
  type: actionTypes.CANCEL_START,
  payload: { id },
});
export const cancelSuccess = id => ({
  type: actionTypes.CANCEL_SUCCESS,
  payload: { id },
});
export const cancelFailure = () => ({
  type: actionTypes.CANCEL_FAILURE,
});

export const syncStart = id => ({
  type: actionTypes.SYNC_START,
  payload: { id },
});
export const syncSuccess = id => ({
  type: actionTypes.SYNC_SUCCESS,
  payload: { id },
});
export const syncFailure = () => ({
  type: actionTypes.SYNC_FAILURE,
});

export const eidrFetchStart = () => ({
  type: actionTypes.EIDR_FETCH_START,
});
export const eidrFetchSuccess = (eidr, eidrEditableFields) => ({
  type: actionTypes.EIDR_FETCH_SUCCESS,
  payload: { eidr, eidrEditableFields },
});
export const eidrFetchFailure = error => ({
  type: actionTypes.EIDR_FETCH_FAILURE,
  payload: error,
});
export const eidrClear = () => ({
  type: actionTypes.EIDR_CLEAR,
});

export const loadProductTreeStart = () => ({
  type: actionTypes.LOAD_PRODUCT_TREE_START,
});
export const loadProductTreeSuccess = (result) => ({
  type: actionTypes.LOAD_PRODUCT_TREE_SUCCESS,
  payload: { result },
});
export const loadProductTreeSuccessV2 = (result) => ({
  type: actionTypes.LOAD_PRODUCT_TREE_SUCCESS_V2,
  payload: { result },
});
export const loadProductTreeFailure = () => ({
  type: actionTypes.LOAD_PRODUCT_TREE_FAILURE,
});

export const loadProductParamsStart = productPath => ({
  type: actionTypes.LOAD_PRODUCT_PARAMS_START,
  payload: { productPath },
});
export const loadProductParamsSuccess = (result) => ({
  type: actionTypes.LOAD_PRODUCT_PARAMS_SUCCESS,
  payload: { result },
});
export const loadProductParamsFailure = () => ({
  type: actionTypes.LOAD_PRODUCT_PARAMS_FAILURE,
});

export const getCertificateStart = (certType, version) => ({
  type: actionTypes.GET_CERTIFICATE_START,
  certType,
  version,
});
export const getCertificateSuccess = (certType, version) => ({
  type: actionTypes.GET_CERTIFICATE_SUCCESS,
  certType,
  version,
});
export const getCertificateFailure = (certType, version) => ({
  type: actionTypes.GET_CERTIFICATE_FAILURE,
  certType,
  version,
});

export const getCertificateListStart = () => ({
  type: actionTypes.GET_CERTIFICATE_LIST_START,
});
export const getCertificateListSuccess = (payload) => ({
  type: actionTypes.GET_CERTIFICATE_LIST_SUCCESS,
  payload,
});
export const getCertificateListFailure = () => ({
  type: actionTypes.GET_CERTIFICATE_LIST_FAILURE,
});

export const getVideoAssetListStart = () => ({
  type: actionTypes.GET_VIDEO_ASSET_LIST_START,
});
export const getVideoAssetListSuccess = (payload) => ({
  type: actionTypes.GET_VIDEO_ASSET_LIST_SUCCESS,
  payload,
});
export const getVideoAssetListFailure = () => ({
  type: actionTypes.GET_VIDEO_ASSET_LIST_FAILURE,
});

export const downloadVideoAssetStart = () => ({
  type: actionTypes.DOWNLOAD_VIDEO_ASSET_START,
});
export const downloadVideoAssetSuccess = (payload) => ({
  type: actionTypes.DOWNLOAD_VIDEO_ASSET_SUCCESS,
  payload,
});
export const downloadVideoAssetFailure = () => ({
  type: actionTypes.DOWNLOAD_VIDEO_ASSET_FAILURE,
});

export const signalStart = ({ signalName, inputData }) => ({
  type: actionTypes.SIGNAL_START,
  payload: { signalName, inputData },
});
export const signalSuccess = () => ({
  type: actionTypes.SIGNAL_SUCCESS,
});
export const signalFailure = () => ({
  type: actionTypes.SIGNAL_FAILURE,
});

export const getUpdatableMetaFieldsStart = (workOrder) => ({
  type: actionTypes.GET_UPDATABLE_META_FIELDS_START,
  payload: { workOrder },
});
export const getUpdatableMetaFieldsSuccess = (result) => ({
  type: actionTypes.GET_UPDATABLE_META_FIELDS_SUCCESS,
  payload: result,
});
export const getUpdatableMetaFieldsFailure = () => ({
  type: actionTypes.GET_UPDATABLE_META_FIELDS_FAILURE,
});

export const validateMeta = (meta, options = { withoutRCD: false }) => ({
  type: actionTypes.VALIDATE_META, meta, options,
});

export const validateMetaField = (meta, field) => ({
  type: actionTypes.VALIDATE_META_FIELD, meta, field,
});

export const validateEpisodesMeta = () => ({
  type: actionTypes.VALIDATE_EPISODES_META,
});

export const validateEpisodeMetaField = (field, episodeId) => ({
  type: actionTypes.VALIDATE_EPISODE_META_FIELD, field, episodeId,
});

export const startFetchSubmissionAdditionInformations = () => ({
  type: actionTypes.START_FETCH_SUBMISSION_ADDITION_INFORMATIONS,
});
export const successFetchSubmissionAdditionInformations = (closedDays, minClassificationDays) => ({
  type: actionTypes.SUCCESS_FETCH_SUBMISSION_ADDITION_INFORMATIONS,
  payload: { closedDays, minClassificationDays },
});
export const failedFetchSubmissionAdditionInformations = error => ({
  type: actionTypes.FAILED_FETCH_SUBMISSION_ADDITION_INFORMATIONS,
  payload: error,
});

export const incrementEpisode = () => ({
  type: actionTypes.INCREMENT_EPISODE,
});

export const incrementVersionNumber = () => ({
  type: actionTypes.INCREMENT_VERSION_NUMBER,
});

export const checkMetaModificationStart = (changedMeta) => ({
  type: actionTypes.CHECK_META_MODIFICATION_START,
  payload: { changedMeta },
});
export const checkMetaModificationSuccess = (result) => ({
  type: actionTypes.CHECK_META_MODIFICATION_SUCCESS,
  payload: { result },
});
export const checkMetaModificationFailure = () => ({
  type: actionTypes.CHECK_META_MODIFICATION_FAILURE,
});

export const getInvoicePdfStart = (id) => ({
  type: actionTypes.GET_INVOICE_PDF_START,
  id,
});
export const getInvoicePdfSuccess = (id) => ({
  type: actionTypes.GET_INVOICE_PDF_SUCCESS,
  id,
});
export const getInvoicePdfFailure = (id) => ({
  type: actionTypes.GET_INVOICE_PDF_FAILURE,
  id,
});

export const addEpisode = (episode) => ({
  type: actionTypes.ADD_EPISODE,
  payload: episode,
});

export const deleteEpisodes = () => ({
  type: actionTypes.DELETE_EPISODES,
});

export const deleteEpisode = (id) => ({
  type: actionTypes.DELETE_EPISODE,
  payload: id,
});

export const setEpisodeMetaData = (field, value, id) => ({
  type: actionTypes.SET_EPISODE_METADATA,
  payload: { field, value, id },
});

const submission = {
  setMetaData,
  startNewSuccess,
  startNewFailure,
  addNewStart,
  addNewSuccess,
  addNewFailure,
  loadStart,
  loadSuccess,
  loadFailure,
  reset,
  eidrFetchStart,
  eidrFetchSuccess,
  eidrFetchFailure,
  loadProductTreeStart,
  loadProductTreeSuccess,
  loadProductTreeFailure,
  getUpdatableMetaFieldsStart,
  getUpdatableMetaFieldsSuccess,
  getUpdatableMetaFieldsFailure,
  startFetchSubmissionAdditionInformations,
  successFetchSubmissionAdditionInformations,
  failedFetchSubmissionAdditionInformations,
  incrementEpisode,
  checkMetaModificationStart,
  checkMetaModificationSuccess,
  checkMetaModificationFailure,
  addEpisode,
  deleteEpisodes,
  deleteEpisode,
  validateEpisodesMeta,
};

export default submission;
