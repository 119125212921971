import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const identityProxyUrl = `${apiUrl}/identity`;

console.log('IDENTITY PROXY URI:', identityProxyUrl); // eslint-disable-line no-console

const indetityProxy = {
  get: invoke(identityProxyUrl, 'GET'),
  post: invoke(identityProxyUrl, 'POST'),
  put: invoke(identityProxyUrl, 'PUT'),
  patch: invoke(identityProxyUrl, 'PATCH'),
  delete: invoke(identityProxyUrl, 'DELETE'),
};

/**
 * Authenticated routes
 */
export const getUsers = payload => indetityProxy.post('/user/list', { body: payload });

export const getUser = id => indetityProxy.get(`/user/${id}`);
export const getAllUsers = () => indetityProxy.get('/user/all');

export const saveUser = user => indetityProxy.put('/user', { body: user });

export const removeUserMFA = id => indetityProxy.delete(`/user/${id}/mfa`);

export const getCompanies = payload => indetityProxy.post('/company/list', { body: payload });
export const getAllCompanies = (payload = { rowsPerPage: 2000 }) => indetityProxy.post('/company/list', { body: payload });
export const getCompany = id => indetityProxy.get(`/company/${id}`);

export const saveCompany = company => indetityProxy.put('/company', { body: company });

export const updateCompany = (id, company) => indetityProxy.patch(`/company/${id}`, { body: company });

export const updateUser = (id, user) => indetityProxy.patch(`/user/${id}`, { body: user });

export const getMe = headers => indetityProxy.get('/auth/me', { headers });

export const getRoles = payload => indetityProxy.post('/new-role/list', { body: payload });
export const getRole = id => indetityProxy.get(`/new-role/${id}`);
export const getRoleUsers = (id, { page = 0, rowsPerPage = 10 }) => indetityProxy.get(`/new-role/${id}/users`, { query: { page, rowsPerPage } });
export const saveRole = role => indetityProxy.put('/new-role', { body: role });
export const editRole = (id, role) => indetityProxy.patch(`/new-role/${id}`, { body: role });

export const getPermissions = (payload = {}) => indetityProxy.post('/permission/list', { body: payload });

export const getUsersByPermissions = permissions => indetityProxy.post('/user/permissions', { body: { permissions } });

export const findUsers = body => indetityProxy.post('/user/find', { body });

export const resetPasswordRequestWithAuth = email => indetityProxy.post('/auth/reset-password-request', { body: { email } });

export const revokeUserSessionAPI = id => indetityProxy.delete(`/user/refresh-token/${id}`);

export const getUserNamesAndPics = ids => indetityProxy.post('/user/get-names-pics', { body: { ids } });

export const disableApiKey = (companyId, apiKeyId) => indetityProxy.patch(`/company/${companyId}/apiKey/${apiKeyId}/disable`);

export const enableApiKey = (companyId, apiKeyId) => indetityProxy.patch(`/company/${companyId}/apiKey/${apiKeyId}/enable`);

export const removeApiKey = (companyId, apiKeyId) => indetityProxy.delete(`/company/${companyId}/apiKey/${apiKeyId}`);

export const generateApiKey = (companyId, name) => indetityProxy.post(`/company/${companyId}/apiKey`, { body: { name } });

export const setApiUser = (companyId) => indetityProxy.post(`/company/${companyId}/setVirtualUser`);

export const logout = () => indetityProxy.post('/auth/logout');

/**
 * Unauthenticated routes
 */
export const loginUserInit = payload => indetityProxy.post('/auth/login/init', { body: payload });

export const loginUserMFA = payload => indetityProxy.post('/auth/login/mfa', { body: payload });

export const switchCompany = payload => indetityProxy.post('/auth/switch-company', { body: payload });

export const resetPasswordRequest = email => indetityProxy.post('/auth/reset-password-request', { body: { email } });

export const resetPassword = (token, password) => indetityProxy.post('/auth/reset-password', { body: { token, password } });

export const finishInstallation = (token, code) => indetityProxy.post('/auth/signup/finish', { body: { token, code } });

export const signup = (token, user) => indetityProxy.post('/auth/signup', { body: { token, ...user } });

export const validateResetPasswordToken = token => indetityProxy.post('/auth/reset-password/validate', { body: { token } });

export const refreshTokenRequestAPI = refreshToken => indetityProxy.post('/auth/refresh-token', { body: { refreshToken } });

export const destoryRefreshTokenAPI = refreshToken => indetityProxy.delete('/auth/refresh-token', { body: { refreshToken } });
