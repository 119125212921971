import moment from 'moment';
import last from 'lodash/last';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import {
  SELF_MEDIA_DELIVERY_FLAG,
  RATING_NO_PREFERENCE,
  WORK_ORDER_MODIFICATION_SIGNALS,
} from '../../utils/constants';
import { actionTypes } from '../actions/submissionV2';

import {
  isValidMetaData,
  metaValidation,
  getValidationValue,
  episodeMetaValidation,
  bonusMaterialMetaValidation,
  isValidAllItemsMetaData,
  isValidItemsTotalRuntime,
} from '../../utils/submission';
import { transformSubmission } from '../utils';

const defaultSubmissionProperties = {
  id: null,
  offer: {
    selectedOffer: null,
    params: null,
    reservation: null,
  },
  meta: {
    year: new Date().getFullYear(),
    eidr: '',
    requiredRating: RATING_NO_PREFERENCE,
    lriDelay: false,
    productParams: {},
    isKDMRequired: false,
    subtitles: false,
    isExternalVenueRequired: false,
    isRemoteComplianceViewingAllowed: false,
    additionalLanguages: [],
    mediaDeliverer: SELF_MEDIA_DELIVERY_FLAG,
    invoicingCompany: 'null',
    workflowVersion: 'v2',
    isDualRatingCriteriaAllowed: false,
    cinemaRegistrationNumber: '',
  },
  items: [],
  validation: {
    isValid: false,
    isAllItemsValid: true,
    hasAtLeastOneItem: true,
    isItemsTotalRuntimeValid: true,
  },
  updatableMetaFields: [
    'title',
    'year',
    'director',
    'genre',
    'language',
    'country',
    'season',
    'episode',
    'seasonTitle',
    'episodeTitle',
    'runtimeInSeconds',
    'lriDelay',
    'requiredClassificationDueDate',
    'releaseDate',
    'requiredRating',
    'actualRuntime',
    'actualRuntimeInSeconds',
    'versionNumber',
    'versionName',
    'versionDescription',
    'version',
    'additionalTitle',
    'productPath',
    'submissionRating',
    'publicationDate',
    'aspectRatio',
    'invoicingCompany',
    'numberOfLocations',
    'videoIdentical',
    'preschool',
    'modernFilmClassification',
    'compositeElements',
    'alsoKnownAs',
    'isDualRatingCriteriaAllowed',
    'cinemaRegistrationNumber',
  ],
  isLoaded: false,
  isProductParamsLoading: false,
  error: {
    message: '',
    status: null,
  },
  certificate: { isLoading: false },
  videoAsset: {
    details: {},
    isLoading: false,
    list: [],
  },
  sentSignals: [],
  isEidrLoading: false,
  eidrEditableFields: {},
  journal: [],
};

export const submissionDefaultState = {
  isRebookDialogShown: false,
  offers: [],
  submissions: [],
  currentIndex: 0,
  isLoaded: false,
  isSubmissionLoaded: false,
  apiError: false,
  isBusy: false,
  isOfferLoading: false,
  error: {
    message: '',
    status: null,
  },
  productTree: null,
  clientOrderId: null,
  companyHolidays: [],
};

// eslint-disable-next-line default-param-last
export const reducer = (state = submissionDefaultState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NEW: {
      const disabledFields = ['title', 'season'];
      const updatableMetaFields = state.submissions.length > 0 ?
        defaultSubmissionProperties.updatableMetaFields.filter((f) => !disabledFields.includes(f)) :
        defaultSubmissionProperties.updatableMetaFields;

      return {
        ...state,
        clientOrderId: action.clientOrderId,
        submissions: [
          ...state.submissions,
          {
            ...defaultSubmissionProperties,
            updatableMetaFields,
          },
        ],
        isLoaded: false,
        apiError: false,
        isBusy: false,
        productTree: null,
        currentIndex: state.submissions.length,
        offers: [],
      };
    }

    case actionTypes.SET_REFERENCE_SUBMISSION: {
      const { currentIndex } = state;
      const { referenceSubmission, referenceSubmissionByEpisode } = action;
      if (!referenceSubmission.meta) return state;

      const inputMatrix = get(referenceSubmission, 'meta.productParams.input', {});
      const path = get(referenceSubmission, 'meta.productPath', '');
      const excludedFields = [
        'releaseDate',
        'publicationDate',
        'lriDelay',
        'runtime',
        'runtimeInSeconds',
        'actualRuntime',
        'actualRuntimeInSeconds',
        'versionName',
        'versionDescription',
        'contentDeliveryType',
        'productParams',
        'videoIdentical',
        'numberOfLocations',
        'preschool',
        'modernFilmClassification',
        'additionalTitle',
        'compositeElements',
        'alsoKnownAs',
        'batchUpload',
        'imdbId',
        'cast',
        'researched',
        'eidr',
      ];
      const productPath = path.includes('Cinema') ? path.match(/^[^/]*/)[0] : path.match(/^[^/]*\/[^/]*/)[0];
      const filteredMeta = omit(referenceSubmission.meta, excludedFields);
      const meta = { ...filteredMeta, productPath };

      if (inputMatrix.versionNumber) meta.versionNumber = get(referenceSubmissionByEpisode, 'meta.versionNumber');
      if (inputMatrix.addEpisodeInfo) meta.episode = get(referenceSubmissionByEpisode, 'meta.episode');

      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex]?.meta,
          ...meta,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.RESET: {
      return {
        ...submissionDefaultState,
      };
    }

    case actionTypes.SET_META_DATA: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex]?.meta,
          ...action.payload.meta,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.VALIDATE_META_FIELD: {
      const { meta = {}, field, calendars } = action;
      const { currentIndex } = state;
      const submission = state.submissions[currentIndex];

      const validationOptions = {
        calendars,
        fieldSet: get(submission, 'meta.productParams.input'),
        ...pick(submission, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const validation = metaValidation(meta, validationOptions) || {};

      const currentSubmission = {
        ...submission,
        validation: {
          ...submission?.validation,
          [field]: getValidationValue(validation, field),
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.VALIDATE_META: {
      const { currentIndex } = state;
      const { meta = {}, options, calendars } = action;
      const submission = state.submissions[currentIndex];

      const validationOptions = {
        calendars,
        fieldSet: get(submission, 'meta.productParams.input'),
        ...pick(submission, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const validation = metaValidation(meta, validationOptions, options) || {};
      const values = Object.keys(validation).reduce((acc, k) => ({ ...acc, [k]: getValidationValue(validation, k) }), {});

      const currentSubmission = {
        ...submission,
        validation: {
          isValid: isValidMetaData(meta, options, validationOptions),
          ...values,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.VALIDATE_ITEMS_META: {
      const { currentIndex } = state;
      const { viewingCalendar } = action.payload;
      const submission = state.submissions[currentIndex];
      const isBatch = submission?.meta?.productParams?.batch;

      const validationOptions = {
        fieldSet: get(submission, 'meta.productParams.input'),
        productPath: get(submission, 'meta.productPath'),
        ...pick(submission, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const itemsValidationValues = submission?.items.map(e => {
        if (validationOptions.productPath?.includes('Episodic')) {
          const validation = episodeMetaValidation(e, validationOptions);
          const values = Object.keys(validation).reduce((acc, k) => ({ ...acc, [k]: getValidationValue(validation, k) }), {});

          return { id: e.id, ...values };
        }
        if (validationOptions.productPath?.includes('Bonus Material')) {
          const validation = bonusMaterialMetaValidation(e, validationOptions);
          const values = Object.keys(validation).reduce((acc, k) => ({ ...acc, [k]: getValidationValue(validation, k) }), {});

          return { id: e.id, ...values };
        }
        return { id: e.id };
      });

      const currentSubmission = {
        ...submission,
        validation: {
          ...submission?.validation,
          isAllItemsValid: isBatch ? isValidAllItemsMetaData(submission?.items, validationOptions) : true,
          hasAtLeastOneItem: isBatch ? (submission?.items || []).length > 0 : true,
          items: itemsValidationValues,
          isItemsTotalRuntimeValid: isValidItemsTotalRuntime(submission?.items, viewingCalendar),
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.VALIDATE_ITEM_META_FIELD: {
      const { currentIndex } = state;
      const { field, itemId } = action;
      const submission = state.submissions[currentIndex];

      const validationOptions = {
        fieldSet: get(submission, 'meta.productParams.input'),
        ...pick(submission, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const itemsValidationValues = submission?.items.map(e => {
        const itemValidation = (submission?.validation?.items?.filter((item) => item) || []).find((v) => v.id === e.id);
        if (e.id !== itemId) return itemValidation;
        if (submission?.meta?.productPath?.includes('Episodic')) {
          const validation = episodeMetaValidation(e, validationOptions);
          return { ...itemValidation, [field]: getValidationValue(validation, field) };
        }
        if (submission?.meta?.productPath?.includes('Bonus Material')) {
          const validation = bonusMaterialMetaValidation(e, validationOptions);
          return { ...itemValidation, [field]: getValidationValue(validation, field) };
        }
        return itemValidation;
      });

      const currentSubmission = {
        ...submission,
        validation: {
          ...submission?.validation,
          items: itemsValidationValues,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.RESET_META_DATA: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...defaultSubmissionProperties.meta,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_PRODUCT_PARAMS_START: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex]?.meta,
          productParams: { _path: action.payload.productPath },
        },
        isProductParamsLoading: true,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_PRODUCT_PARAMS_SUCCESS: {
      const { result: productParams } = action.payload;
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex]?.meta,
          productParams,
        },
        isProductParamsLoading: false,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_PRODUCT_PARAMS_FAILURE: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        isProductParamsLoading: false,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_PRODUCT_TREE_SUCCESS: {
      const { result: productTree } = action.payload;
      return {
        ...state,
        productTree,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.LOAD_EIDR_START: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        isEidrLoading: true,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_EIDR_SUCCESS: {
      const { eidr, eidrEditableFields } = action.payload;

      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex].meta,
          eidr,
        },
        isEidrLoading: false,
        eidrEditableFields: {
          ...eidrEditableFields,
          eidr: true,
          genre: true,
          requiredRating: true,
          requiredClassificationDueDate: true,
        },
      };

      return {
        ...state,
        apiError: false,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_EIDR_FAILURE: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex].meta,
          eidr: null,
        },
        eidrEditableFields: {},
        isEidrLoading: false,
      };

      return {
        ...state,
        apiError: action.payload,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.EIDR_CLEAR: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        meta: {
          ...state.submissions[currentIndex].meta,
          eidr: null,
        },
        eidrEditableFields: {},
        isEidrLoading: false,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.ADD_ITEM: {
      const { currentIndex } = state;
      const submission = state.submissions[currentIndex];
      const currentSubmission = {
        ...submission,
        items: [
          ...submission.items,
          { ...action.payload, id: submission.items.length },
        ],
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.DELETE_ITEM: {
      const { currentIndex } = state;
      const submission = state.submissions[currentIndex];

      const itemsValidationValues = submission?.validation?.items?.filter((item) => item.id !== action.payload);

      const currentSubmission = {
        ...submission,
        items: submission.items.filter((item) => item.id !== action.payload),
        validation: {
          ...submission.validation,
          items: itemsValidationValues,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.DELETE_ITEMS: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        items: [],
        validation: defaultSubmissionProperties.validation,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.SET_ITEM_METADATA: {
      const { currentIndex } = state;
      const submission = state.submissions[currentIndex];
      const currentSubmission = {
        ...submission,
        items: submission?.items.map((items) => {
          if (action.payload.id !== items.id) return items;
          return { ...items, [action.payload.field]: action.payload.value };
        }),
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.GET_CALENDAR_OFFERS_START: {
      return {
        ...state,
        offers: [],
        isOfferLoading: true,
      };
    }

    case actionTypes.GET_CALENDAR_OFFERS_FAILURE: {
      return {
        ...state,
        offers: [],
        isOfferLoading: false,
      };
    }

    case actionTypes.GET_CALENDAR_OFFERS_SUCCESS: {
      const { offers, params, companyHolidays } = action.payload;
      const { currentIndex } = state;

      const currentSubmission = {
        ...state.submissions[currentIndex],
        offer: {
          ...state.submissions[currentIndex].offer,
          params,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
        offers,
        companyHolidays,
        isOfferLoading: false,
      };
    }

    case actionTypes.OFFER_RESERVATION_START:
    case actionTypes.OFFER_RESERVATION_FAILURE: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        offer: {
          ...state.submissions[currentIndex].offer,
          reservation: null,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }
    case actionTypes.OFFER_RESERVATION_SUCCESS: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        offer: {
          ...state.submissions[currentIndex].offer,
          ...action.payload,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.REBOOK_START: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        isRebookLoading: true,
      };

      return {
        ...state,
        isBusy: true,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }
    case actionTypes.REBOOK_FAILURE:
    case actionTypes.REBOOK_SUCCESS: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        isRebookLoading: false,
      };

      return {
        ...state,
        isBusy: false,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.CANCEL_START: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        isCancellationRequested: true,
      };

      return {
        ...state,
        isBusy: true,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.CANCEL_FAILURE: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        isCancellationRequested: false,
      };

      return {
        ...state,
        isBusy: false,
        apiError: true,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_JOURNAL_SUCCESS: {
      const { journal } = action.payload;

      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        journal,
      };

      return {
        ...state,
        isLoaded: true,
        isBusy: false,
        apiError: false,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.GET_UPDATABLE_META_FIELDS_SUCCESS: {
      const { allowedFields: updatableMetaFields } = action.payload;

      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        updatableMetaFields,
      };

      return {
        ...state,
        isBusy: false,
        apiError: false,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.LOAD_SUCCESS: {
      const submission = transformSubmission(action.payload.submission);

      const { currentIndex } = state;
      const currentSubmission = state.submissions[currentIndex] || {};

      let { waitingForModificationProcess = false } = currentSubmission;
      const { sentSignals } = currentSubmission;
      if (waitingForModificationProcess) {
        const lastSentModificationSignal = last(sentSignals.filter(s => WORK_ORDER_MODIFICATION_SIGNALS.includes(s.signalName)));
        const { lastModificationCompletedAt } = submission.meta;
        if (lastModificationCompletedAt && moment(lastModificationCompletedAt).isAfter(lastSentModificationSignal.timestamp)) {
          waitingForModificationProcess = false;
        }
      }

      const newSubmission = {
        ...currentSubmission,
        ...submission,
        waitingForModificationProcess,
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? newSubmission : _)),
        isLoaded: true,
        isSubmissionLoaded: true,
        isBusy: false,
        apiError: false,
        productTree: null,
        currentIndex: 0,
        offers: [],
      };
    }

    case actionTypes.GET_VIDEO_ASSET_LIST_START: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        videoAsset: {
          ...state.submissions[currentIndex].videoAsset,
          isLoading: true,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.GET_VIDEO_ASSET_LIST_SUCCESS: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        videoAsset: {
          ...state.submissions[currentIndex].videoAsset,
          isLoading: false,
          list: action.payload,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.GET_VIDEO_ASSET_LIST_FAILURE: {
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        videoAsset: {
          ...state.submissions[currentIndex].videoAsset,
          isLoading: false,
        },
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.SIGNAL_START: {
      const { signalName } = action.payload;
      const { currentIndex } = state;
      const currentSubmission = {
        ...state.submissions[currentIndex],
        sentSignals: [ // keep the order please
          ...state.submissions[currentIndex].sentSignals,
          {
            signalName,
            timestamp: Date.now(),
          },
        ],
        waitingForModificationProcess: state.submissions[currentIndex].waitingForModificationProcess || WORK_ORDER_MODIFICATION_SIGNALS.includes(signalName),
      };

      return {
        ...state,
        submissions: state.submissions.map((_, i) => (i === currentIndex ? currentSubmission : _)),
      };
    }

    case actionTypes.SET_IS_REBOOK_DIALOG_SHOWN: {
      const { isShown } = action.payload;

      return {
        ...state,
        isRebookDialogShown: isShown,
      };
    }

    case actionTypes.CHECK_META_MODIFICATION_SUCCESS:
    case actionTypes.DOWNLOAD_VIDEO_ASSET_SUCCESS:
    case actionTypes.SYNC_SUCCESS: {
      return {
        ...state,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.CHECK_META_MODIFICATION_FAILURE:
    case actionTypes.DOWNLOAD_VIDEO_ASSET_FAILURE:
    case actionTypes.SYNC_FAILURE:
    case actionTypes.LOAD_JOURNAL_FAILURE:
    case actionTypes.GET_UPDATABLE_META_FIELDS_FAILURE:
    case actionTypes.LOAD_FAILURE:
    case actionTypes.LOAD_PRODUCT_TREE_FAILURE: {
      const error = get(action.payload, 'error');
      return {
        ...state,
        isBusy: false,
        apiError: true,
        error,
      };
    }

    case actionTypes.CHECK_META_MODIFICATION_START:
    case actionTypes.DOWNLOAD_VIDEO_ASSET_START:
    case actionTypes.SYNC_START:
    case actionTypes.LOAD_JOURNAL_START:
    case actionTypes.GET_UPDATABLE_META_FIELDS_START:
    case actionTypes.LOAD_START:
    case actionTypes.LOAD_PRODUCT_TREE_START:
      return { ...state, isBusy: true };

    default:
      return state;
  }
};

export default reducer;
