import { actionTypes } from '../actions/notifications';

export const notificationsDefaultState = {
  timeoutID: null,
  notifications: [],
  activeNotifications: [],
  activeNotificationCount: 0,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = notificationsDefaultState, { type, payload }) => {
  switch (type) {
    case actionTypes.SAVE_NOTIFICATION_TIMEOUT:
      clearTimeout(state.timeoutID);
      return { ...state, timeoutID: payload };
    case actionTypes.CLEAR_NOTIFICATION_TIMEOUT:
      clearTimeout(state.timeoutID);
      return { ...state, timeoutID: null };
    case actionTypes.SAVE_NOTIFICATIONS: {
      const activeNotifications = payload.filter(({ isViewed }) => !isViewed);
      return {
        ...state,
        notifications: payload,
        activeNotifications,
        activeNotificationCount: activeNotifications.length,
      };
    }
    default:
      return state;
  }
};

export default reducer;
