import update from 'immutability-helper';
import { actionTypes } from '../actions/app';
import { NOTIFICATION_VARIANTS } from '../../utils/constants';

const SESSION_COUNTDOWN = 15 * 60 * 1000;

export const appDefaultState = {
  workflowVersionLoading: false,
  workflowVersion: null,
  notification: {
    time: null,
    message: '',
    variant: NOTIFICATION_VARIANTS.INFO,
    autoHide: true,
  },
  sliderMenuLocked: true,
  countries: {
    error: '',
    loading: false,
    rows: [],
  },
  news: {
    error: '',
    loading: false,
    detail: {},
  },
  sessionCountdown: {
    counter: SESSION_COUNTDOWN,
    timeoutID: null,
  },
  flags: {
    values: [],
    error: null,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = appDefaultState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFY: {
      const { message, variant, autoHide } = action.payload;
      return {
        ...state,
        notification: {
          time: new Date().getTime(),
          message,
          variant,
          autoHide,
        },
      };
    }
    case actionTypes.NOTIFICATION_SHOWN: {
      return update(state, {
        notification: {
          shown: { $set: true },
        },
      });
    }
    case actionTypes.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: appDefaultState.notification,
      };
    }
    case actionTypes.TOGGLE_SLIDER_MENU_LOCK: {
      return update(state, {
        $toggle: ['sliderMenuLocked'],
      });
    }
    case actionTypes.FETCH_COUNTRIES:
      return { ...state, countries: { error: '', loading: true } };
    case actionTypes.FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: { rows: action.payload, error: '', loading: false } };
    case actionTypes.FETCH_COUNTRIES_FAILURE:
      return { ...state, countries: { rows: [], loading: false, error: action.payload.message } };
    case actionTypes.FETCH_NEWS:
      return { ...state, news: { ...state.news, error: '', loading: true } };
    case actionTypes.FETCH_NEWS_SUCCESS:
      return { ...state, news: { detail: action.payload, error: '', loading: false } };
    case actionTypes.FETCH_NEWS_FAILURE:
    case actionTypes.UPDATE_NEWS_FAILURE:
      return { ...state, news: { detail: {}, loading: false, error: action.error.message } };
    case actionTypes.DECREASE_SESSION_COUNTDOWN:
      return {
        ...state,
        sessionCountdown: {
          ...state.sessionCountdown,
          counter: state.sessionCountdown.counter - action.payload,
        },
      };
    case actionTypes.SAVE_SESSION_COUNTDOWN_TIMEOUT:
      clearTimeout(state.sessionCountdown.timeoutID);
      return {
        ...state,
        sessionCountdown: {
          ...state.sessionCountdown,
          timeoutID: action.payload,
        },
      };
    case actionTypes.CLEAR_SESSION_COUNTDOWN_TIMEOUT:
      clearTimeout(state.sessionCountdown.timeoutID);
      return {
        ...state,
        sessionCountdown: {
          ...state.sessionCountdown,
          timeoutID: null,
        },
      };
    case actionTypes.RESET_SESSION_COUNTDOWN_COUNTER:
      return {
        ...state,
        sessionCountdown: {
          ...state.sessionCountdown,
          counter: action.payload || SESSION_COUNTDOWN,
        },
      };
    case actionTypes.SAVE_SESSION_COUNTDOWN_MODIFIER_ID:
      return {
        ...state,
        sessionCountdown: {
          ...state.sessionCountdown,
        },
      };
    case actionTypes.SAVE_SESSION_LAST_SYNC:
      return {
        ...state,
        sessionCountdown: {
          ...state.sessionCountdown,
        },
      };
    case actionTypes.FETCH_FLAGS:
      return { ...state, flags: { ...state.flags, error: null } };
    case actionTypes.FETCH_FLAGS_SUCCESS:
      return { ...state, flags: { values: action.payload, error: null } };
    case actionTypes.FETCH_FLAGS_FAILURE:
      return { ...state, flags: { values: [], error: action.payload } };

    case actionTypes.GET_WORKFLOW_VERSION_BY_ID_START: {
      return {
        ...state,
        workflowVersionLoading: true,
        workflowVersion: null,
      };
    }

    case actionTypes.GET_WORKFLOW_VERSION_BY_ID_SUCCESS: {
      const { workflowVersion } = action.payload;

      return {
        ...state,
        workflowVersionLoading: false,
        workflowVersion,
      };
    }

    case actionTypes.GET_WORKFLOW_VERSION_BY_ID_FAILURE: {
      return {
        ...state,
        workflowVersionLoading: false,
        workflowVersion: null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
