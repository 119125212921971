import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;

console.log('VERSIONS:', `${apiUrl}/version`); // eslint-disable-line no-console
console.log('PROCESS ADMIN URI:', apiUrl); // eslint-disable-line no-console

const api = {
  get: invoke(apiUrl, 'GET'),
  post: invoke(apiUrl, 'POST'),
  put: invoke(apiUrl, 'PUT'),
  patch: invoke(apiUrl, 'PATCH'),
  delete: invoke(apiUrl, 'DELETE'),
};

export const fetchEidrApi = id => api.get('/eidr', { query: { id } });

export const getCountries = () => api.get('/countries');

export const createMultipartUpload = (bucket, file, metadata) => api.post(`/aws/s3/multipart/${bucket}`, { body: { filename: file.name, metadata } });

export const listParts = (bucket, uploadId, key) => api.get(`aws/s3/multipart/${bucket}/${uploadId}`, { query: { key } });

export const prepareUploadPart = (bucket, uploadId, number, key) => api.get(`/aws/s3/multipart/${bucket}/${uploadId}/${number}`, { query: { key } });

export const abortMultipartUpload = (bucket, uploadId, key) => api.delete(`/aws/s3/multipart/${bucket}/${uploadId}`, { query: { key } });

export const completeMultipartUpload = (bucket, uploadId, key, parts) => api.post(`/aws/s3/multipart/${bucket}/${uploadId}/complete`, { query: { key }, body: { parts } });

export const deleteClientOrder = (clientOrderId, complete = false) => api.get(`/orchestration/clean/client-order/${clientOrderId}?complete=${complete}`);

export const deleteWorkOrder = (workOrderId, complete = false) => api.get(`/orchestration/clean/work-order/${workOrderId}?complete=${complete}`);

export const deleteClientOrders = (clientOrderIds, complete = false) => api.post(`/orchestration/clean/client-orders?complete=${complete}`, { body: { clientOrderIds } });

export const getFlags = () => api.get('/flags');