export const actionTypes = {
  GET_GENRES_START: 'res-GET_GENRES_START',
  GET_GENRES_SUCCESS: 'res-GET_GENRES_SUCCESS',
  GET_GENRES_FAILURE: 'res-GET_GENRES_FAILURE',
  ADD_GENRE_START: 'res-ADD_GENRE_START',
  ADD_GENRE_SUCCESS: 'res-ADD_GENRE_SUCCESS',
  ADD_GENRE_FAILURE: 'res-ADD_GENRE_FAILURE',
  GET_GSUITE_USERS_START: 'res-GET_GSUITE_USERS_START',
  GET_GSUITE_USERS_SUCCESS: 'res-GET_GSUITE_USERS_SUCCESS',
  GET_GSUITE_USERS_FAILURE: 'res-GET_GSUITE_USERS_FAILURE',
  GET_COMPLIANCE_OFFICERS_START: 'res-GET_COMPLIANCE_OFFICERS_START',
  GET_COMPLIANCE_OFFICERS_SUCCESS: 'res-GET_COMPLIANCE_OFFICERS_SUCCESS',
  GET_COMPLIANCE_OFFICERS_FAILURE: 'res-GET_COMPLIANCE_OFFICERS_FAILURE',
  ADD_COMPLIANCE_OFFICER_START: 'res-ADD_COMPLIANCE_OFFICER_START',
  ADD_COMPLIANCE_OFFICER_SUCCESS: 'res-ADD_COMPLIANCE_OFFICER_SUCCESS',
  ADD_COMPLIANCE_OFFICER_FAILURE: 'res-ADD_COMPLIANCE_OFFICER_FAILURE',
  UPDATE_COMPLIANCE_OFFICER_START: 'res-UPDATE_COMPLIANCE_OFFICER_START',
  UPDATE_COMPLIANCE_OFFICER_SUCCESS: 'res-UPDATE_COMPLIANCE_OFFICER_SUCCESS',
  UPDATE_COMPLIANCE_OFFICER_FAILURE: 'res-UPDATE_COMPLIANCE_OFFICER_FAILURE',
  DELETE_COMPLIANCE_OFFICER_START: 'res-DELETE_COMPLIANCE_OFFICER_START',
  DELETE_COMPLIANCE_OFFICER_SUCCESS: 'res-DELETE_COMPLIANCE_OFFICER_SUCCESS',
  DELETE_COMPLIANCE_OFFICER_FAILURE: 'res-DELETE_COMPLIANCE_OFFICER_FAILURE',
  SET_COMPLIANCE_OFFICER_GENRE_START: 'res-SET_COMPLIANCE_OFFICER_GENRE_START',
  SET_COMPLIANCE_OFFICER_GENRE_SUCCESS: 'res-SET_COMPLIANCE_OFFICER_GENRE_SUCCESS',
  SET_COMPLIANCE_OFFICER_GENRE_FAILURE: 'res-SET_COMPLIANCE_OFFICER_GENRE_FAILURE',
};

export const getGenresStart = () => ({
  type: actionTypes.GET_GENRES_START,
});

export const getGenresSuccess = genres => ({
  type: actionTypes.GET_GENRES_SUCCESS,
  payload: { genres },
});

export const getGenresFailure = () => ({
  type: actionTypes.GET_GENRES_FAILURE,
});

export const addGenreStart = () => ({
  type: actionTypes.ADD_GENRE_START,
});

export const addGenreSuccess = genres => ({
  type: actionTypes.ADD_GENRE_SUCCESS,
  payload: { genres },
});

export const addGenreFailure = () => ({
  type: actionTypes.ADD_GENRE_FAILURE,
});

export const getGsuiteUsersStart = () => ({
  type: actionTypes.GET_GSUITE_USERS_START,
});

export const getGsuiteUsersSuccess = gsuiteUsers => ({
  type: actionTypes.GET_GSUITE_USERS_SUCCESS,
  payload: { gsuiteUsers },
});

export const getGsuiteUsersFailure = () => ({
  type: actionTypes.GET_GSUITE_USERS_FAILURE,
});

export const getComplianceOfficersStart = () => ({
  type: actionTypes.GET_COMPLIANCE_OFFICERS_START,
});

export const getComplianceOfficersSuccess = complianceOfficers => ({
  type: actionTypes.GET_COMPLIANCE_OFFICERS_SUCCESS,
  payload: { complianceOfficers },
});

export const getComplianceOfficersFailure = () => ({
  type: actionTypes.GET_COMPLIANCE_OFFICERS_FAILURE,
});

export const addComplianceOfficerStart = () => ({
  type: actionTypes.ADD_COMPLIANCE_OFFICER_START,
});

export const addComplianceOfficerSuccess = () => ({
  type: actionTypes.ADD_COMPLIANCE_OFFICER_SUCCESS,
});

export const addComplianceOfficerFailure = () => ({
  type: actionTypes.ADD_COMPLIANCE_OFFICER_FAILURE,
});

export const updateComplianceOfficerStart = (id, data) => ({
  type: actionTypes.UPDATE_COMPLIANCE_OFFICER_START,
  payload: { id, data },
});

export const updateComplianceOfficerSuccess = () => ({
  type: actionTypes.UPDATE_COMPLIANCE_OFFICER_SUCCESS,
});

export const updateComplianceOfficerFailure = () => ({
  type: actionTypes.UPDATE_COMPLIANCE_OFFICER_FAILURE,
});

export const deleteComplianceOfficerStart = (id) => ({
  type: actionTypes.DELETE_COMPLIANCE_OFFICER_START,
  payload: { id },
});

export const deleteComplianceOfficerSuccess = () => ({
  type: actionTypes.DELETE_COMPLIANCE_OFFICER_SUCCESS,
});

export const deleteComplianceOfficerFailure = () => ({
  type: actionTypes.DELETE_COMPLIANCE_OFFICER_FAILURE,
});

export const setComplianceOfficerGenreStart = (data) => ({
  type: actionTypes.SET_COMPLIANCE_OFFICER_GENRE_START,
  payload: data,
});

export const setComplianceOfficerGenreSuccess = () => ({
  type: actionTypes.SET_COMPLIANCE_OFFICER_GENRE_SUCCESS,
});

export const setComplianceOfficerGenreFailure = () => ({
  type: actionTypes.SET_COMPLIANCE_OFFICER_GENRE_FAILURE,
});

const resources = {
  getGenresStart,
  getGenresSuccess,
  getGenresFailure,
  addGenreStart,
  addGenreSuccess,
  addGenreFailure,
  getGsuiteUsersStart,
  getGsuiteUsersSuccess,
  getGsuiteUsersFailure,
  getComplianceOfficersStart,
  getComplianceOfficersSuccess,
  getComplianceOfficersFailure,
  addComplianceOfficerStart,
  addComplianceOfficerSuccess,
  addComplianceOfficerFailure,
  setComplianceOfficerGenreStart,
  setComplianceOfficerGenreSuccess,
  setComplianceOfficerGenreFailure,
};

export default resources;
