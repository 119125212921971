import { NOTIFICATION_VARIANTS } from '../../utils/constants';

export const actionTypes = {
  NOTIFY: 'NOTIFY',
  NOTIFICATION_SHOWN: 'NOTIFICATION_SHOWN',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  TOGGLE_SLIDER_MENU_LOCK: 'TOGGLE_SLIDER_MENU_LOCK',

  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE: 'FETCH_COUNTRIES_FAILURE',

  FETCH_NEWS: 'FETCH_NEWS',
  FETCH_NEWS_SUCCESS: 'FETCH_NEWS_SUCCESS',
  FETCH_NEWS_FAILURE: 'FETCH_NEWS_FAILURE',

  UPDATE_NEWS: 'UPDATE_NEWS',
  UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
  UPDATE_NEWS_FAILURE: 'UPDATE_NEWS_FAILURE',

  SAVE_SESSION_COUNTDOWN_TIMEOUT: 'SAVE_SESSION_COUNTDOWN_TIMEOUT',
  CLEAR_SESSION_COUNTDOWN_TIMEOUT: 'CLEAR_SESSION_COUNTDOWN_TIMEOUT',
  DECREASE_SESSION_COUNTDOWN: 'DECREASE_SESSION_COUNTDOWN',
  RESET_SESSION_COUNTDOWN_COUNTER: 'RESET_SESSION_COUNTDOWN_COUNTER',
  SAVE_SESSION_COUNTDOWN_MODIFIER_ID: 'SAVE_SESSION_COUNTDOWN_MODIFIER_ID',
  SAVE_SESSION_LAST_SYNC: 'SAVE_SESSION_LAST_SYNC',

  FETCH_FLAGS: 'app-FETCH_FLAGS',
  FETCH_FLAGS_SUCCESS: 'app-FETCH_FLAGS_SUCCESS',
  FETCH_FLAGS_FAILURE: 'app-FETCH_FLAGS_FAILURE',

  GET_WORKFLOW_VERSION_BY_ID_START: 'GET_WORKFLOW_VERSION_BY_ID_START',
  GET_WORKFLOW_VERSION_BY_ID_SUCCESS: 'GET_WORKFLOW_VERSION_BY_ID_SUCCESS',
  GET_WORKFLOW_VERSION_BY_ID_FAILURE: 'GET_WORKFLOW_VERSION_BY_ID_FAILURE',
};

export const notify = (message, variant = NOTIFICATION_VARIANTS.INFO, autoHide = true) => ({
  type: actionTypes.NOTIFY,
  payload: { message, variant, autoHide },
});

export const clearNotification = () => ({
  type: actionTypes.CLEAR_NOTIFICATION,
});

export const notificationShown = () => ({
  type: actionTypes.NOTIFICATION_SHOWN,
});

export const toggleSliderMenuLock = () => ({
  type: actionTypes.TOGGLE_SLIDER_MENU_LOCK,
});

export const fetchCountriesStarted = () => ({ type: actionTypes.FETCH_COUNTRIES });
export const fetchCountriesSuccess = payload => ({ type: actionTypes.FETCH_COUNTRIES_SUCCESS, payload });
export const fetchCountriesFailed = error => ({ type: actionTypes.FETCH_COUNTRIES_FAILURE, error });

export const fetchNewsStarted = () => ({ type: actionTypes.FETCH_NEWS });
export const fetchNewsSuccess = payload => ({ type: actionTypes.FETCH_NEWS_SUCCESS, payload });
export const fetchNewsFailed = error => ({ type: actionTypes.FETCH_NEWS_FAILURE, error });

export const updateNewsStarted = () => ({ type: actionTypes.UPDATE_NEWS });
export const updateNewsSuccess = () => ({ type: actionTypes.UPDATE_NEWS_SUCCESS });
export const updateNewsFailed = error => ({ type: actionTypes.UPDATE_NEWS_FAILURE, error });

export const saveSessionCountdownTimeout = timeoutID => ({ type: actionTypes.SAVE_SESSION_COUNTDOWN_TIMEOUT, payload: timeoutID });
export const resetSessionCountdownCounter = counter => ({ type: actionTypes.RESET_SESSION_COUNTDOWN_COUNTER, payload: counter });
export const clearSessionCountdownCounter = () => ({ type: actionTypes.CLEAR_SESSION_COUNTDOWN_TIMEOUT });

export const fetchFlagsStart = () => ({ type: actionTypes.FETCH_FLAGS });
export const fetchFlagsSuccess = payload => ({ type: actionTypes.FETCH_FLAGS_SUCCESS, payload });
export const fetchFlagsFailure = payload => ({ type: actionTypes.FETCH_FLAGS_FAILURE, payload });

export const getWorkflowVersionByIdStart = () => ({
  type: actionTypes.GET_WORKFLOW_VERSION_BY_ID_START,
});

export const getWorkflowVersionByIdSuccess = (workflowVersion) => ({
  type: actionTypes.GET_WORKFLOW_VERSION_BY_ID_SUCCESS,
  payload: { workflowVersion },
});

export const getWorkflowVersionByIdFailure = () => ({
  type: actionTypes.GET_WORKFLOW_VERSION_BY_ID_FAILURE,
});

const app = {
  notify,
  notificationShown,
  toggleSliderMenuLock,
  resetSessionCountdownCounter,
  fetchFlagsStart,
  fetchFlagsSuccess,
  fetchFlagsFailure,
};

export default app;
