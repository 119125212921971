import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const clipExportProxyUrl = `${apiUrl}/clip-export`;

console.log('EXPORT CLIP PROXY URI:', clipExportProxyUrl); // eslint-disable-line no-console

const proxyApi = {
  post: invoke(clipExportProxyUrl, 'POST'),
  get: invoke(clipExportProxyUrl, 'GET'),
};

const createExportJob = (body) => proxyApi.post('/jobs', { body });

const fetchExportJobs = (body) => proxyApi.post('/jobs/list', { body });

const fetchExportJob = (id) => proxyApi.get(`/jobs/${id}`);

const exportClipClient = {
  createExportJob,
  fetchExportJobs,
  fetchExportJob,
};

export default exportClipClient;
