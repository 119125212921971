import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'next/router';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { selectTaskUploads } from '../../../store/selectors/uppy';
import { FILE_UPLOAD_STATUSES } from '../../../utils/constants';

import InProgressMessage from './InProgressMessage';
import SuccessMessage from './SuccessMessage';
import FailureMessage from './FailureMessage';
import ProgressCircle from './ProgressCircle';

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  margin: theme.spacing(1),
}));

class BackgroundFileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  isNavigationPreventionActive = false;

  componentDidUpdate() {
    if (!this.isNavigationPreventionActive && this.props.fileUploads) {
      this.toggleNavigationPrevention(true);
    }
    if (this.isNavigationPreventionActive && !this.props.fileUploads) {
      this.toggleNavigationPrevention(false);
    }
  }

  componentWillUnmount() {
    this.toggleNavigationPrevention(false);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  preventNavigation = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = ''; // eslint-disable-line
  };

  toggleNavigationPrevention = (enable) => {
    if (enable) {
      window.addEventListener('beforeunload', this.preventNavigation);
    } else {
      window.removeEventListener('beforeunload', this.preventNavigation);
    }
    this.isNavigationPreventionActive = enable;
  };

  render() {
    const { fileUploads } = this.props;

    if (!fileUploads) return null;

    return Object.keys(fileUploads).map((k, i) => {
      const upload = fileUploads[k];
      const isInProgress = (upload || []).some((f) => [
        FILE_UPLOAD_STATUSES.IN_PROGRESS,
        FILE_UPLOAD_STATUSES.INITIALIZED,
      ].includes(f.status));
      const isUploaded = (upload || []).every(
        (f) => f.status === FILE_UPLOAD_STATUSES.UPLOADED
      );
      const isFailed = (upload || []).some(
        (f) => f.status === FILE_UPLOAD_STATUSES.FAILED
      );
      const avgProgress =
        (upload || []).reduce((sume, el) => sume + el.progress.percentage, 0) /
        upload.length;

      const actions = [
        <ProgressCircle
          key="progress"
          progress={avgProgress}
          show={isInProgress}
        />,
      ];

      if (!isInProgress) {
        actions.push(
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            style={{ padding: 5 }}
            onClick={this.handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        );
      }

      return (
        <StyledSnackbar
          className="bg-uploader"
          style={{ transform: `translateY(-${i * 60}px)` }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.open}
          onClose={this.handleClose}
          key={i}
          autoHideDuration={!isInProgress ? 500 : null}
          message={
            <Fragment>
              {isInProgress && <InProgressMessage />}
              {isUploaded && <SuccessMessage />}
              {isFailed && <FailureMessage />}
            </Fragment>
          }
          action={actions}
        />
      );
    });
  }
}

BackgroundFileUploader.propTypes = {
  fileUploads: PropTypes.object,
};

const mapStateToProps = (state) => ({
  fileUploads: selectTaskUploads(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(BackgroundFileUploader);
