export const actionTypes = {
  GET_TABLE_PREFERENCES_SUCCESS: 'archive-GET_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'archive-RESET_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'archive-SET_TABLE_PREFERENCES',
};

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });

export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });

export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });
