import throttle from 'lodash/throttle';

export const throttleAction = (action, wait, options) => {
  const throttled = throttle(
    (dispatch, actionArgs) => dispatch(action(...actionArgs)),
    wait,
    options
  );

  const thunk = (...actionArgs) => dispatch => throttled(dispatch, actionArgs);

  thunk.cancel = throttled.cancel;
  thunk.flush = throttled.flush;

  return thunk;
};
