import moment from 'moment';
import last from 'lodash/last';
import get from 'lodash/get';
import pick from 'lodash/pick';
import {
  WORK_ORDER_TAG,
  SELF_MEDIA_DELIVERY_FLAG,
  RATING_NO_PREFERENCE,
  WORK_ORDER_MODIFICATION_SIGNALS,
} from '../../utils/constants';
import { actionTypes } from '../actions/submission';
import { actionTypes as clientOrderActionTypes } from '../actions/client-order';
import { isValidMetaData, metaValidation, getValidationValue, episodeMetaValidation, isValidAllEpisodeMetaData } from '../../utils/submission';
import { transformSubmission } from '../utils';

export const submissionDefaultState = {
  id: null,
  meta: {
    year: new Date().getFullYear(),
    eidr: '',
    requiredRating: RATING_NO_PREFERENCE,
    lriDelay: false,
    productParams: {},
    isKDMRequired: false,
    subtitles: false,
    isExternalVenueRequired: false,
    isRemoteComplianceViewingAllowed: false,
    additionalLanguages: [],
    mediaDeliverer: SELF_MEDIA_DELIVERY_FLAG,
    invoicingCompany: 'null',
    workflowVersion: 'v1',
    isDualRatingCriteriaAllowed: false,
    cinemaRegistrationNumber: '',
  },
  episodes: [],
  validation: {
    isValid: false,
    isAllEpisodesValid: true,
    hasAtLeastOneEpisode: true,
  },
  timeSlotSelection: {},
  updatableMetaFields: [
    'title',
    'year',
    'director',
    'genre',
    'language',
    'country',
    'season',
    'episode',
    'seasonTitle',
    'episodeTitle',
    'runtimeInSeconds',
    'lriDelay',
    'requiredClassificationDueDate',
    'releaseDate',
    'requiredRating',
    'actualRuntime',
    'actualRuntimeInSeconds',
    'versionNumber',
    'versionName',
    'versionDescription',
    'version',
    'additionalTitle',
    'productPath',
    'submissionRating',
    'publicationDate',
    'aspectRatio',
    'invoicingCompany',
    'numberOfLocations',
    'videoIdentical',
    'preschool',
    'modernFilmClassification',
    'compositeElements',
    'alsoKnownAs',
    'isDualRatingCriteriaAllowed',
    'cinemaRegistrationNumber',
  ],
  tags: [WORK_ORDER_TAG.NEW],
  journal: [],
  tasks: [],
  isLoaded: false,
  isBusy: false,
  isProductParamsLoading: false,
  apiError: false,
  error: {
    message: '',
    status: null,
  },
  isEidrLoading: false,
  eidrEditableFields: {},
  productTree: null,
  productTreeV2: null,
  certificate: { isLoading: false },
  videoAsset: {
    details: {},
    isLoading: false,
    list: [],
  },
  isCancellationRequested: false,
  sentSignals: [],
  waitingForModificationProcess: false,
  closedDays: [],
  minClassificationDays: {},
  parentClientOrderId: null,
  calendar: {
    offers: [],
    selectedOffer: null,
    params: null,
    reservation: null,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = submissionDefaultState, action) => {
  switch (action.type) {
    case actionTypes.START_NEW_SUCCESS:
      // Add new submission to an existing client-order
      if (action.payload.clientOrderId) {
        const disabledFields = ['title', 'season'];
        return {
          ...submissionDefaultState,
          updatableMetaFields: state.updatableMetaFields.filter((f) => !disabledFields.includes(f)),
          parentClientOrderId: action.payload.clientOrderId,
          ...pick(state, ['productTree', 'closedDays', 'minClassificationDays']), // keep data in the state what is not property of the WO entity
          isLoaded: true,
          apiError: false,
        };
      }

      return {
        ...submissionDefaultState,
        parentClientOrderId: action.payload.clientOrderId,
        ...pick(state, ['productTree', 'closedDays', 'minClassificationDays']), // keep data in the state what is not property of the WO entity
        isLoaded: true,
        apiError: false,
      };

    case clientOrderActionTypes.ADD_NEW_CLIENT_ORDER_SUCCESS:
      return {
        ...state,
        clientOrderId: action.payload.id,
        parentClientOrderId: action.payload.id,
      };
    case clientOrderActionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_SUCCESS:
      return { ...state, clientOrderId: action.payload.clientOrderId };
    case actionTypes.SET_META_DATA:
      return { ...state, ...action.payload };
    case actionTypes.SET_META_DATA_FIELD:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_TIME_SLOT_SELECTION:
      return {
        ...state,
        timeSlotSelection: {
          ...state.timeSlotSelection,
          ...action.payload,
        },
        episodes: state.episodes.map((episode) => ({
          ...episode,
          timeSlotSelection: {
            ...(episode?.timeSlotSelection || {}),
            ...action.payload,
          },
        })),
      };

    case actionTypes.ADD_NEW_START:
    case actionTypes.UPDATE_START:
    case actionTypes.LOAD_START:
    case actionTypes.LOAD_JOURNAL_START:
    case actionTypes.CHECK_META_MODIFICATION_START:
    case actionTypes.SYNC_START:
      return {
        ...state,
        isBusy: true,
      };
    case actionTypes.CANCEL_START:
      return { ...state, isBusy: true, isCancellationRequested: true };
    case actionTypes.GET_UPDATABLE_META_FIELDS_START:
    case actionTypes.LOAD_PRODUCT_TREE_START:
      return { ...state, isBusy: true };

    case actionTypes.LOAD_PRODUCT_PARAMS_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          productParams: { _path: action.payload.productPath }, // eslint-disable-line no-underscore-dangle
        },
        isBusy: true,
        isProductParamsLoading: true,
      };

    case actionTypes.ADD_NEW_SUCCESS:
    case actionTypes.UPDATE_SUCCESS:
    case actionTypes.LOAD_SUCCESS: {
      const submission = transformSubmission(action.payload.submission);

      let { waitingForModificationProcess } = state;
      if (waitingForModificationProcess) {
        const lastSentModificationSignal = last(state.sentSignals.filter(s => WORK_ORDER_MODIFICATION_SIGNALS.includes(s.signalName)));
        const { lastModificationCompletedAt } = submission.meta;
        if (lastModificationCompletedAt && moment(lastModificationCompletedAt).isAfter(lastSentModificationSignal.timestamp)) {
          waitingForModificationProcess = false;
        }
      }

      return {
        ...state,
        ...submission,
        waitingForModificationProcess,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.LOAD_PRODUCT_PARAMS_SUCCESS: {
      const { result: productParams } = action.payload;
      // DO NOT transform productParams. It will be compared to the original version on server side.
      // Transform in the scheduler service instead.
      return {
        ...state,
        meta: {
          ...state.meta,
          productParams,
        },
        isLoaded: true,
        isBusy: false,
        isProductParamsLoading: false,
        apiError: false,
      };
    }

    case actionTypes.LOAD_PRODUCT_TREE_SUCCESS: {
      const { result: productTree } = action.payload;
      return {
        ...state,
        productTree,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.LOAD_PRODUCT_TREE_SUCCESS_V2: {
      const { result: productTreeV2 } = action.payload;
      return {
        ...state,
        productTreeV2,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.LOAD_JOURNAL_SUCCESS: {
      const { journal } = action.payload;
      return {
        ...state,
        journal,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.CANCEL_SUCCESS: {
      return {
        ...state,
        isLoaded: false,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.SYNC_SUCCESS:
    case actionTypes.CHECK_META_MODIFICATION_SUCCESS: {
      return {
        ...state,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.START_NEW_FAILURE:
    case actionTypes.ADD_NEW_FAILURE:
    case actionTypes.UPDATE_FAILURE:
    case actionTypes.LOAD_FAILURE:
    case actionTypes.LOAD_JOURNAL_FAILURE:
    case actionTypes.CHECK_META_MODIFICATION_FAILURE:
    case actionTypes.SYNC_FAILURE:
    case actionTypes.LOAD_PRODUCT_TREE_FAILURE: {
      const error = get(action.payload, 'error');
      return {
        ...state,
        isBusy: false,
        apiError: true,
        error,
      };
    }
    case actionTypes.LOAD_PRODUCT_PARAMS_FAILURE: {
      return {
        ...state,
        isBusy: false,
        isProductParamsLoading: false,
      };
    }
    case actionTypes.CANCEL_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
        isCancellationRequested: false,
      };
    }
    case actionTypes.SET_FILE_UPLOADED: {
      return {
        ...state,
        status: 'FILE_UPLOADED',
      };
    }

    case actionTypes.RESET: {
      return { ...submissionDefaultState };
    }

    case actionTypes.VALIDATE_META: {
      const { meta = {}, options } = action;
      const validationOptions = {
        fieldSet: get(state.meta, 'productParams.input'),
        ...pick(state, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const validation = metaValidation(meta, validationOptions, options) || {};
      const values = Object.keys(validation).reduce((acc, k) => ({ ...acc, [k]: getValidationValue(validation, k) }), {});

      return {
        ...state,
        validation: {
          isValid: isValidMetaData(meta, options, validationOptions),
          ...values,
        },
      };
    }

    case actionTypes.VALIDATE_EPISODES_META: {
      if (!get(state.meta, 'productPath', '').includes('Episodic')) {
        return {
          ...state,
          validation: {
            ...state.validation,
            isAllEpisodesValid: true,
            hasAtLeastOneEpisode: true,
          },
        };
      }

      const validationOptions = {
        fieldSet: get(state.meta, 'productParams.input'),
        ...pick(state, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const episodeValidationValues = state.episodes.map(e => {
        const validation = episodeMetaValidation(e, validationOptions);
        const values = Object.keys(validation).reduce((acc, k) => ({ ...acc, [k]: getValidationValue(validation, k) }), {});

        return { id: e.id, ...values };
      });

      return {
        ...state,
        validation: {
          ...state.validation,
          isAllEpisodesValid: isValidAllEpisodeMetaData(state.episodes, validationOptions),
          hasAtLeastOneEpisode: (state.episodes || []).length > 0,
          episodes: episodeValidationValues,
        },
      };
    }

    case actionTypes.VALIDATE_META_FIELD: {
      const { meta = {}, field } = action;
      const validationOptions = {
        fieldSet: get(state.meta, 'productParams.input'),
        ...pick(state, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const validation = metaValidation(meta, validationOptions) || {};

      return {
        ...state,
        validation: {
          ...state.validation,
          [field]: getValidationValue(validation, field),
        },
      };
    }

    case actionTypes.VALIDATE_EPISODE_META_FIELD: {
      const { field, episodeId } = action;
      const validationOptions = {
        fieldSet: get(state.meta, 'productParams.input'),
        ...pick(state, ['minClassificationDays', 'closedDays', 'updatableMetaFields']),
      };

      const episodeValidationValues = state.episodes.map(e => {
        const episodeValidation = (state.validation.episodes || []).find((v) => v.id === e.id);
        if (e.id !== episodeId) return episodeValidation;
        const validation = episodeMetaValidation(e, validationOptions);
        return { ...episodeValidation, [field]: getValidationValue(validation, field) };
      });

      return {
        ...state,
        validation: {
          ...state.validation,
          episodes: episodeValidationValues,
        },
      };
    }

    case actionTypes.RESET_META_DATA: {
      return {
        ...state,
        meta: {
          ...submissionDefaultState.meta,
        },
      };
    }

    case actionTypes.RESET_ID: {
      return {
        ...state,
        id: null,
      };
    }

    case actionTypes.EIDR_FETCH_START: {
      return { ...state, isEidrLoading: true };
    }
    case actionTypes.EIDR_FETCH_SUCCESS: {
      const { eidr, eidrEditableFields } = action.payload;
      return {
        ...state,
        isEidrLoading: false,
        apiError: false,
        meta: { ...state.meta, eidr },
        eidrEditableFields: {
          ...eidrEditableFields,
          eidr: true,
          genre: true,
          requiredRating: true,
          requiredClassificationDueDate: true,
        },
      };
    }
    case actionTypes.EIDR_FETCH_FAILURE: {
      return { ...state, isEidrLoading: false, apiError: action.payload, meta: { ...state.meta, eidr: null }, eidrEditableFields: {} };
    }
    case actionTypes.EIDR_CLEAR: {
      return { ...state, meta: { ...state.meta, eidr: null }, eidrEditableFields: {} };
    }
    case actionTypes.GET_CERTIFICATE_START: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          list: state.certificate.list?.map(cert => {
            if (cert.subType !== action.certType || cert.version !== action.version) return cert;
            return { ...cert, isLoading: true };
          }),
        },
      };
    }
    case actionTypes.GET_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          list: state.certificate.list?.map(cert => {
            if (cert.subType !== action.certType || cert.version !== action.version) return cert;
            return { ...cert, isLoading: false };
          }),
        },
      };
    }
    case actionTypes.GET_CERTIFICATE_FAILURE: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          list: state.certificate.list?.map(cert => {
            if (cert.subType !== action.certType || cert.version !== action.version) return cert;
            return { ...cert, isLoading: false };
          }),
        },
      };
    }
    case actionTypes.GET_CERTIFICATE_LIST_START: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          isLoading: true,
        },
      };
    }
    case actionTypes.GET_CERTIFICATE_LIST_SUCCESS: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          list: action.payload,
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_CERTIFICATE_LIST_FAILURE: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_VIDEO_ASSET_LIST_START: {
      return {
        ...state,
        videoAsset: {
          ...state.videoAsset,
          isLoading: true,
        },
      };
    }
    case actionTypes.GET_VIDEO_ASSET_LIST_SUCCESS: {
      return {
        ...state,
        videoAsset: {
          ...state.videoAsset,
          isLoading: false,
          list: action.payload,
        },
      };
    }
    case actionTypes.GET_VIDEO_ASSET_LIST_FAILURE: {
      return {
        ...state,
        videoAsset: {
          ...state.videoAsset,
          isLoading: false,
        },
      };
    }
    case actionTypes.DOWNLOAD_VIDEO_ASSET_START:
    case actionTypes.DOWNLOAD_VIDEO_ASSET_FAILURE: {
      return {
        ...state,
        videoAsset: {
          ...state.videoAsset,
        },
      };
    }
    case actionTypes.DOWNLOAD_VIDEO_ASSET_SUCCESS: {
      return {
        ...state,
        videoAsset: {
          ...state.videoAsset,
          details: action.payload,
        },
      };
    }
    case actionTypes.GET_UPDATABLE_META_FIELDS_SUCCESS: {
      const { allowedFields: updatableMetaFields } = action.payload;

      return {
        ...state,
        updatableMetaFields,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.SIGNAL_START: {
      const { signalName } = action.payload;
      return {
        ...state,
        sentSignals: [ // keep the order please
          ...state.sentSignals,
          {
            signalName,
            timestamp: Date.now(),
          },
        ],
        waitingForModificationProcess: state.waitingForModificationProcess || WORK_ORDER_MODIFICATION_SIGNALS.includes(signalName),
      };
    }
    case actionTypes.SUCCESS_FETCH_SUBMISSION_ADDITION_INFORMATIONS:
      return {
        ...state,
        closedDays: action.payload.closedDays,
        minClassificationDays: action.payload.minClassificationDays,
      };
    case actionTypes.INCREMENT_EPISODE: {
      return { ...state, meta: { ...state.meta, episode: Number(state.meta.episode) + 1 } };
    }
    case actionTypes.INCREMENT_VERSION_NUMBER: {
      return { ...state, meta: { ...state.meta, versionNumber: state.meta.versionNumber + 1 } };
    }
    case actionTypes.ADD_EPISODE: {
      return {
        ...state,
        episodes: [
          ...state.episodes,
          { ...action.payload, id: state.episodes.length },
        ],
        validation: {
          ...state.validation,
          hasAtLeastOneEpisode: true,
        },
      };
    }
    case actionTypes.DELETE_EPISODE: {
      const newEpisodes = state.episodes.filter((episode) => episode.id !== action.payload);
      return {
        ...state,
        episodes: newEpisodes,
      };
    }
    case actionTypes.DELETE_EPISODES: {
      return { ...state, episodes: [] };
    }
    case actionTypes.SET_EPISODE_METADATA: {
      return {
        ...state,
        episodes: state.episodes.map((episode) => {
          if (action.payload.id !== episode.id) return episode;
          return { ...episode, [action.payload.field]: action.payload.value };
        }),
      };
    }
    default:
      return state;
  }
};

export default reducer;
