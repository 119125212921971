import { actionTypes } from '../actions/genres';

export const genresDefaultState = {
  error: '',
  loading: false,
  rows: [],
};

// eslint-disable-next-line default-param-last
export const reducer = (state = genresDefaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GENRES:
      return { ...state, error: '', loading: true };
    case actionTypes.FETCH_GENRES_SUCCESS:
      return { ...state, rows: action.payload, error: '', loading: false };
    case actionTypes.FETCH_GENRES_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload && action.payload.message };
    default:
      return state;
  }
};

export default reducer;
