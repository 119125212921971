import {
  createStore,
  applyMiddleware,
} from 'redux';
import {
  composeWithDevTools,
} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import Router from 'next/router';
import getConfig from 'next/config';
import reducer, {
  initialState,
} from '../store/reducers';
import * as processAdminClient from './processAdminClient';
import schedulerClient from './schedulerClient';
import * as identityClient from './identityClient';
import * as messagingClient from './messagingClient';
import * as notificationsClient from './notificationsClient';
import * as financeClient from './financeClient';
import * as taskClient from './taskClient';
import * as cookie from '../utils/cookie';
import * as localStorage from '../utils/localStorage';
import vidigraphClient from './vidigraphClient';
import tagClient from './tagClient';
import * as dataApiClient from './dataApiClient';
import * as pricingClient from './pricingClient';
import exportClipClient from './exportClipClient';
import * as googleClient from './googleClient';

import {
  actionTypes as notificationActionTypes,
} from '../store/actions/notifications';
import {
  actionTypes as pollerActionTypes,
} from '../store/actions/poller';
import {
  actionTypes as submissionActionTypes,
} from '../store/actions/submission';
import {
  actionTypes as appActionTypes,
} from '../store/actions/app';
import {
  actionTypes as messagingActionTypes,
} from '../store/actions/messaging';
import uppyMiddleware from '../store/middlewares/uppy';
import createUppyClient from './uppyClient';
import * as window from './window';

const {
  publicRuntimeConfig,
  serverRuntimeConfig,
} = getConfig();
const {
  enableDevTools,
} = publicRuntimeConfig;

const middlewares = applyMiddleware(
  thunk.withExtraArgument({
    processAdminClient,
    schedulerClient,
    identityClient,
    messagingClient,
    taskClient,
    vidigraphClient,
    tagClient,
    Router,
    cookie,
    localStorage,
    notificationsClient,
    publicRuntimeConfig,
    serverRuntimeConfig,
    createUppyClient,
    financeClient,
    dataApiClient,
    pricingClient,
    exportClipClient,
    googleClient,
    window,
  }),
  uppyMiddleware({ createUppyClient, processAdminClient }),
);

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [
    ...Object.values(notificationActionTypes),
    ...Object.values(pollerActionTypes),
    appActionTypes.SAVE_SESSION_COUNTDOWN_TIMEOUT,
    appActionTypes.CLEAR_SESSION_COUNTDOWN_TIMEOUT,
    appActionTypes.RESET_SESSION_COUNTDOWN_COUNTER,
    appActionTypes.DECREASE_SESSION_COUNTDOWN,
    appActionTypes.SAVE_SESSION_LAST_SYNC,
    submissionActionTypes.SET_META_DATA,
    messagingActionTypes.SAVE_CHECK_MESSAGES_TIMEOUT,
    messagingActionTypes.START_CHECK_MESSAGES,
    messagingActionTypes.CLEAR_CHECK_MESSAGES_TIMEOUT,
    messagingActionTypes.RESOLVE_CHECK_MESSAGES,
  ],
});

export const initStore = (state = initialState) => (
  createStore(
    reducer,
    state,
    enableDevTools ? composeEnhancers(middlewares) : middlewares
  )
);

export default initStore;
