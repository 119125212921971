import { actionTypes } from '../actions/my-tasks';

const tableColums = [
  { columnName: 'id', width: 100 },
  { columnName: 'options.title', width: 240 },
  { columnName: 'workOrder.company.companyName', width: 120 },
  { columnName: 'workOrder.user.fullName', width: 180 },
  { columnName: 'orderId', width: 170 },
  { columnName: 'workOrder.meta.title', width: 120 },
  { columnName: 'options.subTitleView.additionalTitle', width: 120 },
  { columnName: 'workOrder.meta.productPath', width: 200 },
  { columnName: 'workOrder.tags', width: 180 },
  { columnName: 'workOrder.meta.director', width: 120 },
  { columnName: 'workOrder.meta.runtime', width: 120 },
  { columnName: 'workOrder.meta.season', width: 100 },
  { columnName: 'workOrder.meta.seasonTitle', width: 100 },
  { columnName: 'workOrder.meta.episode', width: 100 },
  { columnName: 'workOrder.meta.episodeTitle', width: 120 },
  { columnName: 'workOrder.meta.versionName', width: 120 },
  { columnName: 'workOrder.meta.versionNumber', width: 120 },
  { columnName: 'assignees', width: 180 },
  { columnName: 'dueDate', width: 120 },
  { columnName: 'createdAt', width: 135 },
  { columnName: 'status', width: 100 },
  { columnName: 'options', width: 100 },
];

export const myTasksDefaultState = {
  list: [],
  count: 0,
  isLoaded: false,
  isBusy: false,
  apiError: false,
  tablePreferences: {
    hiddenColumns: [
      'orderId',
      'workOrder.meta.director',
      'workOrder.meta.runtime',
      'workOrder.meta.season',
      'workOrder.meta.versionName',
      'workOrder.meta.versionNumber',
      'workOrder.meta.seasonTitle',
      'workOrder.meta.episode',
      'workOrder.meta.episodeTitle',
      'options.subTitleView.additionalTitle',
    ],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = myTasksDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_START: {
      return {
        ...state,
        isLoaded: false,
        isBusy: true,
      };
    }
    case actionTypes.GET_LIST_SUCCESS: {
      const { result: list, count } = action.payload;

      return {
        ...state,
        list,
        count,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.GET_LIST_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: myTasksDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: {
          ...state.tablePreferences,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
