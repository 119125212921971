export const actionTypes = {
  GET_LIST_START: 'ss-GET_LIST_START',
  GET_LIST_SUCCESS: 'ss-GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'ss-GET_LIST_FAILURE',
  INVALIDATE_LIST: 'ss-INVALIDATE_LIST',
  GET_TABLE_PREFERENCES_SUCCESS: 'ss-GET_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'ss-RESET_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'ss-SET_TABLE_PREFERENCES',
  TOGGLE_SHOW_DELETED_ITEMS: 'ss-TOGGLE_SHOW_DELETED_ITEMS',
  CALCULATE_PRICES_V2_START: 'ss-CALCULATE_PRICES_V2_START',
  CALCULATE_PRICES_V2_SUCCESS: 'ss-CALCULATE_PRICES_V2_SUCCESS',
  CALCULATE_PRICES_V2_FAILURE: 'ss-CALCULATE_PRICES_V2_FAILURE',
};

export const getListStart = () => ({
  type: actionTypes.GET_LIST_START,
});

export const getListSuccess = result => ({
  type: actionTypes.GET_LIST_SUCCESS,
  payload: result,
});

export const getListFailure = error => ({
  type: actionTypes.GET_LIST_FAILURE,
  payload: error,
});

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });

export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });

export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });

export const invalidateList = () => ({
  type: actionTypes.INVALIDATE_LIST,
});

export const toggleShowDeletedItems = () => ({
  type: actionTypes.TOGGLE_SHOW_DELETED_ITEMS,
});

export const calculatePricesV2Start = () => ({ type: actionTypes.CALCULATE_PRICES_V2_START });
export const calculatePricesV2Success = payload => ({ type: actionTypes.CALCULATE_PRICES_V2_SUCCESS, payload });
export const calculatePricesV2Failure = payload => ({ type: actionTypes.CALCULATE_PRICES_V2_FAILURE, payload });

const submissions = {
  getListStart,
  getListSuccess,
  getListFailure,
  invalidateList,
  calculatePricesV2Start,
  calculatePricesV2Success,
  calculatePricesV2Failure,
};

export default submissions;