import {
  FETCH_COLLECTION,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COLLECTION_FAILURE,
  FETCH_RELATED_COLLECTION,
  FETCH_RELATED_COLLECTION_FAILURE,
  FETCH_RELATED_COLLECTION_SUCCESS,
  FETCH_TV_SHOW_EPISODES_FAILURE,
  FETCH_TV_SHOW_EPISODES,
  FETCH_TV_SHOW_EPISODES_SUCCESS,
  CLEAR_COLLECTION,
  COLLECTION_OPERATION,
  COLLECTION_OPERATION_SUCCESS,
  COLLECTION_OPERATION_FAILURE,
} from '../types';

export const collectionsDefaultState = {
  error: {
    message: '',
    status: null,
  },
  loading: true,
  count: 0,
  autocomplete: [],
  collection: [],
  related: [],
  episodes: [],
};

// eslint-disable-next-line default-param-last
export const reducer = (state = collectionsDefaultState, action) => {
  switch (action.type) {
    case FETCH_COLLECTION:
      return { ...state, error: {}, loading: true };
    case FETCH_RELATED_COLLECTION:
      return { ...state, error: {}, loading: true, related: [] };
    case FETCH_TV_SHOW_EPISODES:
      return { ...state, error: {}, loading: true, episodes: [] };
    case FETCH_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: action.payload.rows,
        count: action.payload.count,
        error: {},
        loading: false,
      };
    case FETCH_RELATED_COLLECTION_SUCCESS: {
      if (action.payload.hits === 0) {
        return { ...state };
      }

      const { collection } = action.payload;
      collection.forEach((item, i) => {
        if (item.metadata && item.metadata.timespan.length > 0) {
          item.metadata.timespan[0].field.forEach(field => {
            if (field.value) {
              const value = field.value.map(val => val.value);
              collection[i][field.name] = value.join(', ');
            }
          });
        }
      });

      return { ...state, related: collection, error: {}, loading: false };
    }
    case FETCH_TV_SHOW_EPISODES_SUCCESS: {
      const episodes = action.payload;
      episodes.forEach((item, i) => {
        if (item.metadata && item.metadata.timespan.length > 0) {
          item.metadata.timespan[0].field.forEach(field => {
            if (field.value) {
              const value = field.value.map(val => val.value);
              episodes[i][field.name] = value.join(', ');
            }
          });
        }
      });

      return { ...state, episodes, error: {}, loading: false };
    }
    case FETCH_COLLECTION_FAILURE:
      return { ...state, collection: [], autocomplete: [], loading: false, error: action.payload.error.message };
    case CLEAR_COLLECTION:
      return { ...state, collection: [], autocomplete: [], loading: false };
    case FETCH_RELATED_COLLECTION_FAILURE:
      return { ...state, related: [], loading: false, error: action.payload.error.message };
    case FETCH_TV_SHOW_EPISODES_FAILURE:
      return { ...state, related: [], loading: false, error: action.payload.error.message };
    case COLLECTION_OPERATION:
      return { ...state, error: {}, loading: true };
    case COLLECTION_OPERATION_SUCCESS:
      return { ...state, error: {}, loading: false };
    case COLLECTION_OPERATION_FAILURE:
      return { ...state, error: action.payload.error.message, loading: false };
    default:
      return state;
  }
};

export default reducer;
