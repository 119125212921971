import { actionTypes } from '../actions/asset';

export const assetDefaultState = {
  detail: {},
  error: null,
  loading: false,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = assetDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSET_START:
      return { ...state, error: null, loading: true };

    case actionTypes.GET_ASSET_SUCCESS:
      return { ...state, detail: action.payload, error: null, loading: false };
    case actionTypes.GET_ASSET_FAILURE:
      return { ...state, detail: {}, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
