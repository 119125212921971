import { actionTypes } from '../actions/resources';

export const resourcesDefaultState = {
  genres: [],
  complianceOfficers: [],
  gsuiteUsers: [],
  isGenresLoaded: false,
  isComplianceOfficersLoaded: false,
  isGsuiteUsersLoaded: false,
  isBusy: false,
  apiError: false,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = resourcesDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_GENRES_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.GET_GENRES_SUCCESS: {
      const { genres } = action.payload;
      return {
        ...state,
        genres: genres.map(({ id, genre_name }) => ({ id, name: genre_name })), // eslint-disable-line camelcase
        isBusy: false,
        isGenresLoaded: true,
        apiError: false,
      };
    }
    case actionTypes.GET_GENRES_FAILURE: {
      return {
        ...state,
        isBusy: false,
        isGenresLoaded: false,
        apiError: true,
      };
    }
    case actionTypes.ADD_GENRE_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.ADD_GENRE_SUCCESS: {
      const { genres } = action.payload; // TODO ask service-scheduler to return the full list in this case
      return {
        ...state,
        genres,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.ADD_GENRE_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.GET_GSUITE_USERS_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.GET_GSUITE_USERS_SUCCESS: {
      const { gsuiteUsers } = action.payload;
      return {
        ...state,
        gsuiteUsers,
        isBusy: false,
        isGsuiteUsersLoaded: true,
        apiError: false,
      };
    }
    case actionTypes.GET_GSUITE_USERS_FAILURE: {
      return {
        ...state,
        isBusy: false,
        isGsuiteUsersLoaded: false,
        apiError: true,
      };
    }
    case actionTypes.GET_COMPLIANCE_OFFICERS_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.GET_COMPLIANCE_OFFICERS_SUCCESS: {
      const { complianceOfficers } = action.payload;
      return {
        ...state,
        complianceOfficers,
        isBusy: false,
        isComplianceOfficersLoaded: true,
        apiError: false,
      };
    }
    case actionTypes.GET_COMPLIANCE_OFFICERS_FAILURE: {
      return {
        ...state,
        isBusy: false,
        isComplianceOfficersLoaded: false,
        apiError: true,
      };
    }
    case actionTypes.ADD_COMPLIANCE_OFFICER_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.ADD_COMPLIANCE_OFFICER_SUCCESS: {
      return {
        ...state,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.ADD_COMPLIANCE_OFFICER_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.UPDATE_COMPLIANCE_OFFICER_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.UPDATE_COMPLIANCE_OFFICER_SUCCESS: {
      return {
        ...state,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.UPDATE_COMPLIANCE_OFFICER_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.DELETE_COMPLIANCE_OFFICER_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.DELETE_COMPLIANCE_OFFICER_SUCCESS: {
      return {
        ...state,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.DELETE_COMPLIANCE_OFFICER_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.SET_COMPLIANCE_OFFICER_GENRE_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.SET_COMPLIANCE_OFFICER_GENRE_SUCCESS: {
      return {
        ...state,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.SET_COMPLIANCE_OFFICER_GENRE_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
