import get from 'lodash/get';
import { actionTypes } from '../actions/messaging';

export const messagingDefaultState = {
  loading: false,
  data: [],
  error: null,
  timeoutID: null,
  users: [],
  replyID: null,
  unreadMessages: {
    loading: false,
    error: null,
    count: 0,
    timeoutID: null,
  },
  attachment: {
    downloading: false,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = messagingDefaultState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_MESSAGES:
    case actionTypes.START_SEND_MESSAGE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case actionTypes.START_DOWNLOAD_ATTACHMENT: {
      return {
        ...state,
        attachment: {
          downloading: true,
        },
      };
    }
    case actionTypes.RESOLVE_FETCH_MESSAGES: {
      if (action.payload.data.length === state.data.length) {
        return {
          ...state,
          loading: action.payload.firstStart ? false : state.isLoading,
        };
      }
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    }
    case actionTypes.RESOLVE_DOWNLOAD_ATTACHMENT: {
      return {
        ...state,
        attachment: {
          downloading: false,
        },
      };
    }
    case actionTypes.RESOLVE_SEND_MESSAGE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.REJECT_DOWNLOAD_ATTACHMENT: {
      return {
        ...state,
        error: action.payload,
        attachment: {
          downloading: false,
        },
      };
    }
    case actionTypes.REJECT_FETCH_MESSAGES:
    case actionTypes.REJECT_SEND_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actionTypes.SAVE_MESSAGING_TIMEOUT:
      clearTimeout(state.timeoutID);
      return {
        ...state,
        timeoutID: action.payload,
      };
    case actionTypes.CLEAR_MESSAGING_TIMEOUT:
      clearTimeout(state.timeoutID);
      if (action.payload.clearOnlyTimeout) {
        return {
          ...state,
          timeoutID: null,
        };
      }
      return messagingDefaultState;
    case actionTypes.SAVE_USERS_DETAILS:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.SET_REPLY_ID:
      return {
        ...state,
        replyID: action.payload,
      };
    case actionTypes.START_CHECK_MESSAGES: {
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          loading: true,
        },
      };
    }
    case actionTypes.REJECT_CHECK_MESSAGES: {
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          loading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.RESOLVE_CHECK_MESSAGES: {
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          loading: false,
          count: get(action, 'payload.data.count', 0),
        },
      };
    }
    case actionTypes.SAVE_CHECK_MESSAGES_TIMEOUT:
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          timeoutID: action.payload,
        },
      };
    case actionTypes.CLEAR_CHECK_MESSAGES_TIMEOUT:
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          timeoutID: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
