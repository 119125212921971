import jwtDecode from 'jwt-decode';

import {
  fetchCountriesStarted,
  fetchCountriesSuccess,
  fetchCountriesFailed,
  fetchNewsStarted,
  fetchNewsSuccess,
  fetchNewsFailed,
  updateNewsStarted,
  updateNewsSuccess,
  updateNewsFailed,
  notify,
  saveSessionCountdownTimeout,
  resetSessionCountdownCounter,
  fetchFlagsStart,
  fetchFlagsSuccess,
  fetchFlagsFailure,
  getWorkflowVersionByIdStart,
  getWorkflowVersionByIdSuccess,
  getWorkflowVersionByIdFailure,
} from '../actions/app';
import { NOTIFICATION_VARIANTS } from '../../utils/constants';

import { logoutUser } from './authentication';

const TICK_INTERVAL = 1000;

export const getCountries = () => async (dispatch, _, { processAdminClient }) => {
  try {
    dispatch(fetchCountriesStarted());
    const countries = await dispatch(processAdminClient.getCountries());
    return dispatch(fetchCountriesSuccess(countries));
  } catch (error) {
    dispatch(notify(error.message || 'Failed to load countries', NOTIFICATION_VARIANTS.ERROR));
    return dispatch(fetchCountriesFailed(error));
  }
};

export const getNews = () => async (dispatch, _, { notificationsClient }) => {
  try {
    dispatch(fetchNewsStarted());
    const news = await dispatch(notificationsClient.getNews());
    return dispatch(fetchNewsSuccess(news));
  } catch (error) {
    return dispatch(fetchNewsFailed(error));
  }
};

export const updateNews = (data) => async (dispatch, _, { notificationsClient }) => {
  try {
    dispatch(updateNewsStarted());
    await dispatch(notificationsClient.updateNews(data));
    dispatch(getNews());
    return dispatch(updateNewsSuccess());
  } catch (error) {
    dispatch(notify(error.message || 'Failed to update news', NOTIFICATION_VARIANTS.ERROR));
    return dispatch(updateNewsFailed(error));
  }
};

export const startSessionCountdown = () => (dispatch, getState, { cookie }) => {
  const intervalId = setInterval(() => {
    const token = cookie.getCookie('token');
    if (token) {
      const { exp } = jwtDecode(token);
      const remainingTime = Number(exp * 1000) - Date.now();
      dispatch(resetSessionCountdownCounter(remainingTime));

      // Force logout the user before the token expires
      if (remainingTime < 2000) {
        return dispatch(logoutUser('Your session has expired due to inactivity.'));
      }
    } else {
      return dispatch(logoutUser);
    }
  }, TICK_INTERVAL);

  dispatch(saveSessionCountdownTimeout(intervalId));
};

export const getFlags = () => async (dispatch, _, { processAdminClient }) => {
  try {
    dispatch(fetchFlagsStart());
    const result = await dispatch(processAdminClient.getFlags());
    return dispatch(fetchFlagsSuccess(result));
  } catch (error) {
    return dispatch(fetchFlagsFailure(error));
  }
};

export const getWorkflowVersionById = (workOrderId) => async (dispatch, _, { taskClient }) => {
  try {
    dispatch(getWorkflowVersionByIdStart());
    const { workflowVersion } = await dispatch(taskClient.getWorkflowVersionById(workOrderId));
    dispatch(getWorkflowVersionByIdSuccess(workflowVersion));
    return workflowVersion;
  } catch (error) {
    dispatch(notify('Failed to load submission, please reload', NOTIFICATION_VARIANTS.ERROR));
    dispatch(getWorkflowVersionByIdFailure());
  }
};