import { actionTypes } from '../actions/tasks';

const tableColums = [
  { columnName: 'id', width: 100 },
  { columnName: 'options.title', width: 220 },
  { columnName: 'orderId', width: 200 },
  { columnName: 'workOrder.meta.title', width: 120 },
  { columnName: 'workOrder.meta.season', width: 100 },
  { columnName: 'workOrder.meta.episode', width: 100 },
  { columnName: 'workOrder.meta.episodeTitle', width: 120 },
  { columnName: 'assignees', width: 180 },
  { columnName: 'dueDate', width: 180 },
  { columnName: 'createdAt', width: 180 },
  { columnName: 'status', width: 120 },
  { columnName: 'options', width: 100 },
  { columnName: 'isBlocked', width: 100 },
];

export const tasksDefaultState = {
  list: [],
  count: 0,
  assignableUsers: [],
  myTasks: { list: [], count: 0 },
  isLoaded: false,
  isBusy: false,
  apiError: false,
  userInputTaskModalOpen: false,
  tablePreferences: {
    hiddenColumns: [
      'workOrder.meta.season',
      'workOrder.meta.episode',
      'workOrder.meta.episodeTitle',
    ],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = tasksDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_TASKS_START:
    case actionTypes.GET_ASSIGNABLE_USERS_START:
    case actionTypes.GET_LIST_START: {
      return {
        ...state,
        isLoaded: false,
        isBusy: true,
      };
    }
    case actionTypes.FULFILL_START:
    case actionTypes.UPDATE_START:
    case actionTypes.ASSIGN_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.UPDATE_SUCCESS:
    case actionTypes.ASSIGN_SUCCESS: {
      return {
        ...state,
        isBusy: false,
      };
    }
    case actionTypes.GET_LIST_SUCCESS: {
      const { result: list, count } = action.payload;

      return {
        ...state,
        list,
        count,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.GET_MY_TASKS_SUCCESS: {
      const { result: list, count } = action.payload;
      return {
        ...state,
        myTasks: { list, count },
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.GET_ASSIGNABLE_USERS_SUCCESS: {
      const assignableUsers = action.payload;

      return {
        ...state,
        assignableUsers,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.FULFILL_SUCCESS: {
      const updatedTask = action.payload;
      const { id } = updatedTask;

      return {
        ...state,
        list: state.list.map((task) => {
          if (task.id === id) {
            // merge necessary, because there is no joined data (from other db tables) in the result of the update
            return { ...task, ...updatedTask };
          }
          return task;
        }),
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.ASSIGN_FAILURE:
    case actionTypes.GET_MY_TASKS_FAILURE:
    case actionTypes.GET_ASSIGNABLE_USERS_FAILURE:
    case actionTypes.FULFILL_FAILURE:
    case actionTypes.UPDATE_FAILURE:
    case actionTypes.GET_LIST_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: tasksDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    case actionTypes.OPEN_USER_INPUT_TASK_MODAL: {
      return {
        ...state,
        userInputTaskModalOpen: true,
      };
    }
    case actionTypes.CLOSE_USER_INPUT_TASK_MODAL: {
      return {
        ...state,
        userInputTaskModalOpen: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
