import React from 'react';
import Icon from '@mui/icons-material/Cloud';

const FailureMessage = () => (
  <span className="message">
    <Icon className="icon" />
    <span>Failed to upload file(s)</span>
  </span>
);

export default FailureMessage;