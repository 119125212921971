import getConfig from 'next/config';
import { invoke } from './api';
import { SKIP_JOIN_METADATA_FIELDS, SPLIT_METADATA_FIELDS, OBJECT_METADATA_FIELDS } from '../utils/constants';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const vidigraphProxyUrl = `${apiUrl}/vidigraph`;

const headers = { Accept: 'application/json' };

console.log('VIDIGRAPH PROXY URI:', vidigraphProxyUrl); // eslint-disable-line no-console

const proxyApi = {
  get: invoke(vidigraphProxyUrl, 'GET'),
  post: invoke(vidigraphProxyUrl, 'POST'),
  put: invoke(vidigraphProxyUrl, 'PUT'),
  patch: invoke(vidigraphProxyUrl, 'PATCH'),
  delete: invoke(vidigraphProxyUrl, 'DELETE'),
};

const getArchiveItems = (payload) => proxyApi.post('/archive/items', { body: payload });

const getArchiveItem = (itemId) => proxyApi.get(`/archive/items/${itemId}`);

const syncSubmission = (submissionId, assetId, version) => proxyApi.post('/asset/sync', { body: { submissionId, assetId, version } });

const encodeQuery = (obj) => Object.keys(obj).map(
  k => `${k}=${encodeURIComponent(obj[k])}`
).join('&');

const searchByTypeBody = (type, q) => {
  const typeValue = !Array.isArray(type)
    ? [{ value: type }]
    : type.map(value => ({ value }));

  return {
    version: 2,
    field: [
      { name: 'title', value: [{ value: q }] },
      { name: 'horizon_collection_type', value: typeValue },
    ],
    autocomplete: [
      { field: 'title', text: q },
    ],
  };
};

const searchByType = (type, collectionType, q, page = 1, rowsPerPage = 15) => proxyApi.put(type, {
  headers,
  query: {
    number: rowsPerPage,
    first: (page * rowsPerPage) + 1,
    field: ['horizon_director', 'horizon_year', 'horizon_season', 'horizon_collection_type'].join(','),
  },
  body: searchByTypeBody(collectionType, q),
});

const searchByCollectionId = (bodyFilter) => proxyApi.put('/collection?number=100', {
  headers,
  body: bodyFilter,
});

const getSuggested = (text, entityType = 'item') => proxyApi.put('search/autocomplete', { body: { text, maximumSuggestions: 5 }, query: { entityType }, headers });

const getVideoQuery = encodeQuery({ content: 'metadata,uri', methodType: 'AUTO', tag: 'lowres' });
const getVideo = (id, type) => proxyApi.get(`${type}/${id}?${getVideoQuery}`, { headers });

const relatedItemsQuery = encodeQuery({
  content: 'metadata',
  field: [
    'title',
    'horizon_classification',
    'horizon_shortFormInsight',
    'horizon_TypeOfMedia',
    'horizon_runtime',
    'horizon_distributor',
    'horizon_DistributorChange',
    'horizon_classificationDate',
    'horizon_releaseDate',
    'horizon_episodeTitle',
    'horizon_director',
    'horizon_DatePassed',
    'horizon_season',
    'horizon_episode',
    'horizon_AdviceViewing',
    'horizon_productPath',
    'horizon_genre',
    'TVMETA_EP_TYPE',
    'TVMETA_EP_TIMECODE',
    'tvmeta_ep_eci',
    'tvmeta_ep_cat',
    'tvmeta_CRA_type',
    '__collection',
    'horizon_source',
  ].join(','),
});

const getRelatedWork = (id) => proxyApi.get(`collection/${id}/item?${relatedItemsQuery}`, { headers });

const relatedCollectionsQuery = encodeQuery({
  content: 'metadata',
  number: 50,
  field: [
    'title',
    'horizon_classification',
    'horizon_director',
    'horizon_collection_type',
    '__collection',
  ].join(','),
});

const getRelatedCollections = (id) => proxyApi.put(`collection?${relatedCollectionsQuery}`, {
  headers,
  body: {
    version: 2,
    field: [
      { name: '__parent_collection', value: [{ value: id }] },
    ],
  },
});

const setMetadataValue = (metadata, key) => {
  if ((!SKIP_JOIN_METADATA_FIELDS.includes(key) && !SPLIT_METADATA_FIELDS.includes(key)) || OBJECT_METADATA_FIELDS.includes(key)) {
    return [{
      value: metadata[key],
    }];
  }

  if (SPLIT_METADATA_FIELDS.includes(key)) {
    return metadata[key].split(',').map(value => ({ value: value.trim() }));
  }

  return metadata[key].map(value => ({ value }));
};

const setUpdateItemMetadataBody = (metadata) => ({
  timespan: [{
    field: Object.keys(metadata).map(key => ({
      name: key,
      value: setMetadataValue(metadata, key),
    })),
    start: '-INF',
    end: '+INF',
  }],
});

const updateItemMetadata = (id, metadata) => proxyApi.put(`item/${id}/metadata`, { body: setUpdateItemMetadataBody(metadata), headers });

const updateCollection = (id, data) => {
  const body = data;
  if (data.horizon_genre) {
    body.horizon_genre = data.horizon_genre.split(',').map(_ => _.trim());
  }

  return proxyApi.put(`collection/${id}`, { body, headers });
};

const addItemToCollection = (id, itemId, type = 'collection') => proxyApi.put(`collection/${id}/${itemId}?type=${type}`, { headers });

const removeItemFromCollection = (id, itemId, type = 'collection') => proxyApi.delete(`collection/${id}/${itemId}?type=${type}`, { headers });

const removeCollectionWithoutItems = (id) => proxyApi.delete(`collection/${id}`, { headers });

const vidigraphClient = {
  searchByCollectionId,
  getVideo,
  getSuggested,
  getRelatedWork,
  updateItemMetadata,
  updateCollection,
  searchByType,
  addItemToCollection,
  removeItemFromCollection,
  getRelatedCollections,
  removeCollectionWithoutItems,
  syncSubmission,
  getArchiveItems,
  getArchiveItem,
};

export default vidigraphClient;