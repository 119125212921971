export const actionTypes = {
  SEARCH_BY_TAGS: 'tag-SEARCH_BY_TAGS',
  SEARCH_BY_TAGS_SUCCESS: 'tag-SEARCH_BY_TAGS_SUCCESS',
  SEARCH_BY_TAGS_FAILURE: 'tag-SEARCH_BY_TAGS_FAILURE',
  GET_TABLE_PREFERENCES_SUCCESS: 'tag-GET_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'tag-RESET_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'tag-SET_TABLE_PREFERENCES',
  EXPORT_CLIPS: 'clip-EXPORT_CLIPS',
  EXPORT_CLIPS_SUCCESS: 'clip-EXPORT_CLIPS_SUCCESS',
  EXPORT_CLIPS_FAILURE: 'clip-EXPORT_CLIPS_FAILURE',
};

export const searchByTagsStarted = () => ({ type: actionTypes.SEARCH_BY_TAGS });
export const searchByTagsSuccess = payload => ({ type: actionTypes.SEARCH_BY_TAGS_SUCCESS, payload });
export const searchByTagsFailed = payload => ({ type: actionTypes.SEARCH_BY_TAGS_FAILURE, payload });
export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });
export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });
export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });
export const exportClipsStarted = () => ({ type: actionTypes.EXPORT_CLIPS });
export const exportClipsSuccess = payload => ({ type: actionTypes.EXPORT_CLIPS_SUCCESS, payload });
export const exportClipsFailed = payload => ({ type: actionTypes.EXPORT_CLIPS_FAILURE, payload });