import { actionTypes } from '../actions/relevant-asset';

export const relevantAssetDefaultState = {
  relevantAssets: null,
  possibleRelevantAssets: null,
  possibleRelevantAssetsLoading: false,
  possibleRelevantAssetsError: null,
  error: null,
  relevantAssetLoading: false,
};

const getRelevantAssets = (state) => ({ ...state, relevantAssets: null, error: null, relevantAssetLoading: true });
const getRelevantAssetsSuccess = (state, action) => ({ ...state, relevantAssets: action.payload, relevantAssetLoading: false });
const getRelevantAssetFailure = (state, action) => ({ ...state, error: action.payload, relevantAssetLoading: false });
const clearRelevantAssets = (_) => ({ ...relevantAssetDefaultState });
const updateRelevantAssetOverride = (state, action) => ({
  ...state,
  relevantAsset: state.relevantAssets.map(asset => {
    if (asset.id === action.payload.id) return { ...asset, ...action.payload };
    return asset;
  }),
});

const getPossibleRelevantAssets = (state) => ({ ...state, possibleRelevantAssets: null, possibleRelevantAssetsLoading: true, possibleRelevantAssetsError: null });
const getPossibleRelevantAssetsSuccess = (state, action) => ({ ...state, possibleRelevantAssets: action.payload, possibleRelevantAssetsLoading: false });
const getPossibleRelevantAssetsFailure = (state, action) => ({ ...state, possibleRelevantAssetsLoading: false, possibleRelevantAssetsError: action.payload });
const clearPossibleRelevantAssets = (state) => ({ ...state, possibleRelevantAssets: null, possibleRelevantAssetsLoading: false, possibleRelevantAssetsError: null });

const factory = {
  [actionTypes.GET_RELEVANT_ASSETS]: getRelevantAssets,
  [actionTypes.GET_RELEVANT_ASSETS_SUCCESS]: getRelevantAssetsSuccess,
  [actionTypes.GET_RELEVANT_ASSETS_FAILURE]: getRelevantAssetFailure,
  [actionTypes.CLEAR_RELEVANT_ASSETS]: clearRelevantAssets,
  [actionTypes.UPDATE_RELEVANT_ASSET_OVERRIDE]: updateRelevantAssetOverride,
  [actionTypes.GET_POSSIBLE_RELEVANT_ASSETS]: getPossibleRelevantAssets,
  [actionTypes.GET_POSSIBLE_RELEVANT_ASSETS_SUCCESS]: getPossibleRelevantAssetsSuccess,
  [actionTypes.GET_POSSIBLE_RELEVANT_ASSETS_FAILURE]: getPossibleRelevantAssetsFailure,
  [actionTypes.CLEAR_POSSIBLE_RELEVANT_ASSETS]: clearPossibleRelevantAssets,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = relevantAssetDefaultState, action) => {
  const fn = factory[action.type];
  if (fn) return fn(state, action);

  return state;
};

export default reducer;
