export const actionTypes = {
  GET_ASSET_START: 'GET_ASSET_START',
  GET_ASSET_SUCCESS: 'GET_ASSET_SUCCESS',
  GET_ASSET_FAILURE: 'GET_ASSET_FAILURE',

  UPDATE_ASSET_START: 'UPDATE_ASSET_START',
  UPDATE_ASSET_SUCCESS: 'UPDATE_ASSET_SUCCESS',
  UPDATE_ASSET_FAILURE: 'UPDATE_ASSET_FAILURE',
};

export const getAssetStart = () => ({
  type: actionTypes.GET_ASSET_START,
});

export const getAssetSuccess = (payload) => ({
  type: actionTypes.GET_ASSET_SUCCESS,
  payload,
});

export const getAssetFailure = (error) => ({
  type: actionTypes.GET_ASSET_FAILURE,
  payload: error,
});

export const updateAssetStart = () => ({
  type: actionTypes.UPDATE_ASSET_START,
});

export const updateAssetSuccess = (payload) => ({
  type: actionTypes.UPDATE_ASSET_SUCCESS,
  payload,
});

export const updateAssetFailure = (error) => ({
  type: actionTypes.UPDATE_ASSET_FAILURE,
  payload: error,
});
