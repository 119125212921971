export const actionTypes = {
  FILE_ADDED: 'FILE_ADDED',
  UPLOAD_START: 'UPLOAD_START',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  UPPY_INIT: 'UPPY_INIT',
  UPPY_SET_UPLOAD_COMPLETE: 'UPPY_SET_UPLOAD_COMPLETE',
  UPPY_ADD_FILES: 'UPPY_ADD_FILES',
  UPPY_FILE_ADDED: 'UPPY_FILE_ADDED',
  UPPY_REMOVE_FILE: 'UPPY_REMOVE_FILE',
  UPPY_FILE_REMOVED: 'UPPY_FILE_REMOVED',
  UPPY_START_UPLOAD: 'UPPY_START_UPLOAD',
  UPPY_UPLOAD_PROGRESS: 'UPPY_UPLOAD_PROGRESS',
  UPPY_UPLOAD_STARTED: 'UPPY_UPLOAD_STARTED',
  UPPY_PAUSE_RESUME_FILE: 'UPPY_PAUSE_RESUME_FILE',
  UPPY_FILE_PAUSED_OR_RESUMED: 'UPPY_FILE_PAUSED_OR_RESUMED',
  UPPY_UPLOAD_COMPLETED: 'UPPY_UPLOAD_COMPLETED',
};

export const fileAdded = (file, source, multiple) => ({ type: actionTypes.FILE_ADDED, payload: { source, file, multiple } });

export const uploadStart = (file) => ({ type: actionTypes.UPLOAD_START, payload: { file } });

export const uploadProgress = (source, fileId, progress) => ({ type: actionTypes.UPLOAD_PROGRESS, payload: { source, fileId, progress } });

export const uploadSuccess = (source, fileId) => ({ type: actionTypes.UPLOAD_SUCCESS, payload: { source, fileId } });

export const uploadComplete = (result) => ({ type: actionTypes.UPLOAD_COMPLETE, payload: result });

export const uploadError = (source, fileId, error) => ({ type: actionTypes.UPLOAD_ERROR, payload: { source, fileId, error } });

export const initUppy = ({ bucket, folder, source, autoProceed, onUploadComplete = {}, onUploadProgress = {} }) => ({ type: actionTypes.UPPY_INIT, payload: { bucket, folder, source, autoProceed, onUploadComplete, onUploadProgress } });

export const setUploadComplete = ({ source, callOnUploadComplete }) => ({ type: actionTypes.UPPY_SET_UPLOAD_COMPLETE, payload: { source, callOnUploadComplete } });

export const addFiles = ({ files, source, folder }) => ({ type: actionTypes.UPPY_ADD_FILES, payload: { files, source, folder } });

export const uppyFileAdded = ({ file, source }) => ({ type: actionTypes.UPPY_FILE_ADDED, payload: { file, source } });

export const removeFile = ({ fileId, source }) => ({ type: actionTypes.UPPY_REMOVE_FILE, payload: { fileId, source } });

export const uppyFileRemoved = ({ file, source }) => ({ type: actionTypes.UPPY_FILE_REMOVED, payload: { file, source } });

export const startUpload = ({ source }) => ({ type: actionTypes.UPPY_START_UPLOAD, payload: { source } });

export const uppyUploadProgress = ({ fileId, progress, source }) => ({ type: actionTypes.UPPY_UPLOAD_PROGRESS, payload: { fileId, source, progress } });

export const uppyUploadStarted = ({ fileId, source }) => ({ type: actionTypes.UPPY_UPLOAD_STARTED, payload: { fileId, source } });

export const pauseResumeFile = ({ fileId, source }) => ({ type: actionTypes.UPPY_PAUSE_RESUME_FILE, payload: { fileId, source } });

export const uppyUploadPausedOrResumed = ({ fileId, source, isPaused }) => ({ type: actionTypes.UPPY_FILE_PAUSED_OR_RESUMED, payload: { fileId, source, isPaused } });

export const uppyUploadCompleted = ({ source, successful, failed }) => ({ type: actionTypes.UPPY_UPLOAD_COMPLETED, payload: { source, successful, failed } });
