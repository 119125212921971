import jwtDecode from 'jwt-decode';
import ms from 'ms';
import {
  postLoginstarted,
  postLoginSuccess,
  postLoginFailed,
  logout,
  logoutMessage,
  resetPasswordStarted,
  resetPasswordSuccess,
  resetPasswordFailed,
  resetPasswordRequestStarted,
  resetPasswordRequestSuccess,
  resetPasswordRequestFailed,
  finishInstallationStarted,
  finishInstallationSuccess,
  finishInstallationFailure,
  loginInitStart,
  loginInitSuccess,
  loginInitFailure,
  loginMFAStart,
  loginMFASuccess,
  loginMFAFailure,
  validateResetPasswordTokenStarted,
  validateResetPasswordTokenSuccess,
  validateResetPasswordTokenFailed,
  signupStarted,
  signupSuccess,
  signupFailed,
  switchCompanyStart,
  switchCompanyFailure,
  switchCompanySuccess,
  getInvoicingCompaniesStart,
  getInvoicingCompaniesSuccess,
  getInvoicingCompaniesFailed,
  startRefreshToken,
  finishRefreshToken,
} from '../actions/authentication';
import { redirectTo } from '../../utils/utils';
import { clearNotificationTimeout } from '../actions/notifications';
import { notify, clearSessionCountdownCounter } from '../actions/app';
import { NOTIFICATION_VARIANTS } from '../../utils/constants';
import { selectVerifyToken, selectRemember, selectRefreshToken } from '../selectors/authentication';
import { throttleAction } from '../../utils/redux';

export const initLogin = ({ token, refreshToken }) => async (dispatch, _, { identityClient }) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const user = await dispatch(identityClient.getMe(headers));
    const tokenExpire = jwtDecode(token).exp;
    dispatch(postLoginSuccess({ token, refreshToken, user, tokenExpire }));
  } catch (error) {
    dispatch(postLoginFailed({ error }));
  }
};

export const loginUserInit = ({ email, password, remember }, next = '/') => async (dispatch, _, { identityClient, cookie }) => {
  try {
    await dispatch(loginInitStart());
    const {
      verifyToken,
      companies,
      mfaConfigureRequired,
      qr,
      disabledMFA = false,
      token,
      refreshToken,
    } = await dispatch(identityClient.loginUserInit({ email, password }));
    if (disabledMFA && token) {
      const { exp: tokenExpiresAt } = jwtDecode(token);
      const tokenExpiry = new Date(tokenExpiresAt * 1000);
      const { exp: refreshTokenExpiresAt } = jwtDecode(refreshToken);
      const refreshTokenExpiry = new Date(refreshTokenExpiresAt * 1000);

      cookie.setCookie('token', token, remember ? tokenExpiry.getDate() : null);
      cookie.setCookie('refreshToken', refreshToken, remember ? refreshTokenExpiry.getDate() : null);
      cookie.setCookie('rememberMe', remember ? 'yes' : 'no', 30);
      await dispatch(initLogin({ token, refreshToken }));
      await redirectTo(next, null, {});
      return await dispatch(loginMFASuccess());
    }
    if (qr) {
      const qrPathD = qr.match(/d="(.*)"/)[1];
      const qrViewBox = qr.match(/viewBox="([0-9]+ [0-9]+ [0-9]+ [0-9]+)"/)[1];
      dispatch(signupSuccess({ qr: { qrPathD, qrViewBox } }));
    }
    await dispatch(loginInitSuccess({ verifyToken, companies, remember, mfaConfigureRequired, disabledMFA }));
  } catch (error) {
    dispatch(loginInitFailure({ error }));
  }
};

export const loginUserMFA = ({ companyId, code }, next = '/') => async (dispatch, getState, { identityClient, cookie, Router }) => {
  const verifyToken = selectVerifyToken(getState());
  try {
    await dispatch(loginMFAStart());
    const { token, refreshToken } = await dispatch(identityClient.loginUserMFA({ verifyToken, companyId, code }));
    const remember = selectRemember(getState());
    const { exp: tokenExpiresAt } = jwtDecode(token);
    const tokenExpiry = new Date(tokenExpiresAt * 1000);
    const { exp: refreshTokenExpiresAt } = jwtDecode(refreshToken);
    const refreshTokenExpiry = new Date(refreshTokenExpiresAt * 1000);
    cookie.setCookie('token', token, remember ? tokenExpiry.getDate() : null);
    cookie.setCookie('refreshToken', refreshToken, remember ? refreshTokenExpiry.getDate() : null);
    await dispatch(initLogin({ token, refreshToken }));
    await redirectTo(next, null, {});
    await dispatch(loginMFASuccess());
  } catch (error) {
    await Router.push('/login');
    dispatch(loginMFAFailure({ error }));
  }
};

export const switchCompany = (companyId) => async (dispatch, getState, { identityClient, cookie, window }) => {
  dispatch(switchCompanyStart());
  const state = getState();
  const refreshToken = selectRefreshToken(state);
  const remember = selectRemember(state);
  try {
    const { token, refreshToken: newRefreshToken } = await dispatch(identityClient.switchCompany({ refreshToken, companyId }));
    const { exp: tokenExpiresAt } = jwtDecode(token);
    const tokenExpiry = new Date(tokenExpiresAt * 1000);
    const { exp: refreshTokenExpiresAt } = jwtDecode(newRefreshToken);
    const refreshTokenExpiry = new Date(refreshTokenExpiresAt * 1000);

    cookie.setCookie('token', token, remember ? tokenExpiry.getDate() : null);
    cookie.setCookie('refreshToken', newRefreshToken, remember ? refreshTokenExpiry.getDate() : null);
    dispatch(switchCompanySuccess());
    window.refreshPage();
  } catch (error) {
    dispatch(switchCompanyFailure());
  }
};

export const loginGsuiteUser = ({ token, refreshToken }, next = '/') => async (dispatch, _, { cookie, Router }) => {
  try {
    await dispatch(postLoginstarted());
    const { exp: tokenExpiresAt } = jwtDecode(token);
    const tokenExpiry = new Date(tokenExpiresAt * 1000);
    const { exp: refreshTokenExpiresAt } = jwtDecode(refreshToken);
    const refreshTokenExpiry = new Date(refreshTokenExpiresAt * 1000);
    cookie.setCookie('token', token, tokenExpiry.getDate());
    cookie.setCookie('refreshToken', refreshToken, refreshTokenExpiry.getDate());
    await dispatch(initLogin(({ token, refreshToken })));
    await Router.push(next);
  } catch (error) {
    dispatch(postLoginFailed({ error }));
  }
};

export const logoutUser = (message) => async (dispatch, getState, { cookie, window, identityClient }) => {
  try {
    dispatch(clearSessionCountdownCounter());
    try {
      await dispatch(identityClient.logout());
    } catch (error) {
      console.error('Logout failed');
    }

    dispatch(logout());
    if (message) dispatch(logoutMessage(message));
    const next = window.currentPath();
    await redirectTo('/login', next, {});

    const cookiesToRemove = ['token', 'refreshToken', 'clientorders', 'workorders', 'tasks', 'my-tasks', 'users', 'companies'];
    cookiesToRemove.forEach(cookie.removeCookie);

    dispatch(clearNotificationTimeout());
  } catch (error) {
    console.log(error);
  }
};

export const refreshTokenIfNeededThunk = () => async (dispatch, getState, { identityClient, cookie }) => {
  try {
    dispatch(startRefreshToken());

    const refreshToken = cookie.getCookie('refreshToken');
    const token = cookie.getCookie('token');
    const { exp: tokenExpiresAt } = jwtDecode(token);
    const tokenExpiresIn = (tokenExpiresAt * 1000) - new Date();
    const isTokenAboutToExpire = tokenExpiresIn < (ms('9h') + ms('59m'));
    if (!isTokenAboutToExpire) return;
    console.log(`The token expires in ${Math.floor(tokenExpiresIn / 1000)} seconds, refreshing..`);

    const { token: newToken, refreshToken: newRefreshToken } = await dispatch(identityClient.refreshTokenRequestAPI(refreshToken));
    const { exp: refreshTokenExpiresAt } = jwtDecode(newRefreshToken);
    const rememberMe = selectRemember(getState());
    const refreshTokenExpiry = new Date(refreshTokenExpiresAt * 1000);
    cookie.setCookie('refreshToken', newRefreshToken, rememberMe ? refreshTokenExpiry.getDate() : null);

    const { exp: newTokenExpiresAt } = jwtDecode(newToken);
    const tokenExpiry = new Date(newTokenExpiresAt * 1000);
    cookie.setCookie('token', newToken, rememberMe ? tokenExpiry.getDate() : null);

    await dispatch(initLogin({ token: newToken, refreshToken: newRefreshToken }));
    return newToken;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(finishRefreshToken());
  }
};

export const refreshTokenIfNeeded = throttleAction(refreshTokenIfNeededThunk, ms('30s'));

export const startGoogleLogin = () => async (dispatch, _, { publicRuntimeConfig }) => {
  // Router.push(`${identityUrl}/auth/google`);
  const { apiUrl } = publicRuntimeConfig;
  window.location.href = `${apiUrl}/identity/auth/google?service=${window.location.href}`;
};

export const resetPassword = (token, password, next = '/') => async (dispatch, _, { identityClient, Router }) => {
  try {
    dispatch(resetPasswordStarted());
    await dispatch(identityClient.resetPassword(token, password));
    await Router.push(next);
    dispatch(resetPasswordSuccess());
  } catch (error) {
    dispatch(resetPasswordFailed({ error }));
  }
};

export const finishInstallation = (token, code, next = '/signup/success') => async (dispatch, _, { identityClient, Router }) => {
  try {
    dispatch(finishInstallationStarted());
    await dispatch(identityClient.finishInstallation(token, code));
    await Router.push(next);
    dispatch(finishInstallationSuccess());
  } catch (error) {
    dispatch(finishInstallationFailure({ error }));
  }
};

export const resetPasswordRequest = (email, next = '/password-reset/request-success', withNotification = false, withAuth = false) => async (dispatch, _, { identityClient, Router }) => {
  try {
    dispatch(resetPasswordRequestStarted());
    await dispatch(withAuth ? identityClient.resetPasswordRequestWithAuth(email) : identityClient.resetPasswordRequest(email));
    if (next) await Router.push(next);
    dispatch(resetPasswordRequestSuccess());
    if (withNotification) dispatch(notify('Password reset email sent', NOTIFICATION_VARIANTS.SUCCESS));
  } catch (error) {
    dispatch(resetPasswordRequestFailed({ error }));
    if (withNotification) dispatch(notify(error.message || 'Failed to send password reset email', NOTIFICATION_VARIANTS.ERROR));
  }
};

export const validateResetPasswordToken = token => async (dispatch, _, { identityClient }) => {
  try {
    dispatch(validateResetPasswordTokenStarted());
    const user = await dispatch(identityClient.validateResetPasswordToken(token));
    dispatch(validateResetPasswordTokenSuccess({ user }));
  } catch (error) {
    dispatch(validateResetPasswordTokenFailed({ error }));
  }
};

export const signup = (token, user, next = '/signup/setup-mfa') => async (dispatch, _, { identityClient, Router }) => {
  try {
    dispatch(signupStarted());
    const { qr, disabledMFA } = await dispatch(identityClient.signup(token, user));
    if (disabledMFA) {
      await Router.push('/signup/success');
      return dispatch(finishInstallationSuccess());
    }
    const qrPathD = qr.match(/d="(.*)"/)[1];
    const qrViewBox = qr.match(/viewBox="([0-9]+ [0-9]+ [0-9]+ [0-9]+)"/)[1];
    await Router.push(`${next}?token=${token}`);
    dispatch(signupSuccess({ qr: { qrPathD, qrViewBox } }));
  } catch (error) {
    dispatch(signupFailed({ error }));
  }
};

export const getInvoicingCompanies = () => async (dispatch, getState, { financeClient }) => {
  dispatch(getInvoicingCompaniesStart());
  try {
    const items = await dispatch(financeClient.getInvoicingCompanies());
    dispatch(getInvoicingCompaniesSuccess(items));
  } catch (err) {
    console.error(err);
    dispatch(getInvoicingCompaniesFailed(err));
  }
};
