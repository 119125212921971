export const actionTypes = {
  SAVE_POLLER_TIMEOUT: 'SAVE_POLLER_TIMEOUT',
  CLEAR_POLLER_TIMEOUT: 'CLEAR_POLLER_TIMEOUT',
};

export const savePollerTimeout = (id, label = 'default') => ({
  type: actionTypes.SAVE_POLLER_TIMEOUT,
  payload: { id, label },
});

export const clearPollerTimeout = (label = 'default') => ({
  type: actionTypes.CLEAR_POLLER_TIMEOUT,
  payload: { label },
});
