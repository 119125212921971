export const hasPermission =
  ({ userIsSuperAdmin, userPermissions }) => permission => userIsSuperAdmin || (userPermissions || []).includes(permission);

export const hasPermissions = ({ userIsSuperAdmin, userPermissions }) => (permissions = []) => {
  const has = hasPermission({ userIsSuperAdmin, userPermissions });
  try {
    return userIsSuperAdmin || permissions.some(permission => has(permission));
  } catch (err) {
    return false;
  }
};

export const orderPermissions = permissions => [...permissions].sort((a, b) => (a.group < b.group ? -1 : 1));

export const getPermissionsByGroup = (permissions = []) => orderPermissions(permissions).reduce((acc, permission) => {
  const index = acc.findIndex(item => item.group === permission.group);
  if (index < 0) acc.push({ group: permission.group, permissions: [permission] });
  else acc[index].permissions.push(permission);
  return acc;
}, []);

const permissions = {
  hasPermission,
  hasPermissions,
  orderPermissions,
  getPermissionsByGroup,
};

export default permissions;