import { compose, pickProperty } from '../../utils/utils';

export const selectAuthentication = state => state.authentication;

export const selectLoggedInUserToken = compose(selectAuthentication, pickProperty('token'));

export const selectIsAuthenticated = compose(selectAuthentication, pickProperty('isAuthenticated'));

export const selectInvoicingCompanies = compose(selectAuthentication, pickProperty('invoicingCompanies'));

export const selectInvoicingCompaniesLoading = compose(selectAuthentication, pickProperty('invoicingCompaniesLoading'));

export const selectIsLoading = compose(selectAuthentication, pickProperty('loading'));

export const selectError = compose(selectAuthentication, pickProperty('error'));

export const selectLoggedInUser = compose(selectAuthentication, pickProperty('user'));

export const selectLoggedInUserCompany = compose(selectLoggedInUser, pickProperty('company'));

export const selectLoggedInUserCompanyID = compose(selectLoggedInUserCompany, pickProperty('id'));

export const selectLoggedInUserCompanyName = compose(selectLoggedInUserCompany, pickProperty('companyName'));

export const selectLoggedInUserCompanyDCodes = compose(selectLoggedInUserCompany, pickProperty('dCodes'));

export const selectLoggedInUserCompanySettings = compose(selectLoggedInUserCompany, pickProperty('settings'));

export const selectLoggedInUserCompanySuppliers = compose(selectLoggedInUserCompany, pickProperty('suppliers'));

export const selectLoggedInUserCompanyDualRatingEnabled = compose(selectLoggedInUserCompany, pickProperty('isDualRatingEnabled'));

export const selectLoggedInUserContactId = compose(selectLoggedInUserCompany, pickProperty('xeroContactId'));

export const selectLoggedInUserSettings = compose(selectLoggedInUser, pickProperty('settings'));

export const selectLoggedInUserID = compose(selectLoggedInUser, pickProperty('id'));

export const selectPermissions = compose(selectLoggedInUser, pickProperty('permissions'));

export const selectResetPassword = compose(selectAuthentication, pickProperty('resetPassword'));

export const isSuperAdmin = compose(selectLoggedInUser, pickProperty('isAdmin')) || false;

export const selectUserisInternal = compose(selectLoggedInUser, pickProperty('isInternal')) || false;

export const selectUserGSuiteUserID = compose(selectLoggedInUser, pickProperty('gsuiteUserId')) || null;

export const selectQR = compose(selectAuthentication, pickProperty('qr'));

export const selectVerifyToken = compose(selectAuthentication, pickProperty('verifyToken'));

export const selectRemember = compose(selectAuthentication, pickProperty('remember'));

export const selectMfaConfigureRequired = compose(selectAuthentication, pickProperty('mfaConfigureRequired'));

export const selectRefreshToken = compose(selectAuthentication, pickProperty('refreshToken'));

export const selectTokenExpireDate = compose(selectAuthentication, pickProperty('tokenExpire'));

export const selectIsRequestTokenLoading = compose(selectAuthentication, pickProperty('isRequestTokenLoading'));
