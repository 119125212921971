import { actionTypes } from '../actions/submissions';
import { transformSubmission, transformPricingResult } from '../utils';

const tableColums = [
  { columnName: 'id', width: 100 },
  { columnName: 'meta.title', width: 200 },
  { columnName: 'user.fullName', width: 120 },
  { columnName: 'company.companyName', width: 180 },
  { columnName: 'meta.productPath', width: 180 },
  { columnName: 'meta.director', width: 180 },
  { columnName: 'meta.country', width: 200 },
  { columnName: 'meta.year', width: 150 },
  { columnName: 'meta.eidr', width: 180 },
  { columnName: 'meta.genre', width: 180 },
  { columnName: 'meta.publicationDate', width: 180 },
  { columnName: 'meta.releaseDate', width: 180 },
  { columnName: 'meta.requiredClassificationDueDate', width: 180 },
  { columnName: 'meta.language', width: 180 },
  { columnName: 'meta.additionalLanguages', width: 210 },
  { columnName: 'meta.requiredRating', width: 180 },
  { columnName: 'meta.versionNumber', width: 100 },
  { columnName: 'meta.versionName', width: 100 },
  { columnName: 'meta.runtimeInSeconds', width: 180 },
  { columnName: 'meta.actualRuntimeInSeconds', width: 180 },
  { columnName: 'meta.version', width: 100 },
  { columnName: 'meta.versionDescription', width: 180 },
  { columnName: 'meta.season', width: 100 },
  { columnName: 'meta.seasonTitle', width: 180 },
  { columnName: 'meta.episode', width: 100 },
  { columnName: 'meta.episodeTitle', width: 180 },
  { columnName: 'meta.subtitles', width: 100 },
  { columnName: 'meta.additionalTitle', width: 100 },
  { columnName: 'schedule.timeSlot.price', width: 180 },
  { columnName: 'tags', width: 180 },
  { columnName: 'finance.invoicing.paymentSettled', width: 150 },
  { columnName: 'finance.invoicing.invoiceNumber', width: 150 },
  { columnName: 'dateOfInvoice', width: 150 },
  { columnName: 'createdAt', width: 150 },
  { columnName: 'updatedAt', width: 150 },
  { columnName: 'review.report.shortInfo', width: 300 },
  { columnName: 'review.report.category', width: 100 },
  { columnName: 'review.registrationNumbers', width: 100 },
  { columnName: 'options', width: 100 },
];

export const submissionsDefaultState = {
  list: [], // projections of submission entries from the db
  count: 0,
  isLoaded: false,
  isBusy: true,
  apiError: false,
  error: {
    status: 0,
    message: '',
  },
  tablePreferences: {
    hiddenColumns: [
      'id',
      'meta.country',
      'meta.eidr',
      'meta.genre',
      'meta.publicationDate',
      'meta.releaseDate',
      'meta.requiredClassificationDueDate',
      'meta.language',
      'meta.additionalLanguages',
      'meta.requiredRating',
      'meta.versionNumber',
      'meta.versionName',
      'meta.versionDescription',
      'meta.runtimeInSeconds',
      'meta.actualRuntimeInSeconds',
      'meta.season',
      'meta.seasonTitle',
      'meta.episode',
      'meta.episodeTitle',
      'meta.subtitles',
      'meta.additionalTitle',
      'paymentSettled',
      'finance.invoicing.invoiceNumber',
      'dateOfInvoice',
      'review.report.shortInfo',
      'review.report.category',
      'updatedAt',
      'review.registrationNumbers',
    ],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
    rowsPerPage: 10,
  },
  showDeletedItems: false,
  pricing: {
    isLoading: false,
    result: null,
    error: null,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = submissionsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_START: {
      return {
        ...state,
        isLoaded: false,
        isBusy: true,
        error: {
          status: 0,
          message: '',
        },
      };
    }
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: submissionsDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    case actionTypes.GET_LIST_SUCCESS: {
      const { count, result } = action.payload;

      const list = result.map(transformSubmission);

      return {
        ...state,
        list,
        count,
        isLoaded: true,
        isBusy: false,
        apiError: false,
        error: {
          status: 0,
          message: '',
        },
      };
    }
    case actionTypes.GET_LIST_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        list: [],
        isBusy: false,
        apiError: true,
        error,
      };
    }
    case actionTypes.INVALIDATE_LIST: {
      return {
        ...state,
        isLoaded: false,
      };
    }
    case actionTypes.TOGGLE_SHOW_DELETED_ITEMS: {
      return {
        ...state,
        showDeletedItems: !state.showDeletedItems,
      };
    }
    case actionTypes.CALCULATE_PRICES_V2_START: {
      return {
        ...state,
        pricing: {
          isLoading: true,
          result: null,
          error: null,
        },
      };
    }
    case actionTypes.CALCULATE_PRICES_V2_SUCCESS: {
      return {
        ...state,
        pricing: {
          isLoading: false,
          result: transformPricingResult(action.payload),
          error: null,
        },
      };
    }
    case actionTypes.CALCULATE_PRICES_V2_FAILURE: {
      return {
        ...state,
        pricing: {
          isLoading: false,
          result: null,
          error: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
