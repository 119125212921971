export const actionTypes = {
  GET_LIST_START: 'cal-GET_LIST_START',
  GET_LIST_SUCCESS: 'cal-GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'cal-GET_LIST_FAILURE',
  ADD_ASSET_CALENDAR_START: 'res-ADD_ASSET_CALENDAR_START',
  ADD_ASSET_CALENDAR_SUCCESS: 'res-ADD_ASSET_CALENDAR_SUCCESS',
  ADD_ASSET_CALENDAR_FAILURE: 'res-ADD_ASSET_CALENDAR_FAILURE',
  UPDATE_ASSET_CALENDAR_START: 'res-UPDATE_ASSET_CALENDAR_START',
  UPDATE_ASSET_CALENDAR_SUCCESS: 'res-UPDATE_ASSET_CALENDAR_SUCCESS',
  UPDATE_ASSET_CALENDAR_FAILURE: 'res-UPDATE_ASSET_CALENDAR_FAILURE',
  DELETE_ASSET_CALENDAR_START: 'res-DELETE_ASSET_CALENDAR_START',
  DELETE_ASSET_CALENDAR_SUCCESS: 'res-DELETE_ASSET_CALENDAR_SUCCESS',
  DELETE_ASSET_CALENDAR_FAILURE: 'res-DELETE_ASSET_CALENDAR_FAILURE',
  ASSIGN_RESOURCE_CALENDAR_START: 'res-ASSIGN_RESOURCE_CALENDAR_START',
  ASSIGN_RESOURCE_CALENDAR_SUCCESS: 'res-ASSIGN_RESOURCE_CALENDAR_SUCCESS',
  ASSIGN_RESOURCE_CALENDAR_FAILURE: 'res-ASSIGN_RESOURCE_CALENDAR_FAILURE',
  GET_TABLE_PREFERENCES_SUCCESS: 'res-GET_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'res-RESET_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'res-SET_TABLE_PREFERENCES',
};

export const getListStart = () => ({
  type: actionTypes.GET_LIST_START,
});

export const getListSuccess = calendars => ({
  type: actionTypes.GET_LIST_SUCCESS,
  payload: calendars,
});

export const getListFailure = () => ({
  type: actionTypes.GET_LIST_FAILURE,
});

export const addAssetCalendarStart = () => ({
  type: actionTypes.ADD_ASSET_CALENDAR_START,
});

export const addAssetCalendarSuccess = data => ({
  type: actionTypes.ADD_ASSET_CALENDAR_SUCCESS,
  payload: { data },
});

export const addAssetCalendarFailure = () => ({
  type: actionTypes.ADD_ASSET_CALENDAR_FAILURE,
});

export const updateAssetCalendarStart = (id, data) => ({
  type: actionTypes.UPDATE_ASSET_CALENDAR_START,
  payload: { id, data },
});

export const updateAssetCalendarSuccess = () => ({
  type: actionTypes.UPDATE_ASSET_CALENDAR_SUCCESS,
});

export const updateAssetCalendarFailure = () => ({
  type: actionTypes.UPDATE_ASSET_CALENDAR_FAILURE,
});

export const deleteAssetCalendarStart = (id) => ({
  type: actionTypes.DELETE_ASSET_CALENDAR_START,
  payload: { id },
});

export const deleteAssetCalendarSuccess = () => ({
  type: actionTypes.DELETE_ASSET_CALENDAR_SUCCESS,
});

export const deleteAssetCalendarFailure = () => ({
  type: actionTypes.DELETE_ASSET_CALENDAR_FAILURE,
});

export const assignResourceCalendarStart = (data) => ({
  type: actionTypes.ASSIGN_RESOURCE_CALENDAR_START,
  payload: data,
});

export const assignResourceCalendarSuccess = () => ({
  type: actionTypes.ASSIGN_RESOURCE_CALENDAR_SUCCESS,
});

export const assignResourceCalendarFailure = () => ({
  type: actionTypes.ASSIGN_RESOURCE_CALENDAR_FAILURE,
});

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });

export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });

export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });

const calendars = {
  getListStart,
  getListSuccess,
  getListFailure,
  addAssetCalendarStart,
  addAssetCalendarSuccess,
  addAssetCalendarFailure,
  updateAssetCalendarStart,
  updateAssetCalendarSuccess,
  updateAssetCalendarFailure,
  deleteAssetCalendarStart,
  deleteAssetCalendarSuccess,
  deleteAssetCalendarFailure,
  assignResourceCalendarStart,
  assignResourceCalendarSuccess,
  assignResourceCalendarFailure,
  getTablePreferencesSuccess,
  resetTablePreferences,
  setTablePreferences,
};

export default calendars;
