import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const schedulerProxyUrl = `${apiUrl}/scheduler`;

console.log('SCHEDULER PROXY URI:', schedulerProxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(schedulerProxyUrl, 'GET'),
  post: invoke(schedulerProxyUrl, 'POST'),
  put: invoke(schedulerProxyUrl, 'PUT'),
  patch: invoke(schedulerProxyUrl, 'PATCH'),
  delete: invoke(schedulerProxyUrl, 'DELETE'),
};

const getHumanCalendars = () => api.get('/calendars');

const getAssetCalendars = data => api.post('/resourceList', { body: data });

const getGoogleResourceCalendars = () => api.get('/getResourceCalendars');

const assignResourceCalendar = data => api.post('/resource', { body: data });

const addAssetCalendar = data => api.put('/resource', { body: data });

const updateAssetCalendar = (id, data) => api.patch(`/resource/${id}`, { body: data });

const deleteAssetCalendar = id => api.delete(`/resource/${id}`);

const getGenres = () => api.post('/genres/getGenres');

const addGenre = (data) => api.post('/genres/createGenre', { body: data });

const getGsuiteUsers = () => api.get('/officers/getGsuiteUsers');

const getComplianceOfficers = () => api.post('/officers/getOfficers', { body: { show_deleted: true } });

const addComplianceOfficer = (data) => api.post('/officers/createOfficer', { body: data });

const updateComplianceOfficer = (id, data) => api.post(`/officers/updateOfficer/${id}`, { body: data });

const deleteComplianceOfficer = (id) => api.delete(`/officers/deleteOfficer/${id}`);

const setComplianceOfficerGenre = ({ complianceOfficerId, genreId }) => api.post('/officers/setOfficerGenre', { body: { officerId: complianceOfficerId, genreId } });

const deleteComplianceOfficerGenre = ({ complianceOfficerId, genreId }) => api.post('/officers/deleteOfficerGenre', { body: { officerId: complianceOfficerId, genreId } });

export const getUpdatableMetaFields = (workOrderId) => api.post('/validate/getUpdateable', { body: { workOrderId } });

export const getProductParams = productPath => api.get('/productParams', { query: { path: productPath } });

export const createFolderInDrive = fodlerName => api.put(`/drive/folder/${fodlerName}`);

export const getClosedDays = () => api.get('/offers/closed');

export const getOffers = payload => api.post('/offers3', { body: payload });

export const getBatchOffers = payload => api.post('/offers4/batch', { body: payload });

export const getMinClassificationDate = ({ productPath }) => api.post('/offers/getMinClassificationDate', { body: { productPath } });

export const checkMetaModification = (updateMeta, workOrderId) => api.post('/validate/meta', { body: { updateMeta, workOrderId } });

export const reserveOffer = payload => api.post('/reservation/reserve', { body: payload });

const schedulerClient = {
  getHumanCalendars,
  getGoogleResourceCalendars,
  assignResourceCalendar,
  getAssetCalendars,
  addAssetCalendar,
  updateAssetCalendar,
  deleteAssetCalendar,
  getGenres,
  addGenre,
  getGsuiteUsers,
  getComplianceOfficers,
  addComplianceOfficer,
  updateComplianceOfficer,
  deleteComplianceOfficer,
  setComplianceOfficerGenre,
  deleteComplianceOfficerGenre,
  getUpdatableMetaFields,
  getProductParams,
  createFolderInDrive,
  getClosedDays,
  getMinClassificationDate,
  checkMetaModification,
  getOffers,
  getBatchOffers,
  reserveOffer,
};

export default schedulerClient;
