import { INTEGER_REGEX_MAX_9999 } from './constants';

export const hasValue = (input) => input !== undefined && input !== null && input !== '';

export const safeArray = (input) => {
  if (!hasValue(input)) return [];
  if (Array.isArray(input)) return input.filter(hasValue);
  return [input];
};

export const splitToArray = (input) => {
  const array = safeArray(input);
  return array.join(',')
    .split(',')
    .map((e) => e.trim())
    .filter(hasValue);
};

export const arrayToString = (input) => {
  if (!input) return '';
  const array = splitToArray(input);
  return array.join(', ');
};

export const stringToArray = (input) => {
  if (!input) return [];
  if (Array.isArray(input)) return input;
  return input.split(',')
    .map((item) => item.trim())
    .filter(hasValue);
};

export const toNumber = (input) => {
  if (!input) return null;
  const value = Number(input);
  if (Number.isNaN(value) || !INTEGER_REGEX_MAX_9999.test(value)) return 1;
  return value;
};

export const toUpperCaseArray = (input = []) => splitToArray(input).map((item) => item.toUpperCase());
