import { actionTypes } from '../actions/permissions';

export const permissionsDefaultState = {
  error: '',
  loading: false,
  rows: [],
  count: 0,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = permissionsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERMISSIONS:
      return { ...state, error: '', loading: true };
    case actionTypes.FETCH_PERMISSIONS_SUCCESS:
      return { ...state, ...action.payload, error: '', loading: false };
    case actionTypes.FETCH_PERMISSIONS_FAILURE:
      return { ...state, permissions: [], loading: false, error: action.payload.message };
    default:
      return state;
  }
};

export default reducer;
