export const actionTypes = {
  GET_TAGS: 'tag-GET_TAGS',
  GET_TAGS_SUCCESS: 'tag-GET_TAGS_SUCCESS',
  GET_TAGS_FAILURE: 'tag-GET_TAGS_FAILURE',

  TAG_OPERATION: 'tag-TAG_OPERATION',
  TAG_OPERATION_SUCCESS: 'tag-TAG_OPERATION_SUCCESS',
  TAG_OPERATION_FAILURE: 'tag-TAG_OPERATION_FAILURE',
};

export const fetchTagsStarted = () => ({ type: actionTypes.GET_TAGS });
export const fetchTagsSuccess = payload => ({ type: actionTypes.GET_TAGS_SUCCESS, payload });
export const fetchTagsFailed = payload => ({ type: actionTypes.GET_TAGS_FAILURE, payload });

export const tagOperationStarted = () => ({ type: actionTypes.TAG_OPERATION });
export const tagOperationSuccess = () => ({ type: actionTypes.TAG_OPERATION_SUCCESS });
export const tagOperationFailed = () => ({ type: actionTypes.TAG_OPERATION_FAILURE });