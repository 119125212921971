import { compose, pickProperty } from '../../utils/utils';

const selectUppy = state => state.uppy;

export const selectUploads = compose(selectUppy, pickProperty('uploads'));
export const selectUploadItems = compose(selectUppy, pickProperty('uploadItems'));
export const selectUploader = compose(selectUppy, pickProperty('uploader'));

export const selectUploadsBySource = state => source => {
  const uploadItems = selectUploadItems(state) || {};
  return uploadItems[source];
};

export const selectAllFilesAreUploaded = state => source => {
  const uploads = selectUploadsBySource(state)(source);
  if (!uploads) return false;
  return uploads.every(file => file.progress.uploadComplete);
};

export const hasInProgressUpload = state => {
  const uploadItems = selectUploadItems(state) || {};
  return Object.keys(uploadItems).some(k => uploadItems[k].some(i => i.isUploading));
};

export const selectUploadBySource = state => source => {
  const uploads = selectUploadsBySource(state)(source);
  return Array.isArray(uploads) ? uploads[0] : {};
};

export const selectTaskUploads = state => {
  const uploads = selectUploadItems(state);
  const taskUploads = Object.keys(uploads).filter(k => k.startsWith('TASK'));
  if (!taskUploads.length) return null;
  return taskUploads.reduce((acc, curr) => ({ ...acc, [curr]: uploads[curr] }), {});
};

export const selectUploaderBySource = state => source => {
  const uploaders = selectUploader(state) || {};
  const uploader = uploaders[source] || {};
  return uploader;
};

export const selectUploaderFilesBySource = state => source => {
  const uploader = selectUploaderBySource(state)(source);
  const files = uploader.files || [];
  return files;
};