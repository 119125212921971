import get from 'lodash/get';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import getConfig from 'next/config';
import { WORK_ORDER_TAG } from '../../utils/constants';

const { publicRuntimeConfig: { env } } = getConfig();

export const transformSubmission = incompatibleSubmission => {
  let submission = incompatibleSubmission;

  if (submission.review && !submission.review.review) {
    submission = {
      ...incompatibleSubmission,
      review: {
        review: { reports: [] }, // reports sent for complianceReview task
        confirmation: get(incompatibleSubmission, 'review.confirmed') !== undefined ? {} : undefined, // data about the confirmation process (possible properties: phase, completed)
        report: cloneDeep(incompatibleSubmission.review), // result of complianceReviewConfirmation
        acceptance: { // data about the user acceptance process (possible properties: phase, started, completed)
          started: get(submission, 'tags', []).includes(WORK_ORDER_TAG.COMPLIANCE_REVIEW_CONFIRMED),
        },
        dateOfClassification: get(incompatibleSubmission, 'schedule.timeSlot.end'),
        ...pick(incompatibleSubmission.review, ['resultRevealed', 'registrationNumbers']),
      },
    };
  }

  if (env === 'production' && submission.id <= 93) {
    submission = {
      ...submission,
      tags: [submission.status],
    };
  }

  if (!submission.meta.workflowVersion) {
    submission.meta.workflowVersion = 'v1';
  }

  return submission;
};

export const transformClientOrder = co => ({
  ...co,
  workOrders: Array.isArray(co.workOrders) ? co.workOrders.map(transformSubmission) : co.workOrders,
});