import {
  LOGOUT,
  LOGOUT_MESSAGE,
  LOGIN_INIT_START,
  LOGIN_INIT_SUCCESS,
  LOGIN_INIT_FAILURE,
  LOGIN_MFA_START,
  LOGIN_MFA_SUCCESS,
  LOGIN_MFA_FAILURE,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  FINISH_INSTALLATION_STARTED,
  FINISH_INSTALLATION_SUCCESS,
  FINISH_INSTALLATION_FAILURE,
  VALIDATE_RESET_PASSWORD_TOKEN,
  VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  START_REFRESH_TOKEN_REQUEST,
  FINISH_REFRESH_TOKEN_REQUEST,
  SWITCH_COMPANY,
  SWITCH_COMPANY_SUCCESS,
  SWITCH_COMPANY_FAILURE,
  GET_INVOICING_COMPANIES,
  GET_INVOICING_COMPANIES_SUCCESS,
  GET_INVOICING_COMPANIES_FAILURE,
  START_REFRESH_TOKEN,
  FINISH_REFRESH_TOKEN,
} from '../types';

export const loginInitStart = () => ({ type: LOGIN_INIT_START });
export const loginInitSuccess = payload => ({ type: LOGIN_INIT_SUCCESS, payload });
export const loginInitFailure = payload => ({ type: LOGIN_INIT_FAILURE, payload });

export const loginMFAStart = () => ({ type: LOGIN_MFA_START });
export const loginMFASuccess = payload => ({ type: LOGIN_MFA_SUCCESS, payload });
export const loginMFAFailure = payload => ({ type: LOGIN_MFA_FAILURE, payload });

export const switchCompanyStart = () => ({ type: SWITCH_COMPANY });
export const switchCompanySuccess = () => ({ type: SWITCH_COMPANY_SUCCESS });
export const switchCompanyFailure = () => ({ type: SWITCH_COMPANY_FAILURE });

export const logout = message => ({ type: LOGOUT, payload: message });
export const logoutMessage = message => ({ type: LOGOUT_MESSAGE, payload: message });
export const postLoginstarted = payload => ({ type: LOGIN, payload });
export const postLoginSuccess = payload => ({ type: LOGIN_SUCCESS, payload });
export const postLoginFailed = payload => ({ type: LOGIN_FAILURE, payload });

export const resetPasswordStarted = () => ({ type: RESET_PASSWORD });
export const resetPasswordSuccess = payload => ({ type: RESET_PASSWORD_SUCCESS, payload });
export const resetPasswordFailed = payload => ({ type: RESET_PASSWORD_FAILURE, payload });

export const resetPasswordRequestStarted = () => ({ type: RESET_PASSWORD_REQUEST });
export const resetPasswordRequestSuccess = () => ({ type: RESET_PASSWORD_REQUEST_SUCCESS });
export const resetPasswordRequestFailed = payload => ({ type: RESET_PASSWORD_REQUEST_FAILURE, payload });

export const finishInstallationStarted = () => ({ type: FINISH_INSTALLATION_STARTED });
export const finishInstallationSuccess = () => ({ type: FINISH_INSTALLATION_SUCCESS });
export const finishInstallationFailure = payload => ({ type: FINISH_INSTALLATION_FAILURE, payload });

export const validateResetPasswordTokenStarted = () => ({ type: VALIDATE_RESET_PASSWORD_TOKEN });
export const validateResetPasswordTokenSuccess = payload => ({ type: VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS, payload });
export const validateResetPasswordTokenFailed = payload => ({ type: VALIDATE_RESET_PASSWORD_TOKEN_FAILURE, payload });

export const signupStarted = () => ({ type: SIGNUP });
export const signupSuccess = payload => ({ type: SIGNUP_SUCCESS, payload });
export const signupFailed = payload => ({ type: SIGNUP_FAILURE, payload });

export const startRefreshTokenRequest = () => ({ type: START_REFRESH_TOKEN_REQUEST });
export const finishRefreshTokenRequest = () => ({ type: FINISH_REFRESH_TOKEN_REQUEST });

export const startRefreshToken = () => ({ type: START_REFRESH_TOKEN });
export const finishRefreshToken = () => ({ type: FINISH_REFRESH_TOKEN });

export const getInvoicingCompaniesStart = () => ({ type: GET_INVOICING_COMPANIES });
export const getInvoicingCompaniesSuccess = payload => ({ type: GET_INVOICING_COMPANIES_SUCCESS, payload });
export const getInvoicingCompaniesFailed = payload => ({ type: GET_INVOICING_COMPANIES_FAILURE, payload });
