import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  REMOVE_USER_MFA_START,
  REMOVE_USER_MFA_SUCCESS,
  REMOVE_USER_MFA_FAILED,
  REVOKE_USER_SESSION_START,
  REVOKE_USER_SESSION_SUCCESS,
  REVOKE_USER_SESSION_FAILED,
} from '../types';
import { actionTypes } from '../actions/users';

const tableColums = [
  { columnName: 'profilePicture', width: 100 },
  { columnName: 'id', width: 100 },
  { columnName: 'firstName', width: 180 },
  { columnName: 'lastName', width: 180 },
  { columnName: 'company.companyName', width: 180 },
  { columnName: 'email', width: 180 },
  { columnName: 'gsuiteUserId', width: 140 },
  { columnName: 'slackMemberID', width: 140 },
  { columnName: 'isAdmin', width: 100 },
  { columnName: 'phone', width: 160 },
  { columnName: 'jobTitle', width: 160 },
  { columnName: 'status', width: 120 },
  { columnName: 'lastLogin', width: 140 },
  { columnName: 'createdAt', width: 140 },
  { columnName: 'updatedAt', width: 140 },
];

export const usersDefaultState = {
  error: {
    message: '',
    status: null,
  },
  loading: true,
  rows: [],
  count: 0,
  detail: {},
  tablePreferences: {
    hiddenColumns: [
      'createdAt',
      'updatedAt',
      'jobTitle',
      'phone',
      'gsuiteUserId',
      'slackMemberID',
      'isAdmin',
    ],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
    rowsPerPage: 10,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = usersDefaultState, action) => {
  switch (action.type) {
    case FETCH_USERS:
    case REMOVE_USER_MFA_START:
    case REVOKE_USER_SESSION_START:
    case SAVE_USER:
      return { ...state, error: {}, loading: true };
    case FETCH_USERS_SUCCESS:
    case SAVE_USER_SUCCESS:
    case REMOVE_USER_MFA_SUCCESS:
    case REVOKE_USER_SESSION_SUCCESS:
      return { ...state, ...(action.payload || {}), error: {}, loading: false };
    case FETCH_USERS_FAILURE:
      return { ...state, rows: [], count: 0, loading: false, error: action.payload.error };
    case FETCH_USER:
      return { ...state, detail: {}, error: {}, loading: true };
    case FETCH_USER_SUCCESS:
      return { ...state, detail: action.payload.user, error: {}, loading: false };
    case FETCH_USER_FAILURE:
    case SAVE_USER_FAILURE:
    case REMOVE_USER_MFA_FAILED:
    case REVOKE_USER_SESSION_FAILED:
      return { ...state, detail: {}, loading: false, error: action.payload.error };
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: usersDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    default:
      return state;
  }
};

export default reducer;
