import { actionTypes } from '../actions/videos';
import { resolveMetadata, resolveGroupMetadata } from '../../utils/metadata';

const tableColumns = [
  { columnName: 'edit', width: 120 },
  { columnName: 'id', width: 80 },
  { columnName: 'submissionId', width: 80 },
  { columnName: 'title', width: 400 },
  { columnName: 'status', width: 80 },
  { columnName: 'AKATitle', width: 400 },
  { columnName: 'additionalTitle', width: 400 },
  { columnName: 'collection_id', width: 140 },
  { columnName: 'name', width: 180 },
  { columnName: 'episode', width: 140 },
  { columnName: 'episodeTitle', width: 400 },
  { columnName: 'season', width: 120 },
  { columnName: 'classificationDate', width: 200 },
  { columnName: 'TypeOfMedia', width: 100 },
  { columnName: 'runtime', width: 120 },
  { columnName: 'shortFormInsight', width: 300 },
  { columnName: 'Author', width: 140 },
  { columnName: 'director', width: 140 },
  { columnName: 'distributor', width: 140 },
  { columnName: 'releaseDate', width: 140 },
  { columnName: 'classification', width: 80 },
  { columnName: 'created_on', width: 150 },
  { columnName: 'productPath', width: 150 },
  { columnName: 'PaperworkRemarks', width: 200 },
  { columnName: 'allowVal', width: 80 },
];

export const videosDefaultState = {
  assets: [],
  collections: [],
  suggestions: [],
  related: [],
  detail: {},
  count: 0,
  isLoaded: false,
  isBusy: false,
  apiError: false,
  tablePreferences: {
    hiddenColumns: [
      'id',
      'submissionId',
      'title',
      'AKATitle',
      'additionalTitle',
      'TypeOfMedia',
      'runtime',
      'shortFormInsight',
      'Author',
      'director',
      'distributor',
      'releaseDate',
      'classification',
      'created_on',
      'productPath',
      'PaperworkRemarks',
      'allowVal',
    ],
    columnOrder: tableColumns.map(({ columnName }) => columnName),
    columnWidths: tableColumns,
  },
  parent: null,
  files: [],
  hasFiles: false,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = videosDefaultState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ASSETS_START:
    case actionTypes.SEARCH_COLLECTIONS_START:
    case actionTypes.GET_VIDEO_START:
    case actionTypes.GET_RELATED_START:
    case actionTypes.GET_PARENT_START:
      return { ...state, isLoaded: false, isBusy: true, apiError: false };

    case actionTypes.SEARCH_ASSETS_FAILURE:
    case actionTypes.SEARCH_COLLECTIONS_FAILURE:
    case actionTypes.GET_VIDEO_FAILURE:
    case actionTypes.GET_RELATED_FAILURE:
    case actionTypes.GET_PARENT_FAILURE:
      return { ...state, isBusy: false, isLoaded: true };

    case actionTypes.CLEAR_SUGGESTIONS:
      return { ...state, suggestions: [] };
    case actionTypes.CLEAR_PARENT:
      return { ...state, parent: null };

    case actionTypes.UPDATE_FILTER:
      return { ...state, activeFilter: action.payload.filter };

    case actionTypes.GET_SUGGESTIONS: {
      const { suggestions } = action.payload;
      return {
        ...state,
        suggestions: suggestions.suggestion || [],
      };
    }

    case actionTypes.SEARCH_COLLECTIONS_SUCCESS: {
      const { collections, append = false, count } = action.payload;

      return {
        ...state,
        collections: append ? [ ...state.collections, ...collections] : collections,
        count,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.SEARCH_ASSETS_SUCCESS: {
      const { assets, count } = action.payload;
      return {
        ...state,
        assets,
        count,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.GET_PARENT_SUCCESS: {
      const { video } = action.payload;
      return {
        ...state,
        parent: { ...video },
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.GET_VIDEO_SUCCESS: {
      const { video } = action.payload;
      const metadata = resolveMetadata(video);
      const group = resolveGroupMetadata(video);
      if (group.length > 0) {
        video.group = group;
      }

      return {
        ...state,
        detail: { ...video, ...metadata },
        isLoaded: true,
        isBusy: false,
        apiError: false,
        parent: null,
      };
    }

    case actionTypes.UPDATE_ITEM_METADATA_SUCCESS: {
      const { videoId } = action.payload;

      return {
        ...state,
        detail: { ...state.detail, id: videoId },
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.GET_RELATED_SUCCESS: {
      const { related } = action.payload;
      const items = related.item;

      items.forEach((item, i) => {
        const metadata = resolveMetadata(item);
        items[i] = { ...item, ...metadata };
      });

      return {
        ...state,
        related: items,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.GET_RELATED_LIST_SUCCESS: {
      const { results, append } = action.payload;
      const { items, collections } = { ...state };

      let order = 0;
      const tempStore = {};
      let allCollections = [];
      if (append) {
        allCollections = collections.concat(results.collection);
      } else {
        allCollections = results.collection;
      }

      items.forEach((item, i) => {
        const itemMetadata = resolveMetadata(item);
        items[i] = { ...item, ...itemMetadata };

        const id = item.__collection; /*eslint no-underscore-dangle: ["error", { "allow": ["__collection"] }]*/
        if (tempStore[id]) {
          tempStore[id].items.push(item);
        } else if (id) {
          tempStore[id] = {
            order,
            items: [item],
          };
          order++;
        }
      });

      let nextCollections = [Object.keys(tempStore).length];
      Object.keys(tempStore).forEach(colId => {
        const col = allCollections.filter(c => c.id === colId)[0];
        col.items = tempStore[colId].items;
        nextCollections[tempStore[colId].order] = col;
      });

      nextCollections = nextCollections.filter(c => c.name);
      nextCollections.forEach((collection, i) => {
        const collectionMetadata = resolveMetadata(collection);
        nextCollections[i] = { ...collection, ...collectionMetadata };
      });

      return {
        ...state,
        collections: nextCollections,
      };
    }
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: videosDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: {
          ...state.tablePreferences,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case actionTypes.GET_ARCHIVE_FILES:
      return { ...state, error: {}, loading: true };
    case actionTypes.GET_ARCHIVE_FILES_SUCCESS:
      return { ...state, files: action.payload, error: {}, loading: false };
    case actionTypes.GET_ARCHIVE_FILES_FAILURE:
      return { ...state, files: [], loading: false, error: action.payload.error.message };
    case actionTypes.GET_ARCHIVE_HAS_FILES:
      return { ...state, error: {}, loading: true };
    case actionTypes.GET_ARCHIVE_HAS_FILES_SUCCESS:
      return { ...state, hasFiles: action.payload, error: {}, loading: false };
    case actionTypes.GET_ARCHIVE_HAS_FILES_FAILURE:
      return { ...state, hasFiles: false, loading: false, error: action.payload.error.message };
    default:
      return state;
  }
};

export default reducer;
