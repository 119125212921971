import { actionTypes } from '../actions/tags';

export const tagsDefaultState = {
  error: '',
  loading: true,
  rows: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = tagsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_TAGS:
      return { ...state, error: '', loading: true };
    case actionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: false,
      };
    case actionTypes.GET_TAGS_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload.error.message };
    case actionTypes.TAG_OPERATION:
      return { ...state, loading: true };
    case actionTypes.TAG_OPERATION_SUCCESS:
    case actionTypes.TAG_OPERATION_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
