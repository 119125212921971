import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const proxyUrl = `${apiUrl}/pricing`;

console.log('PRICING API PROXY URI:', proxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(proxyUrl, 'GET'),
  post: invoke(proxyUrl, 'POST'),
  put: invoke(proxyUrl, 'PUT'),
  patch: invoke(proxyUrl, 'PATCH'),
  delete: invoke(proxyUrl, 'DELETE'),
};

/**
 * Authenticated routes
 */

export const calculatePrices = ({
  productPath,
  duration,
  numberOfLocations,
  preschool,
  videoIdentical,
  modernFilmClassification,
  charity,
}) => api.get('/product/v2', {
  query: {
    productPath,
    duration,
    numberOfLocations,
    preschool,
    videoIdentical,
    modernFilmClassification,
    charity,
  },
});
export const getProducts = () => api.get('/product/v2');