import React from 'react';
import InProgressIcon from '@mui/icons-material/CloudUpload';

const InProgressMessage = () => (
  <span className="message">
    <InProgressIcon className="icon" />
    <span>File upload in progress</span>
  </span>
);

export default InProgressMessage;