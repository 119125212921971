export const actionTypes = {
  GET_COLLECTION_START: 'GET_COLLECTION_START',
  GET_COLLECTION_SUCCESS: 'GET_COLLECTION_SUCCESS',
  GET_COLLECTION_FAILURE: 'GET_COLLECTION_FAILURE',

  UPDATE_COLLECTION_START: 'UPDATE_COLLECTION_START',
  UPDATE_COLLECTION_SUCCESS: 'UPDATE_COLLECTION_SUCCESS',
  UPDATE_COLLECTION_FAILURE: 'UPDATE_COLLECTION_FAILURE',

  GET_SEASON_HEADERS_START: 'GET_SEASON_HEADERS_START',
  GET_SEASON_HEADERS_SUCCESS: 'GET_SEASON_HEADERS_SUCCESS',
  GET_SEASON_HEADERS_FAILURE: 'GET_SEASON_HEADERS_FAILURE',

  GET_ASSETS_HEADERS_START: 'GET_ASSETS_HEADERS_START',
  GET_ASSETS_HEADERS_SUCCESS: 'GET_ASSETS_HEADERS_SUCCESS',
  GET_ASSETS_HEADERS_FAILURE: 'GET_ASSETS_HEADERS_FAILURE',

  GET_SEASON_ASSETS_START: 'GET_SEASON_ASSETS_START',
  GET_SEASON_ASSETS_SUCCESS: 'GET_SEASON_ASSETS_SUCCESS',
  GET_SEASON_ASSETS_FAILURE: 'GET_SEASON_ASSETS_FAILURE',

  GET_ASSETS_START: 'GET_ASSETS_START',
  GET_ASSETS_SUCCESS: 'GET_ASSETS_SUCCESS',
  GET_ASSETS_FAILURE: 'GET_ASSETS_FAILURE',

  CLEAR: 'CLEAR',
};

export const getCollectionStart = () => ({
  type: actionTypes.GET_COLLECTION_START,
});

export const getCollectionSuccess = (payload) => ({
  type: actionTypes.GET_COLLECTION_SUCCESS,
  payload,
});

export const getCollectionFailure = (error) => ({
  type: actionTypes.GET_COLLECTION_FAILURE,
  payload: error,
});

export const updateCollectionStart = () => ({
  type: actionTypes.UPDATE_COLLECTION_START,
});

export const updateCollectionSuccess = () => ({
  type: actionTypes.UPDATE_COLLECTION_SUCCESS,
});

export const updateCollectionFailure = (error) => ({
  type: actionTypes.UPDATE_COLLECTION_FAILURE,
  payload: error,
});

export const getSeasonHeadersStart = () => ({
  type: actionTypes.GET_SEASON_HEADERS_START,
});

export const getSeasonHeadersSuccess = (payload) => ({
  type: actionTypes.GET_SEASON_HEADERS_SUCCESS,
  payload,
});

export const getSeasonHeadersFailure = (error) => ({
  type: actionTypes.GET_SEASON_HEADERS_FAILURE,
  payload: error,
});

export const getSeasonAssetsStart = () => ({
  type: actionTypes.GET_SEASON_ASSETS_START,
});

export const getSeasonAssetsSuccess = (payload) => ({
  type: actionTypes.GET_SEASON_ASSETS_SUCCESS,
  payload,
});

export const getSeasonAssetsFailure = (error) => ({
  type: actionTypes.GET_SEASON_ASSETS_FAILURE,
  payload: error,
});

export const getAssetHeadersStart = () => ({
  type: actionTypes.GET_ASSETS_HEADERS_START,
});

export const getAssetHeadersSuccess = (payload) => ({
  type: actionTypes.GET_ASSETS_HEADERS_SUCCESS,
  payload,
});

export const getAssetHeadersFailure = (error) => ({
  type: actionTypes.GET_ASSETS_HEADERS_FAILURE,
  payload: error,
});

export const getAssetsStart = () => ({
  type: actionTypes.GET_ASSETS_START,
});

export const getAssetsSuccess = (payload) => ({
  type: actionTypes.GET_ASSETS_SUCCESS,
  payload,
});

export const getAssetsFailure = (error) => ({
  type: actionTypes.GET_ASSETS_FAILURE,
  payload: error,
});

export const clear = () => ({
  type: actionTypes.CLEAR,
});