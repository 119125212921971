import { actionTypes } from '../actions/collection';

export const collectionDefaultState = {
  collection: {},
  seasonHeaders: [],
  assetHeaders: [],
  seasonAssets: [],
  assets: [],
  error: null,
  assetsLoading: false,
  seasonAssetsLoading: false,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = collectionDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEASON_HEADERS_START:
    case actionTypes.UPDATE_COLLECTION_START:
    case actionTypes.GET_COLLECTION_START:
    case actionTypes.GET_ASSETS_HEADERS_START:
      return { ...state, error: null };

    case actionTypes.GET_ASSETS_START:
      return { ...state, error: null, assetsLoading: true };

    case actionTypes.GET_SEASON_ASSETS_START:
      return { ...state, error: null, seasonAssetsLoading: true };

    case actionTypes.GET_COLLECTION_SUCCESS:
      return { ...state, collection: action.payload, error: null };
    case actionTypes.GET_COLLECTION_FAILURE:
      return { ...state, collection: {}, error: action.payload };

    case actionTypes.UPDATE_COLLECTION_SUCCESS:
      return { ...state, error: null };
    case actionTypes.UPDATE_COLLECTION_FAILURE:
      return { ...state, error: action.payload };

    case actionTypes.GET_SEASON_HEADERS_SUCCESS:
      return { ...state, seasonHeaders: action.payload, error: null };
    case actionTypes.GET_SEASON_HEADERS_FAILURE:
      return { ...state, seasonHeaders: [], error: action.payload };

    case actionTypes.GET_SEASON_ASSETS_SUCCESS:
      return { ...state, seasonAssets: action.payload, seasonAssetsLoading: false, error: null };
    case actionTypes.GET_SEASON_ASSETS_FAILURE:
      return { ...state, seasonAssets: [], seasonAssetsLoading: false, error: action.payload };

    case actionTypes.GET_ASSETS_HEADERS_SUCCESS:
      return { ...state, assetHeaders: action.payload, error: null };
    case actionTypes.GET_ASSETS_HEADERS_FAILURE:
      return { ...state, assetHeaders: [], error: action.payload };

    case actionTypes.GET_ASSETS_SUCCESS:
      return { ...state, assets: action.payload, error: null, assetsLoading: false };
    case actionTypes.GET_ASSETS_FAILURE:
      return { ...state, assets: [], assetsLoading: false, error: action.payload };

    case actionTypes.CLEAR:
      return collectionDefaultState;

    default:
      return state;
  }
};

export default reducer;
