/* eslint-disable no-underscore-dangle */
import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import createCache from '@emotion/cache';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  bbfcTheme: {
    palette: {
      green: { main: '#08918c' },
      lightGreen: { main: '#edf7f7' },
      blue: { main: '#3e7cf4' },
      darkBlue: { main: '#083791' },
      darkGrey: { main: '#3A4850' },
      grey95: { main: '#F5F6FA' },
      grey85: { main: '#D5D9DC' },
      grey70: { main: '#ACB2B9' },
      grey60: { main: '#9098A2' },
      grey50: { main: '#747F8B' },
      grey20: { main: '#304148' },
      grey10: { main: '#274348' },
      red: { main: '#A93222' },
      lightRed: { main: '#F6D8D5' },
      pink: { main: '#fb4f93' },
      yellow: { main: '#fbad00' },
    },
  },
  typography: {
    fontFamily: ['Proxima Nova', 'Roboto', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      light: '#00ada7',
      main: '#374a52',
      dark: '#2d3c43',
      contrastText: '#fff',
    },
    secondary: {
      light: '#00ada7',
      main: '#00ada7',
      dark: '#00ada7',
      contrastText: '#fff',
    },
    error: {
      light: '#dc0a0a',
      main: '#dc0a0a',
      dark: '#dc0a0a',
    },
    success: {
      main: green[500],
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#fff',
    },
    background: {
      default: '#f3f4f5;',
    },
    link: {
      primary: 'rgb(22, 115, 177)',
      muted: 'rgba(0, 0, 0, 0.54)',
    },
    text: {
      primary: '#424242',
      muted: 'rgba(0, 0, 0, 0.54)',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.85rem',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          padding: '0 16px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '0px',
        },
      },
    },
  },
});

// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
const createEmotionCache = () => createCache({ key: 'css', prepend: true });

export { theme, createEmotionCache };
