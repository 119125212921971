import { actionTypes } from '../actions/assets';

const tableColumns = [
  { columnName: 'id', width: 80 },
  { columnName: 'title', width: 220 },
  { columnName: 'category', width: 220 },
  { columnName: 'has_video', width: 125 },
  { columnName: 'runtime', width: 125 },
  { columnName: 'flashing_letter', width: 140 },
  { columnName: 'subtitles', width: 110 },
  { columnName: 'country', width: 110 },
  { columnName: 'cuts', width: 80 },
  { columnName: 'language', width: 120 },
  { columnName: 'classification_date', width: 185 },
  { columnName: 'viewing_date', width: 185 },
  { columnName: 'classification', width: 185 },
];

export const assetsDefaultState = {
  error: '',
  loading: true,
  rows: [],
  tablePreferences: {
    columnOrder: tableColumns.map(({ columnName }) => columnName),
    columnWidths: tableColumns,
    rowsPerPage: 50,
  },
};

// eslint-disable-next-line default-param-last
const reducer = (state = assetsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_BY_TAGS:
      return { ...state, error: '', loading: true };
    case actionTypes.SEARCH_BY_TAGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: false,
      };
    case actionTypes.SEARCH_BY_TAGS_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload.error.message };
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: assetsDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    default:
      return state;
  }
};

export default reducer;
