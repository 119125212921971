/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import Head from 'next/head';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import withReduxStore from '../lib/with-redux-store';
import {
  getCookie,
  setCookieContext,
  clearCookieContext,
  removeCookie,
} from '../utils/cookie';
import {
  initLogin,
  refreshTokenIfNeeded,
} from '../store/thunks/authentication';
import { resetSessionCountdownCounter } from '../store/actions/app';
import { redirectTo } from '../utils/utils';
import BackgroundFileUploader from '../components/tasks/backgroundFileUploader';
import PermissionContext from './_permission';

import {
  isSuperAdmin,
  selectPermissions,
  selectIsAuthenticated,
} from '../store/selectors/authentication';
import { getFlags } from '../store/thunks/app';
import { hasPermission, hasPermissions } from '../utils/permissions';
import { theme, createEmotionCache } from '../lib/getPageContext';

import 'react-quill/dist/quill.snow.css';
import '../scss/main.scss';
import 'moment/locale/en-gb';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

class MyApp extends App {
  constructor(props) {
    super(props);
    moment.locale('en-gb');
  }

  componentDidMount() {
    window.addEventListener('click', this.handleUserActivity);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleUserActivity);
  }

  handleUserActivity = () => {
    const { reduxStore } = this.props;
    const isAuthenticated = selectIsAuthenticated(reduxStore.getState());
    if (!isAuthenticated) return;
    reduxStore.dispatch(refreshTokenIfNeeded());
  };

  static async getInitialProps({ Component, ctx }) {
    const { getInitialProps } = Component;
    const isServer = typeof window === 'undefined';
    const { reduxStore, res, asPath } = ctx;

    if (isServer) setCookieContext(ctx);
    else clearCookieContext();

    const token = getCookie('token');
    const refreshToken = getCookie('refreshToken');

    if (
      !token &&
      !asPath.includes('/login') &&
      !asPath.includes('/password-reset') &&
      !asPath.includes('/signup')
    ) {
      return redirectTo('/login', asPath, { res });
    }

    if (token) {
      try {
        const { exp } = jwtDecode(token);
        const remainingTime = Number(exp * 1000) - Date.now();
        if (remainingTime < 0 && !asPath.includes('/login')) {
          removeCookie('token');
          return redirectTo('/login', asPath, { res });
        }
        reduxStore.dispatch(resetSessionCountdownCounter(remainingTime));
      } catch (e) {
        if (!asPath.includes('/login')) return redirectTo('/login', asPath, { res });
      }
    }

    if (isServer && token) await reduxStore.dispatch(initLogin({ token, refreshToken }));
    const {
      authentication: { isAuthenticated },
      app: { flags },
    } = reduxStore.getState();

    if (!flags?.values.length) await ctx.reduxStore.dispatch(getFlags());

    const pageProps =
      typeof getInitialProps === 'function'
        ? await getInitialProps({ ...ctx, isServer }) || {}
        : {};
    const { page = {} } = pageProps;

    if (
      !isAuthenticated &&
      !page.canViewAsAnonymus &&
      !asPath.includes('/login')
    ) {
      return redirectTo('/login', asPath, { res });
    }

    if (isAuthenticated && page.canViewAsAuthenticated === false) {
      return redirectTo('/', null, { res });
    }

    return { pageProps };
  }

  render() {
    const {
      Component,
      emotionCache = clientSideEmotionCache,
      pageProps,
      reduxStore,
    } = this.props;
    const { page: { title } = {} } = pageProps;
    const state = reduxStore.getState();
    const userIsSuperAdmin = isSuperAdmin(state);
    const userPermissions = selectPermissions(state);

    return (
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{title ? `${title} - ` : ''} BBFC</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>
        <ThemeProvider theme={theme}>
          <PermissionContext.Provider
            value={{
              hasPermission: hasPermission({
                userIsSuperAdmin,
                userPermissions,
              }),
              hasPermissions: hasPermissions({
                userIsSuperAdmin,
                userPermissions,
              }),
              isAdmin: userIsSuperAdmin,
            }}
          >
            <CssBaseline />
            <Provider store={reduxStore}>
              <QueryClientProvider client={queryClient}>
                <Component pageContext={this.pageContext} {...pageProps} />
                <BackgroundFileUploader />
              </QueryClientProvider>
            </Provider>
          </PermissionContext.Provider>
        </ThemeProvider>
      </CacheProvider>
    );
  }
}

export default withReduxStore(MyApp);
