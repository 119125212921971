export const actionTypes = {
  GET_RELEVANT_ASSETS: 'GET_RELEVANT_ASSETS',
  GET_RELEVANT_ASSETS_SUCCESS: 'GET_RELEVANT_ASSETS_SUCCESS',
  GET_RELEVANT_ASSETS_FAILURE: 'GET_RELEVANT_ASSETS_FAILURE',
  UPDATE_RELEVANT_ASSET_OVERRIDE: 'UPDATE_RELEVANT_ASSET_OVERRIDE',
  CLEAR_RELEVANT_ASSETS: 'CLEAR_RELEVANT_ASSETS',
  GET_POSSIBLE_RELEVANT_ASSETS: 'GET_POSSIBLE_RELEVANT_ASSETS',
  GET_POSSIBLE_RELEVANT_ASSETS_SUCCESS: 'GET_POSSIBLE_RELEVANT_ASSETS_SUCCESS',
  GET_POSSIBLE_RELEVANT_ASSETS_FAILURE: 'GET_POSSIBLE_RELEVANT_ASSETS_FAILURE',
  CLEAR_POSSIBLE_RELEVANT_ASSETS: 'CLEAR_POSSIBLE_RELEVANT_ASSETS',
};

export const getRelevantAssetStarted = () => ({ type: actionTypes.GET_RELEVANT_ASSETS });
export const getRelevantAssetSuccess = payload => ({ type: actionTypes.GET_RELEVANT_ASSETS_SUCCESS, payload });
export const getRelevantAssetFailed = payload => ({ type: actionTypes.GET_RELEVANT_ASSETS_FAILURE, payload });
export const clearRelevantAsset = () => ({ type: actionTypes.CLEAR_RELEVANT_ASSETS });
export const updateRelevantAssetOverride = (collectionId, id, payload) => ({ type: actionTypes.UPDATE_RELEVANT_ASSET_OVERRIDE, payload: { id, collectionId, ...payload } });
export const getPossibleRelevantAssetStarted = () => ({ type: actionTypes.GET_POSSIBLE_RELEVANT_ASSETS });
export const getPossibleRelevantAssetSuccess = payload => ({ type: actionTypes.GET_POSSIBLE_RELEVANT_ASSETS_SUCCESS, payload });
export const getPossibleRelevantAssetFailed = payload => ({ type: actionTypes.GET_POSSIBLE_RELEVANT_ASSETS_FAILURE, payload });
export const clearPossibleRelevantAsset = () => ({ type: actionTypes.CLEAR_POSSIBLE_RELEVANT_ASSETS });
