import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.messagingApiUrl || publicRuntimeConfig.messagingApiUrl;

console.log('MESSAGING API URI:', apiUrl); // eslint-disable-line no-console

const messagingApi = {
  get: invoke(apiUrl, 'GET'),
  post: invoke(apiUrl, 'POST'),
  put: invoke(apiUrl, 'PUT'),
  patch: invoke(apiUrl, 'PATCH'),
  delete: invoke(apiUrl, 'DELETE'),
};

/**
 * Authenticated routes
 */
export const fetchMessages = (assetIds, workOrderId) => messagingApi.post('/messages/get-all', { body: { assetIds, workOrderIds: [workOrderId] }, query: { order: 'DESC' } });
export const checkMessages = (assetId, workOrderId) => messagingApi.post('/messages/check', { body: { assetIds: [assetId], workOrderIds: [workOrderId] } });
export const checkMessagesCount = (assetIds, workOrderId) => messagingApi.post('/messages/check/count', { body: { assetIds, workOrderIds: [workOrderId] } });
export const saveMessage = body => messagingApi.post('/messages/send', { body });
export const downloadFile = (messageId, attachmentId) => messagingApi.post(
  `/messages/get-attachment/${messageId}/${attachmentId}`,
  { responseType: 'blob', raw: true }
);
export const getFileURL = (messageId, attachmentId) => messagingApi.post(`/messages/get-attachment/${messageId}/${attachmentId}`);
