import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const tagProxyUrl = `${apiUrl}/tags`;

console.log('TAG PROXY URI:', tagProxyUrl); // eslint-disable-line no-console

const proxyApi = {
  get: invoke(tagProxyUrl, 'GET'),
  post: invoke(tagProxyUrl, 'POST'),
  patch: invoke(tagProxyUrl, 'PATCH'),
  put: invoke(tagProxyUrl, 'PUT'),
};

const getTags = () => proxyApi.get('/tags');

const createTag = (parentId, value, type) => proxyApi.post('/tags', { body: { parent_id: parentId, value, type } });

const editTag = (id, body) => proxyApi.patch(`/tags/${id}`, { body });

const disableTag = id => proxyApi.patch(`/tags/${id}/disable`);

const enableTag = id => proxyApi.patch(`/tags/${id}/enable`);

const searchTags = (tagId, filters, markerClassifications, secondaryTagIds) => {
  const { page, rowsPerPage, orderBy, order, ...body } = filters;
  return proxyApi.put('/tags/search', { body: {
    tagId,
    markerClassifications: markerClassifications.filter(_ => _),
    secondaryTagIds: secondaryTagIds.filter(_ => _),
    ...body.filters,
  },
  query: { page, rowsPerPage, orderBy, order } });
};

const tagClient = {
  getTags,
  createTag,
  editTag,
  disableTag,
  enableTag,
  searchTags,
};

export default tagClient;
