/* eslint-disable no-case-declarations */
import { actionTypes } from '../actions/uppy';
import { FILE_UPLOAD_STATUSES } from '../../utils/constants';

export const uppyDefaultState = {
  uploadItems: {},
  uploader: {},
};

// eslint-disable-next-line default-param-last
export const reducer = (state = uppyDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FILE_ADDED:
      const newUpload = [{
        ...payload.file,
        isUploading: true,
        status: FILE_UPLOAD_STATUSES.INITIALIZED,
        progress: {
          ...payload.file.progress,
          uploadStarted: true,
        },
      }];

      if (payload.multiple && state.uploadItems[payload.source]) newUpload.push(...state.uploadItems[payload.source]);

      return {
        uploadItems: {
          ...state.uploadItems,
          [payload.source]: newUpload,
        },
      };
    case actionTypes.UPLOAD_PROGRESS:
      return {
        uploadItems: {
          ...state.uploadItems,
          [payload.source]: state.uploadItems[payload.source].map(upload => {
            if (upload.id !== payload.fileId) return upload;
            if (upload.progress.percentage === 100) return upload;

            return {
              ...upload,
              error: null,
              status: FILE_UPLOAD_STATUSES.IN_PROGRESS,
              progress: {
                ...upload.progress,
                bytesUploaded: payload.progress.bytesUploaded,
                percentage: Math.floor(((payload.progress.bytesUploaded / upload.progress.bytesTotal) * 100).toFixed(2)),
              },
            };
          }),
        },
      };
    case actionTypes.UPLOAD_SUCCESS:
      return {
        uploadItems: {
          ...state.uploadItems,
          [payload.source]: state.uploadItems[payload.source].map(upload => {
            if (upload.id !== payload.fileId) return upload;

            return {
              ...upload,
              isUploading: false,
              error: null,
              status: FILE_UPLOAD_STATUSES.UPLOADED,
              progress: {
                ...upload.progress,
                percentage: 100,
                bytesUploaded: upload.progress.bytesTotal,
                uploadComplete: true,
                uploadStarted: false,
              },
            };
          }),
        },
      };
    case actionTypes.UPLOAD_COMPLETE:
      return {
        uploads: state.uploads.map(upload => {
          const successMatch = payload.successful.find(file => file.id === upload.id);
          if (successMatch) return successMatch;
          const failedMatch = payload.failed.find(file => file.id === upload.id);
          if (failedMatch) return failedMatch;
          return upload;
        }),
      };
    case actionTypes.UPLOAD_ERROR:
      return {
        uploadItems: {
          ...state.uploadItems,
          [payload.source]: state.uploadItems[payload.source].map(upload => {
            if (upload.id !== payload.fileId) return upload;

            return {
              ...upload,
              isUploading: false,
              error: payload.error,
              status: FILE_UPLOAD_STATUSES.FAILED,
              progress: {
                ...upload.progress,
                uploadComplete: false,
                uploadStarted: false,
              },
            };
          }),
        },
      };

    case actionTypes.UPPY_INIT:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            initialized: true,
            callOnUploadComplete: false,
            files: [],
          },
        },
      };

    case actionTypes.UPPY_SET_UPLOAD_COMPLETE:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            callOnUploadComplete: action.payload.callOnUploadComplete,
          },
        },
      };

    case actionTypes.UPPY_FILE_ADDED:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            files: [
              ...state.uploader[action.payload.source].files,
              {
                ...action.payload.file,
                status: action.payload.file.meta.error ? 'Error' : 'Added',
                error: action.payload.file.meta.error,
                progress: {
                  percentage: 0,
                },
              },
            ],
          },
        },
      };

    case actionTypes.UPPY_FILE_REMOVED:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            files: state.uploader[action.payload.source].files.filter(file => file.id !== action.payload.file.id),
          },
        },
      };

    case actionTypes.UPPY_UPLOAD_PROGRESS:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            files: state.uploader[action.payload.source].files.map(file => {
              if (file.id !== action.payload.fileId) return file;
              if (file.status === 'Paused') return file;
              if (file.status === 'Uploaded') return file;

              return {
                ...file,
                status: 'Uploading',
                progress: {
                  ...file.progress,
                  ...action.payload.progress,
                  percentage: Math.floor(((action.payload.progress.bytesUploaded / action.payload.progress.bytesTotal) * 100).toFixed(2)),
                },
              };
            }),
          },
        },
      };

    case actionTypes.UPPY_UPLOAD_STARTED:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            files: state.uploader[action.payload.source].files.map(file => {
              if (file.id !== action.payload.fileId) return file;

              return {
                ...file,
                status: 'Uploading',
                progress: {
                  ...file.progress,
                  ...action.payload.progress,
                  uploadStarted: new Date().getTime(),
                },
              };
            }),
          },
        },
      };

    case actionTypes.UPPY_FILE_PAUSED_OR_RESUMED:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            files: state.uploader[action.payload.source].files.map(file => {
              if (file.id !== action.payload.fileId) return file;
              if (action.payload.isPaused) return { ...file, status: 'Paused' };
              if (!action.payload.isPaused) return { ...file, status: 'Uploading' };
              return file;
            }),
          },
        },
      };

    case actionTypes.UPPY_UPLOAD_COMPLETED:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          [action.payload.source]: {
            ...state.uploader[action.payload.source],
            files: state.uploader[action.payload.source].files.map(file => {
              const successFile = action.payload.successful.find(f => f.id === file.id);
              const failedFile = action.payload.failed.find(f => f.id === file.id);

              if (successFile) {
                return {
                  ...file,
                  status: 'Uploaded',
                  progress: {
                    ...file.progress,
                    percentage: 100,
                    completed: true,
                  },
                };
              }

              if (failedFile) {
                return {
                  ...file,
                  status: 'Failed',
                  progress: {
                    ...file.progress,
                    percentage: 100,
                    completed: true,
                    failed: true,
                  },
                };
              }

              return file;
            }),
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
