/* eslint-disable no-nested-ternary */
import { v4 as uuidv4 } from 'uuid';

export const getSpeed = (fileProgress) => {
  if (!fileProgress.bytesUploaded) return 0;

  const timeElapsed = (new Date()) - fileProgress.uploadStarted;
  const uploadSpeed = fileProgress.bytesUploaded / (timeElapsed / 1000);
  return uploadSpeed;
};

export const getBytesRemaining = (fileProgress) => fileProgress.bytesTotal - fileProgress.bytesUploaded;

export const getETA = (file) => {
  const totalSpeed = getSpeed(file.progress);
  if (totalSpeed === 0) {
    return 0;
  }

  const bytesRemaining = getBytesRemaining(file.progress);

  return Math.round((bytesRemaining / totalSpeed) * 10) / 10;
};

const secondsToTime = (rawSeconds) => {
  const hours = Math.floor(rawSeconds / 3600) % 24;
  const minutes = Math.floor(rawSeconds / 60) % 60;
  const seconds = Math.floor(rawSeconds % 60);

  return { hours, minutes, seconds };
};

export const prettyETA = (seconds) => {
  const time = secondsToTime(seconds);

  const hoursStr = time.hours ? `${time.hours}h ` : '';
  const minutesVal = time.hours ? (`0${time.minutes}`).substr(-2) : time.minutes;
  const minutesStr = minutesVal ? `${minutesVal}m` : '';
  const secondsVal = minutesVal ? (`0${time.seconds}`).substr(-2) : time.seconds;
  const secondsStr = time.hours ? '' : (minutesVal ? ` ${secondsVal}s` : `${secondsVal}s`);

  return `${hoursStr}${minutesStr}${secondsStr}`;
};

export const getFileName = (folder, file, generateFileName) => {
  if (!file) throw new Error('File is required');
  const fileName = generateFileName ? `${uuidv4()}.${file.name.split('.').pop()}` : file.name;
  if (folder) return `${folder}/${fileName}`;
  return fileName;
};
