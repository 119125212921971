export const actionTypes = {
  START_FETCH_MESSAGES: 'START_FETCH_MESSAGES',
  RESOLVE_FETCH_MESSAGES: 'RESOLVE_FETCH_MESSAGES',
  REJECT_FETCH_MESSAGES: 'REJECT_FETCH_MESSAGES',
  START_SEND_MESSAGE: 'START_SEND_MESSAGE',
  RESOLVE_SEND_MESSAGE: 'RESOLVE_SEND_MESSAGE',
  REJECT_SEND_MESSAGE: 'REJECT_SEND_MESSAGE',
  START_DOWNLOAD_ATTACHMENT: 'START_DOWNLOAD_ATTACHMENT',
  RESOLVE_DOWNLOAD_ATTACHMENT: 'RESOLVE_DOWNLOAD_ATTACHMENT',
  REJECT_DOWNLOAD_ATTACHMENT: 'REJECT_DOWNLOAD_ATTACHMENT',
  SAVE_MESSAGING_TIMEOUT: 'SAVE_MESSAGING_TIMEOUT',
  CLEAR_MESSAGING_TIMEOUT: 'CLEAR_MESSAGING_TIMEOUT',
  SAVE_USERS_DETAILS: 'SAVE_USERS_DETAILS',
  SET_REPLY_ID: 'SET_REPLY_ID',
  START_CHECK_MESSAGES: 'START_CHECK_MESSAGES',
  RESOLVE_CHECK_MESSAGES: 'RESOLVE_CHECK_MESSAGES',
  REJECT_CHECK_MESSAGES: 'REJECT_CHECK_MESSAGES',
  SAVE_CHECK_MESSAGES_TIMEOUT: 'SAVE_CHECK_MESSAGES_TIMEOUT',
  CLEAR_CHECK_MESSAGES_TIMEOUT: 'CLEAR_CHECK_MESSAGES_TIMEOUT',
};

export const startFetchMessages = () => ({
  type: actionTypes.START_FETCH_MESSAGES,
});
export const resolveFetchMessages = (data, firstStart) => ({
  type: actionTypes.RESOLVE_FETCH_MESSAGES,
  payload: { data, firstStart },
});
export const rejectFetchMessages = error => ({
  type: actionTypes.REJECT_FETCH_MESSAGES,
  payload: error,
});

export const startCheckMessages = () => ({
  type: actionTypes.START_CHECK_MESSAGES,
});
export const resolveCheckMessages = (data) => ({
  type: actionTypes.RESOLVE_CHECK_MESSAGES,
  payload: { data },
});
export const rejectCheckMessages = error => ({
  type: actionTypes.REJECT_CHECK_MESSAGES,
  payload: error,
});

export const startSendMessage = () => ({
  type: actionTypes.START_SEND_MESSAGE,
});
export const resolveSendMessage = () => ({
  type: actionTypes.RESOLVE_SEND_MESSAGE,
});
export const rejectSendMessage = error => ({
  type: actionTypes.REJECT_SEND_MESSAGE,
  payload: error,
});

export const startDownloadAttachment = () => ({
  type: actionTypes.START_DOWNLOAD_ATTACHMENT,
});
export const resolveDownloadAttachment = () => ({
  type: actionTypes.RESOLVE_DOWNLOAD_ATTACHMENT,
});
export const rejectDownloadAttachment = error => ({
  type: actionTypes.REJECT_DOWNLOAD_ATTACHMENT,
  payload: error,
});

export const saveMessagingTimeout = id => ({
  type: actionTypes.SAVE_MESSAGING_TIMEOUT,
  payload: id,
});
export const clearMessagingTimeout = (clearOnlyTimeout = false) => ({
  type: actionTypes.CLEAR_MESSAGING_TIMEOUT,
  payload: { clearOnlyTimeout },
});

export const saveCheckMessagesTimeout = id => ({
  type: actionTypes.SAVE_CHECK_MESSAGES_TIMEOUT,
  payload: id,
});
export const clearCheckMessagesTimeout = () => ({
  type: actionTypes.CLEAR_CHECK_MESSAGES_TIMEOUT,
});

export const saveUsersDetails = users => ({
  type: actionTypes.SAVE_USERS_DETAILS,
  payload: users,
});

export const setReplyID = id => ({
  type: actionTypes.SET_REPLY_ID,
  payload: id,
});

const messaging = {
  startFetchMessages,
  resolveFetchMessages,
  rejectFetchMessages,
  startSendMessage,
  resolveSendMessage,
  rejectSendMessage,
  startDownloadAttachment,
  resolveDownloadAttachment,
  rejectDownloadAttachment,
  saveMessagingTimeout,
  clearMessagingTimeout,
  saveUsersDetails,
  setReplyID,
};

export default messaging;
