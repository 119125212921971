export const actionTypes = {
  SEARCH_ASSETS_START: 'vd-SEARCH_ASSETS_START',
  SEARCH_ASSETS_SUCCESS: 'vd-SEARCH_ASSETS_SUCCESS',
  SEARCH_ASSETS_FAILURE: 'vd-SEARCH_ASSETS_FAILURE',
  GET_SUGGESTIONS: 'GET_SUGGESTIONS',
  CLEAR_SUGGESTIONS: 'CLEAR_SUGGESTIONS',
  GET_VIDEO_START: 'GET_VIDEO_START',
  GET_VIDEO_SUCCESS: 'GET_VIDEO_SUCCESS',
  GET_VIDEO_FAILURE: 'GET_VIDEO_FAILURE',
  GET_PARENT_START: 'GET_PARENT_START',
  GET_PARENT_SUCCESS: 'GET_PARENT_SUCCESS',
  GET_PARENT_FAILURE: 'GET_PARENT_FAILURE',
  CLEAR_PARENT: 'CLEAR_PARENT',
  GET_RELATED_START: 'GET_RELATED_START',
  GET_RELATED_SUCCESS: 'GET_RELATED_SUCCESS',
  GET_RELATED_FAILURE: 'GET_RELATED_FAILURE',
  GET_RELATED_LIST_SUCCESS: 'GET_RELATED_LIST_SUCCESS',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_ITEM_METADATA_START: 'UPDATE_ITEM_METADATA_START',
  UPDATE_ITEM_METADATA_FAILURE: 'UPDATE_ITEM_METADATA_FAILURE',
  UPDATE_ITEM_METADATA_SUCCESS: 'UPDATE_ITEM_METADATA_SUCCESS',
  GET_TABLE_PREFERENCES_SUCCESS: 'GET_ARCHIVE_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'RESET_ARCHIVE_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'SET_ARCHIVE_TABLE_PREFERENCES',
  GET_ARCHIVE_FILES: 'GET_ARCHIVE_FILES',
  GET_ARCHIVE_FILES_SUCCESS: 'GET_ARCHIVE_FILES_SUCCESS',
  GET_ARCHIVE_FILES_FAILURE: 'GET_ARCHIVE_FILES_FAILURE',
  GET_ARCHIVE_HAS_FILES: 'GET_ARCHIVE_HAS_FILES',
  GET_ARCHIVE_HAS_FILES_SUCCESS: 'GET_ARCHIVE_HAS_FILES_SUCCESS',
  GET_ARCHIVE_HAS_FILES_FAILURE: 'GET_ARCHIVE_HAS_FILES_FAILURE',
  SEARCH_COLLECTIONS_START: 'vd-SEARCH_COLLECTIONS_START',
  SEARCH_COLLECTIONS_SUCCESS: 'vd-SEARCH_COLLECTIONS_SUCCESS',
  SEARCH_COLLECTIONS_FAILURE: 'vd-SEARCH_COLLECTIONS_FAILURE',
};

export const searchAssetsStart = () => ({ type: actionTypes.SEARCH_ASSETS_START });
export const searchAssetsFailure = () => ({ type: actionTypes.SEARCH_ASSETS_FAILURE });
export const searchAssetsSuccess = (assets, count = 0) => ({
  type: actionTypes.SEARCH_ASSETS_SUCCESS,
  payload: { assets, count },
});

export const searchCollectionsStart = () => ({ type: actionTypes.SEARCH_COLLECTIONS_START });
export const searchCollectionsFailure = () => ({ type: actionTypes.SEARCH_COLLECTIONS_FAILURE });
export const searchCollectionsSuccess = (collections, append, count = 0) => ({
  type: actionTypes.SEARCH_COLLECTIONS_SUCCESS,
  payload: { collections, append, count },
});

export const getSuggestionsSuccess = suggestions => ({
  type: actionTypes.GET_SUGGESTIONS,
  payload: { suggestions },
});
export const clearSuggestions = () => ({ type: actionTypes.CLEAR_SUGGESTIONS });
export const updateFilter = filter => ({
  type: actionTypes.UPDATE_FILTER,
  payload: { filter },
});

export const getVideoStart = () => ({ type: actionTypes.GET_VIDEO_START });
export const getVideoFailure = () => ({ type: actionTypes.GET_VIDEO_FAILURE });
export const getVideoSuccess = video => ({
  type: actionTypes.GET_VIDEO_SUCCESS,
  payload: { video },
});

export const getRelatedStart = () => ({ type: actionTypes.GET_RELATED_START });
export const getRelatedFailure = () => ({ type: actionTypes.GET_RELATED_FAILURE });
export const getRelatedSuccess = related => ({
  type: actionTypes.GET_RELATED_SUCCESS,
  payload: { related },
});
export const getRelatedListSuccess = (results, append) => ({
  type: actionTypes.GET_RELATED_LIST_SUCCESS,
  payload: { results, append },
});
export const updateItemMetaDataStart = () => ({ type: actionTypes.UPDATE_ITEM_METADATA_START });
export const updateItemMetaDataFailure = () => ({ type: actionTypes.UPDATE_ITEM_METADATA_FAILURE });
export const updateItemMetaDataSuccess = ( videoId ) => ({
  type: actionTypes.UPDATE_ITEM_METADATA_SUCCESS,
  payload: { videoId },
});

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });
export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });
export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });

export const getParentStart = () => ({ type: actionTypes.GET_PARENT_START });
export const getParentFailure = () => ({ type: actionTypes.GET_PARENT_FAILURE });
export const getParentSuccess = video => ({
  type: actionTypes.GET_PARENT_SUCCESS,
  payload: { video },
});

export const fetchFilesStarted = () => ({ type: actionTypes.GET_ARCHIVE_FILES });
export const fetchFilesSuccess = payload => ({ type: actionTypes.GET_ARCHIVE_FILES_SUCCESS, payload });
export const fetchFilesFailed = payload => ({ type: actionTypes.GET_ARCHIVE_FILES_FAILURE, payload });

export const fetchHasFilesStarted = () => ({ type: actionTypes.GET_ARCHIVE_HAS_FILES });
export const fetchHasFilesSuccess = payload => ({ type: actionTypes.GET_ARCHIVE_HAS_FILES_SUCCESS, payload });
export const fetchHasFilesFailed = payload => ({ type: actionTypes.GET_ARCHIVE_HAS_FILES_FAILURE, payload });