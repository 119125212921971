import cookie from 'js-cookie';
import getConfig from 'next/config';

const { publicRuntimeConfig: { cookiesConfig } } = getConfig();

const ctx = {
  res: {},
  req: {},
};

export const setCookieContext = ({ res, req }) => {
  ctx.res = res;
  ctx.req = req;
};

export const clearCookieContext = () => {
  ctx.res = {};
  ctx.req = {};
};

export const setCookie = (key, value, expires) => {
  const options = { path: '/', secure: cookiesConfig.secure };

  if (expires) options.expires = expires;

  if (!process.browser) return ctx.res.cookie(key, value, options);

  cookie.set(key, value, options);
};

export const removeCookie = (key) => {
  if (!process.browser) return ctx.res.clearCookie(key);

  cookie.remove(key);
};

const getCookieFromBrowser = key => cookie.get(key);

const getCookieFromServer = key => {
  if (!ctx.req || !ctx.req.headers || !ctx.req.headers.cookie) return false;

  const rawCookie = ctx.req.headers.cookie
    .split(';')
    .find(c => c.trim().startsWith(`${key}=`));
  if (!rawCookie) return false;
  const [, token] = rawCookie.split('=');
  if (!token) return false;

  return token;
};

export const getCookie = key => {
  if (process.browser) return getCookieFromBrowser(key);
  return getCookieFromServer(key);
};
