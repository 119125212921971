import { actionTypes } from '../actions/clip-exports';

const tableColumns = [
  { columnName: 'id', width: 215 },
  { columnName: 'clipLength', width: 150 },
  { columnName: 'tagName', width: 300 },
  { columnName: 'status', width: 200 },
  { columnName: 'driveUrl', width: 150 },
  { columnName: 'createdAt', width: 200 },
];

export const clipExportsDefaultState = {
  error: '',
  loading: true,
  rows: [],
  detail: {},
  tablePreferences: {
    hiddenColumns: [],
    columnOrder: tableColumns.map(({ columnName }) => columnName),
    columnWidths: tableColumns,
    rowsPerPage: 50,
  },
};

// eslint-disable-next-line default-param-last
const reducer = (state = clipExportsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EXPORTS:
      return { ...state, error: '', loading: true };
    case actionTypes.FETCH_EXPORTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: false,
      };
    case actionTypes.FETCH_EXPORTS_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload.error.message };
    case actionTypes.FETCH_EXPORT:
      return { ...state, error: '', loading: true };
    case actionTypes.FETCH_EXPORT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        error: '',
        loading: false,
      };
    case actionTypes.FETCH_EXPORT_FAILURE:
      return { ...state, detail: {}, loading: false, error: action.payload.error.message };
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: clipExportsDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    default:
      return state;
  }
};

export default reducer;
