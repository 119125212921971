import { actionTypes } from '../actions/roles';

export const rolesDefaultState = {
  error: {},
  loading: true,
  rows: [],
  details: {},
  count: 0,
  users: {
    rows: [],
    count: 0,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = rolesDefaultState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES:
      return { ...state, error: {}, loading: true };
    case actionTypes.FETCH_ROLES_SUCCESS:
      return { ...state, ...action.payload, error: {}, loading: false };
    case actionTypes.FETCH_ROLES_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload };
    case actionTypes.SAVE_ROLE:
      return { ...state, error: {}, loading: true };
    case actionTypes.SAVE_ROLE_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.SAVE_ROLE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.FETCH_ROLE:
      return { ...state, error: {}, loading: true };
    case actionTypes.FETCH_ROLE_SUCCESS:
      return { ...state, loading: false, details: action.payload };
    case actionTypes.FETCH_ROLE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.CLEAR_DETAILS:
      return { ...state, details: {} };
    case actionTypes.EDIT_ROLE:
      return { ...state, error: {}, loading: true };
    case actionTypes.EDIT_ROLE_SUCCESS:
      return { ...state, error: {}, loading: false };
    case actionTypes.EDIT_ROLE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.FETCH_ROLE_USERS:
      return { ...state, error: {}, loading: true };
    case actionTypes.FETCH_ROLE_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload };
    case actionTypes.FETCH_ROLE_USERS_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
