import { actionTypes } from '../actions/reports';

export const reportsDefaultState = {
  error: {
    status: 0,
    message: '',
  },
  loading: false,
  rows: [],
  details: {},
};

// eslint-disable-next-line default-param-last
export const reducer = (state = reportsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORT:
      return { ...state, error: reportsDefaultState.error, loading: true };
    case actionTypes.GET_REPORT_SUCCESS:
      return { ...state, details: action.payload, error: reportsDefaultState.error, loading: false };
    case actionTypes.GET_REPORT_FAILURE:
      return { ...state, details: reportsDefaultState.details, loading: false, error: action.payload };
    case actionTypes.CLEAR_REPORT:
      return reportsDefaultState;
    default:
      return state;
  }
};

export default reducer;
