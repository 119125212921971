import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressCircle = ({ progress, show }) => {
  if (!show) {
    return null;
  }
  return (
    <CircularProgress
      className="loading-indicator"
      size={25}
      variant="determinate"
      value={progress}
    />
  );
};

ProgressCircle.propTypes = {
  progress: PropTypes.number,
  show: PropTypes.bool.isRequired,
};

export default ProgressCircle;