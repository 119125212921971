/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import initializeStore from './init-store';

const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

const getOrCreateStore = initialState => {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) return initializeStore(initialState);

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  return window[__NEXT_REDUX_STORE__];
};

export const withReduxStore = App => {
  class AppWithRedux extends Component {
    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore();

      // Provide the store to getInitialProps of pages
      const tmpAppContext = { ...appContext, ctx: { ...appContext.ctx, reduxStore } };

      let appProps = {};
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps.call(App, tmpAppContext);
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      };
    }

    constructor(props) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  }

  AppWithRedux.propTypes = {
    initialReduxState: PropTypes.object.isRequired,
  };

  return AppWithRedux;
};

export default withReduxStore;
