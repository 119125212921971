export const actionTypes = {
  FETCH_EXPORTS: 'clip-FETCH_EXPORTS',
  FETCH_EXPORTS_SUCCESS: 'clip-FETCH_EXPORTS_SUCCESS',
  FETCH_EXPORTS_FAILURE: 'clip-FETCH_EXPORTS_FAILURE',
  GET_EXPORTS_TABLE_PREFERENCES_SUCCESS: 'clip-GET_EXPORTS_TABLE_PREFERENCES_SUCCESS',
  RESET_EXPORTS_TABLE_PREFERENCES: 'clip-RESET_EXPORTS_TABLE_PREFERENCES',
  SET_EXPORTS_TABLE_PREFERENCES: 'clip-SET_EXPORTS_TABLE_PREFERENCES',
  FETCH_EXPORT: 'clip-FETCH_EXPORT',
  FETCH_EXPORT_SUCCESS: 'clip-FETCH_EXPORT_SUCCESS',
  FETCH_EXPORT_FAILURE: 'clip-FETCH_EXPORT_FAILURE',
};

export const fetchExportsStarted = () => ({ type: actionTypes.FETCH_EXPORTS });
export const fetchExportsSuccess = payload => ({ type: actionTypes.FETCH_EXPORTS_SUCCESS, payload });
export const fetchExportsFailed = payload => ({ type: actionTypes.FETCH_EXPORTS_FAILURE, payload });
export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_EXPORTS_TABLE_PREFERENCES_SUCCESS, payload: result });
export const resetTablePreferences = () => ({ type: actionTypes.RESET_EXPORTS_TABLE_PREFERENCES });
export const setTablePreferences = payload => ({ type: actionTypes.SET_EXPORTS_TABLE_PREFERENCES, payload });
export const fetchExportStarted = () => ({ type: actionTypes.FETCH_EXPORT });
export const fetchExportSuccess = payload => ({ type: actionTypes.FETCH_EXPORT_SUCCESS, payload });
export const fetchExportFailed = payload => ({ type: actionTypes.FETCH_EXPORT_FAILURE, payload });