import { actionTypes } from '../actions/calendars';

const tableColums = [
  { columnName: 'generated_resource_name', width: 395 },
  { columnName: 'resourceType', width: 180 },
  { columnName: 'standard', width: 180 },
  { columnName: 'express', width: 180 },
  { columnName: 'nextDay', width: 180 },
  { columnName: 'maxAmmount', width: 180 },
  { columnName: 'options', width: 280 },
];

export const calendarsDefaultState = {
  isLoaded: false,
  isBusy: false,
  apiError: false,
  error: {
    message: '',
    status: null,
  },
  loading: true,
  list: [],
  count: 0,
  detail: {},
  tablePreferences: {
    hiddenColumns: [],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
    rowsPerPage: 10,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = calendarsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_START: {
      return {
        ...state,
        isLoaded: false,
        isBusy: true,
      };
    }
    case actionTypes.GET_LIST_SUCCESS: {
      const { count, result } = action.payload;
      return {
        ...state,
        count,
        list: result,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }
    case actionTypes.GET_LIST_FAILURE: {
      return {
        ...state,
        isBusy: false,
        apiError: true,
      };
    }
    case actionTypes.DELETE_ASSET_CALENDAR_START:
    case actionTypes.UPDATE_ASSET_CALENDAR_START:
    case actionTypes.ADD_ASSET_CALENDAR_START: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case actionTypes.DELETE_ASSET_CALENDAR_SUCCESS:
    case actionTypes.UPDATE_ASSET_CALENDAR_SUCCESS:
    case actionTypes.ADD_ASSET_CALENDAR_SUCCESS:
    case actionTypes.DELETE_ASSET_CALENDAR_FAILURE:
    case actionTypes.UPDATE_ASSET_CALENDAR_FAILURE:
    case actionTypes.ADD_ASSET_CALENDAR_FAILURE: {
      return {
        ...state,
        isBusy: false,
      };
    }
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: calendarsDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    default:
      return state;
  }
};

export default reducer;
