import React from 'react';
import DoneIcon from '@mui/icons-material/CloudDone';

const SuccessMessage = () => (
  <span className="message">
    <DoneIcon className="icon" />
    <span>File(s) uploaded</span>
  </span>
);

export default SuccessMessage;