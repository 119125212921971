import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const proxyUrl = `${apiUrl}/notifications`;

console.log('NOTIFICATION PROXY URI:', proxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(proxyUrl, 'GET'),
  post: invoke(proxyUrl, 'POST'),
  put: invoke(proxyUrl, 'PUT'),
  patch: invoke(proxyUrl, 'PATCH'),
  delete: invoke(proxyUrl, 'DELETE'),
};

export const getNotifications = (query = {}) => api.get('/v1/notifications', { query });
export const readAllNotifications = (query = {}) => api.post('/v1/notifications/read-all', { query });
export const clearAllNotifications = (query = {}) => api.post('/v1/notifications/clear-all', { query });
export const updateNotification = (id, query = {}) => api.patch(`/v1/notification/${id}`, { query });
export const getNews = () => api.get('/news');
export const updateNews = (data) => api.patch('/news', { body: data });
