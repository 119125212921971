/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import { dateFormat, timeFormat, dateTimeFormat } from './constants';

const currYear = new Date().getFullYear();

export const displayFormatDate = date => (date ? moment(date).format(dateFormat) : 'N/A');
export const displayFormatDateTime = date => (date ? moment(date).format(dateTimeFormat) : 'N/A');
export const displayFormatTime = date => moment(date).format(timeFormat);

export const systemFormatDate = date => moment(date).format('YYYY-MM-DD');

export const isDate = (date, format) => (moment(date).isValid() ? format(date) : date);

export const getYearList = (startYear = 1800) => Array.from(Array((currYear + 1) - startYear), (_, i) => (currYear + 1) - i);

export const getDuration = date => {
  if (!date) return 'Never';
  const datetime = moment(date);
  return <span>{datetime.format('DD MMM YYYY HH:mm')} ({datetime.fromNow()})</span>;
};

export const secondsToDuration = (seconds, format = 'HH:mm:ss') => {
  if (!seconds) return null;
  return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format(format);
};

export const durationToSeconds = (duration) => {
  if (!duration) return null;
  return moment.duration(duration).asSeconds();
};

export const secondsToMinutes = (seconds) => {
  if (!seconds) return null;
  return Math.round(seconds / 60);
};

export const minutesToSeconds = (minutes) => {
  if (!minutes) return null;
  return minutes * 60;
};
