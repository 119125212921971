import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const financeProxyUrl = `${apiUrl}/finance`;

console.log('FINANCE PROXY URI:', financeProxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(financeProxyUrl, 'GET'),
  post: invoke(financeProxyUrl, 'POST'),
  put: invoke(financeProxyUrl, 'PUT'),
  patch: invoke(financeProxyUrl, 'PATCH'),
  delete: invoke(financeProxyUrl, 'DELETE'),
};

export const getInvoicingCompanies = () => api.get('/invoicing-companies');

export const getInvoicePdf = (submissionId, id) => api.get(`/invoices/${submissionId}/${id}/pdf`, { responseType: 'arraybuffer', raw: true });
