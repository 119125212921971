export const set = (key, value) => {
  if (!localStorage) return console.warn('localStorage is not available!');
  localStorage.setItem(key, typeof vaule !== 'string' ? JSON.stringify(value) : value);
};

export const get = (key) => {
  if (!localStorage) return console.warn('localStorage is not available!');

  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
};

export const remove = (key) => {
  if (!localStorage) return console.warn('localStorage is not available!');

  localStorage.removeItem(key);
};