import get from 'lodash/get';
import { actionTypes } from '../actions/client-order';

export const clientOrderDefaultState = {
  id: null,
  apiError: false,
  isLoaded: false,
  isBusy: false,
  error: {
    message: '',
    status: null,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = clientOrderDefaultState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NEW_CLIENT_ORDER_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        isLoaded: true,
        apiError: false,
        isBusy: false,
      };

    case actionTypes.ADD_NEW_CLIENT_ORDER_FAILURE:
    case actionTypes.LOAD_CLIENT_ORDER_FAILURE: {
      const error = get(action.payload, 'error');
      return {
        ...state,
        isBusy: false,
        apiError: true,
        error,
      };
    }

    case actionTypes.LOAD_CLIENT_ORDER_SUCCESS: {
      return {
        ...state,
        ...action.payload.submission,
        isLoaded: true,
        isBusy: false,
        apiError: false,
      };
    }

    case actionTypes.LOAD_CLIENT_ORDER_START:
      return {
        ...state,
        isBusy: true,
      };

    case actionTypes.ADD_NEW_CLIENT_ORDER_START:
      return { ...state, isBusy: true, isCancellationRequested: true };

    case actionTypes.RESET_CLIENT_ORDER: {
      return { ...clientOrderDefaultState };
    }
    case actionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_START: {
      return { ...state, isBusy: true };
    }
    case actionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_SUCCESS:
    case actionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_FAILURE: {
      return { ...state, isBusy: false };
    }
    default:
      return state;
  }
};

export default reducer;
