export const actionTypes = {
  GET_REPORT: 'GET_REPORT',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
  CLEAR_REPORT: 'CLEAR_REPORT',
};

export const getReportStarted = () => ({ type: actionTypes.GET_REPORT });
export const getReportSuccess = payload => ({ type: actionTypes.GET_REPORT_SUCCESS, payload });
export const getReportFailed = payload => ({ type: actionTypes.GET_REPORT_FAILURE, payload });

export const clearReport = () => ({ type: actionTypes.CLEAR_REPORT });
