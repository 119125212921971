import Uppy from '@uppy/core';
import throttle from 'lodash/throttle';
import AwsS3 from '@uppy/aws-s3';
import { getFileName } from '../utils/uppy';

const createUppyClient = ({
  multiple = false,
  autoProceed = false,
  restrictions = {},
  onCreateMultipartUpload = () => { },
  onListParts = () => { },
  onPrepareUploadPart = () => { },
  onAbortMultipartUpload = () => { },
  onCompleteMultipartUpload = () => { },
  onFileAdded = () => { },
  onFileRemoved = () => { },
  onUploadProgress = () => { },
  onUploadSuccess = () => { },
  onUploadComplete = () => { },
  onUploadError = () => { },
  onUploadStarted = () => { },
  onUploadPaused = () => { },
}) => {
  const uppy = new Uppy({
    debug: false,
    autoProceed,
    allowMultipleUploads: multiple,
    restrictions,
  });

  uppy.use(AwsS3, {
    retryDelays: [0, 1000, 3000, 5000],
    shouldUseMultipart: () => true,
    createMultipartUpload: file => onCreateMultipartUpload(file),
    listParts: (file, { uploadId, key }) => onListParts(uploadId, key),
    signPart: (file, { uploadId, partNumber, key }) => onPrepareUploadPart(uploadId, partNumber, key),
    abortMultipartUpload: (file, { uploadId, key }) => onAbortMultipartUpload(uploadId, key),
    completeMultipartUpload: (file, { uploadId, key, parts }) => onCompleteMultipartUpload(uploadId, key, parts),
  });

  uppy.on('file-added', (file) => onFileAdded(file));

  uppy.on('file-removed', (file) => onFileRemoved(file));

  uppy.on('upload-progress', throttle((file, progress) => onUploadProgress(file.id, progress), 500, { leading: true, trailing: true }));

  uppy.on('upload', ({ fileIDs }) => onUploadStarted(fileIDs));

  uppy.on('upload-pause', (fileId, isPaused) => onUploadPaused(fileId, isPaused));

  uppy.on('upload-success', (file) => onUploadSuccess(file.id, file.name.split('.')[0]));

  uppy.on('complete', ({ successful, failed }) => onUploadComplete(successful, failed));

  uppy.on('upload-error', (file, error, response) => {
    console.error('Upload error with file:', file.id, error, response);
    onUploadError(file.id, error);
  });

  const addFiles = ({ folder, files, generateFileName = false }) => {
    files.forEach(file => {
      const name = getFileName(folder, file, generateFileName);

      uppy.addFile({
        name,
        type: file.type,
        data: file,
        meta: {
          fileName: file.name,
        },
      });
    });
  };

  const upload = () => uppy.upload();

  return {
    addFiles,
    upload,
    instance: uppy,
  };
};

export default createUppyClient;