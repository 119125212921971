import axios from 'axios';
import http from 'http';
import { logoutUser } from '../store/thunks/authentication';

const httpAgent = new http.Agent({ keepAlive: false });

/**
 * API Client used by Redux Thunk
 */
export const invoke = (baseURL, method) => (url = '/', {
  body = {},
  headers = {},
  query = {},
  responseType = 'json',
  raw = false,
} = {}) => async (dispatch, _, { cookie }) => {
  try {
    const token = cookie?.getCookie('token');
    const response = await axios({
      method,
      url,
      baseURL,
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...headers,
      },
      data: body,
      params: query,
      responseType,
      httpAgent,
    });

    if (raw) return response;

    return response.data;
  } catch (e) {
    const message = (e && e.response && e.response.data && e.response.data.message) || 'Something went wrong';
    const status = e.response && e.response.status;
    const error = { message, status };

    console.error('API error: ', e); // eslint-disable-line no-console

    if (status === 401 && !url.includes('login') && !url.includes('logout') && typeof dispatch === 'function') {
      return dispatch(logoutUser('Your session is expired!'));
    }

    throw error;
  }
};
