const s = name => `mytasks-${name}`;

export const actionTypes = {
  GET_LIST_START: s('GET_LIST_START'),
  GET_LIST_SUCCESS: s('GET_LIST_SUCCESS'),
  GET_LIST_FAILURE: s('GET_LIST_FAILURE'),

  GET_TABLE_PREFERENCES_SUCCESS: s('GET_TABLE_PREFERENCES_SUCCESS'),
  RESET_TABLE_PREFERENCES: s('RESET_TABLE_PREFERENCES'),
  SET_TABLE_PREFERENCES: s('SET_TABLE_PREFERENCES'),
};

export const getListStart = () => ({
  type: actionTypes.GET_LIST_START,
});

export const getListSuccess = result => ({
  type: actionTypes.GET_LIST_SUCCESS,
  payload: result,
});

export const getListFailure = () => ({
  type: actionTypes.GET_LIST_FAILURE,
});

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });

export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });

export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });

const myTasks = {
  getListStart,
  getListSuccess,
  getListFailure,
  getTablePreferencesSuccess,
  resetTablePreferences,
  setTablePreferences,
};

export default myTasks;
