export const actionTypes = {
  ADD_NEW: 'v2-ADD_NEW',
  RESET: 'v2-RESET',
  SET_META_DATA: 'v2-SET_META_DATA',
  RESET_META_DATA: 'v2-RESET_META_DATA',
  VALIDATE_META_FIELD: 'v2-VALIDATE_META_FIELD',
  VALIDATE_META: 'v2-VALIDATE_META',
  VALIDATE_ITEM_META_FIELD: 'v2-VALIDATE_ITEM_META_FIELD',
  LOAD_PRODUCT_TREE_START: 'v2-LOAD_PRODUCT_TREE_START',
  LOAD_PRODUCT_TREE_SUCCESS: 'v2-LOAD_PRODUCT_TREE_SUCCESS',
  LOAD_PRODUCT_TREE_FAILURE: 'v2-LOAD_PRODUCT_TREE_FAILURE',
  LOAD_PRODUCT_PARAMS_START: 'v2-LOAD_PRODUCT_PARAMS_START',
  LOAD_PRODUCT_PARAMS_SUCCESS: 'v2-LOAD_PRODUCT_PARAMS_SUCCESS',
  LOAD_PRODUCT_PARAMS_FAILURE: 'v2-LOAD_PRODUCT_PARAMS_FAILURE',
  LOAD_EIDR_START: 'v2-LOAD_EIDR_START',
  LOAD_EIDR_SUCCESS: 'v2-LOAD_EIDR_SUCCESS',
  LOAD_EIDR_FAILURE: 'v2-LOAD_EIDR_FAILURE',
  EIDR_CLEAR: 'v2-EIDR_CLEAR',
  VALIDATE_ITEMS_META: 'v2-VALIDATE_ITEMS_META',
  ADD_ITEM: 'v2-ADD_ITEM',
  DELETE_ITEMS: 'v2-DELETE_ITEMS',
  DELETE_ITEM: 'v2-DELETE_ITEM',
  SET_ITEM_METADATA: 'v2-SET_ITEM_METADATA',
  GET_CALENDAR_OFFERS_START: 'GET_CALENDAR_OFFERS_START',
  GET_CALENDAR_OFFERS_SUCCESS: 'GET_CALENDAR_OFFERS_SUCCESS',
  GET_CALENDAR_OFFERS_FAILURE: 'GET_CALENDAR_OFFERS_FAILURE',
  OFFER_RESERVATION_START: 'OFFER_RESERVATION_START',
  OFFER_RESERVATION_SUCCESS: 'OFFER_RESERVATION_SUCCESS',
  OFFER_RESERVATION_FAILURE: 'OFFER_RESERVATION_FAILURE',
  SET_REFERENCE_SUBMISSION: 'SET_REFERENCE_SUBMISSION',
  ADD_BATCH_SUBMISSION_START: 'ADD_BATCH_SUBMISSION_START',
  ADD_BATCH_SUBMISSION_SUCCESS: 'ADD_BATCH_SUBMISSION_SUCCESS',
  ADD_BATCH_SUBMISSION_FAILURE: 'ADD_BATCH_SUBMISSION_FAILURE',

  REBOOK_START: 'REBOOK_START',
  REBOOK_SUCCESS: 'REBOOK_SUCCESS',
  REBOOK_FAILURE: 'REBOOK_FAILURE',

  LOAD_START: 'v2-LOAD_START',
  LOAD_SUCCESS: 'v2-LOAD_SUCCESS',
  LOAD_FAILURE: 'v2-LOAD_FAILURE',

  GET_UPDATABLE_META_FIELDS_START: 'v2-GET_UPDATABLE_META_FIELDS_START',
  GET_UPDATABLE_META_FIELDS_SUCCESS: 'v2-GET_UPDATABLE_META_FIELDS_SUCCESS',
  GET_UPDATABLE_META_FIELDS_FAILURE: 'v2-GET_UPDATABLE_META_FIELDS_FAILURE',

  LOAD_JOURNAL_START: 'v2-LOAD_JOURNAL_START',
  LOAD_JOURNAL_SUCCESS: 'v2-LOAD_JOURNAL_SUCCESS',
  LOAD_JOURNAL_FAILURE: 'v2-LOAD_JOURNAL_FAILURE',

  CANCEL_START: 'v2-CANCEL_START',
  CANCEL_SUCCESS: 'v2-CANCEL_SUCCESS',
  CANCEL_FAILURE: 'v2-CANCEL_FAILURE',

  SYNC_START: 'v2-SYNC_START',
  SYNC_SUCCESS: 'v2-SYNC_SUCCESS',
  SYNC_FAILURE: 'v2-SYNC_FAILURE',

  GET_VIDEO_ASSET_LIST_START: 'v2-GET_VIDEO_ASSET_LIST_START',
  GET_VIDEO_ASSET_LIST_SUCCESS: 'v2-GET_VIDEO_ASSET_LIST_SUCCESS',
  GET_VIDEO_ASSET_LIST_FAILURE: 'v2-GET_VIDEO_ASSET_LIST_FAILURE',

  DOWNLOAD_VIDEO_ASSET_START: 'v2-DOWNLOAD_VIDEO_ASSET_START',
  DOWNLOAD_VIDEO_ASSET_SUCCESS: 'v2-DOWNLOAD_VIDEO_ASSET_SUCCESS',
  DOWNLOAD_VIDEO_ASSET_FAILURE: 'v2-DOWNLOAD_VIDEO_ASSET_FAILURE',

  SIGNAL_START: 'v2-SIGNAL_START',

  CHECK_META_MODIFICATION_START: 'v2-CHECK_META_MODIFICATION_START',
  CHECK_META_MODIFICATION_SUCCESS: 'v2-CHECK_META_MODIFICATION_SUCCESS',
  CHECK_META_MODIFICATION_FAILURE: 'v2-CHECK_META_MODIFICATION_FAILURE',

  SET_META_DATA_FIELD: 'v2-SET_META_DATA_FIELD',

  SET_IS_REBOOK_DIALOG_SHOWN: 'SET_IS_REBOOK_DIALOG_SHOWN',
};

export const addNewSubmission = (clientOrderId) => ({
  type: actionTypes.ADD_NEW, clientOrderId,
});

export const setReferenceSubmission = (referenceSubmission = null, referenceSubmissionByEpisode = null) => ({
  type: actionTypes.SET_REFERENCE_SUBMISSION, referenceSubmission, referenceSubmissionByEpisode,
});

export const reset = () => ({
  type: actionTypes.RESET,
});

export const setMetaData = metaData => ({
  type: actionTypes.SET_META_DATA,
  payload: { meta: metaData },
});

export const resetMetaData = () => ({
  type: actionTypes.RESET_META_DATA,
});

export const validateMetaField = (meta, field, calendars = {}) => ({
  type: actionTypes.VALIDATE_META_FIELD, meta, field, calendars,
});

export const validateMeta = (meta, options = { withoutRCD: false }, calendars = {}) => ({
  type: actionTypes.VALIDATE_META, meta, options, calendars,
});

export const validateItemsMeta = (viewingCalendar) => ({
  type: actionTypes.VALIDATE_ITEMS_META,
  payload: { viewingCalendar },
});

export const validateItemMetaField = (field, itemId) => ({
  type: actionTypes.VALIDATE_ITEM_META_FIELD, field, itemId,
});

export const loadProductTreeStart = () => ({
  type: actionTypes.LOAD_PRODUCT_TREE_START,
});

export const loadProductTreeSuccess = (result) => ({
  type: actionTypes.LOAD_PRODUCT_TREE_SUCCESS,
  payload: { result },
});

export const loadProductTreeFailure = (error) => ({
  type: actionTypes.LOAD_PRODUCT_TREE_FAILURE,
  payload: { error },
});

export const loadProductParamsStart = productPath => ({
  type: actionTypes.LOAD_PRODUCT_PARAMS_START,
  payload: { productPath },
});

export const loadProductParamsSuccess = (result) => ({
  type: actionTypes.LOAD_PRODUCT_PARAMS_SUCCESS,
  payload: { result },
});

export const loadProductParamsFailure = () => ({
  type: actionTypes.LOAD_PRODUCT_PARAMS_FAILURE,
});

export const loadEidrStart = () => ({
  type: actionTypes.LOAD_EIDR_START,
});

export const loadEidrSuccess = (eidr, eidrEditableFields) => ({
  type: actionTypes.LOAD_EIDR_SUCCESS,
  payload: { eidr, eidrEditableFields },
});

export const loadEidrFailure = error => ({
  type: actionTypes.LOAD_EIDR_FAILURE,
  payload: error,
});

export const clearEidr = () => ({
  type: actionTypes.EIDR_CLEAR,
});

export const addItem = (item) => ({
  type: actionTypes.ADD_ITEM,
  payload: item,
});

export const deleteItems = () => ({
  type: actionTypes.DELETE_ITEMS,
});

export const deleteItem = (id) => ({
  type: actionTypes.DELETE_ITEM,
  payload: id,
});

export const setItemMetaData = (field, value, id) => ({
  type: actionTypes.SET_ITEM_METADATA,
  payload: { field, value, id },
});

export const getCalendarOffersStart = () => ({
  type: actionTypes.GET_CALENDAR_OFFERS_START,
});

export const getCalendarOffersSuccess = (payload) => ({
  type: actionTypes.GET_CALENDAR_OFFERS_SUCCESS,
  payload,
});

export const getCalendarOffersFailure = () => ({
  type: actionTypes.GET_CALENDAR_OFFERS_FAILURE,
});

export const reserveOfferStart = () => ({
  type: actionTypes.OFFER_RESERVATION_START,
});

export const reserveOfferSuccess = (payload) => ({
  type: actionTypes.OFFER_RESERVATION_SUCCESS,
  payload,
});

export const reserveOfferFailure = () => ({
  type: actionTypes.OFFER_RESERVATION_FAILURE,
});

export const addBatchSubmissionsFailure = () => ({
  type: actionTypes.ADD_BATCH_SUBMISSION_FAILURE,
});

export const addBatchSubmissionsStart = (payload) => ({
  type: actionTypes.ADD_BATCH_SUBMISSION_START,
  payload,
});

export const addBatchSubmissionsSuccess = () => ({
  type: actionTypes.ADD_BATCH_SUBMISSION_SUCCESS,
});

export const rebookStart = () => ({
  type: actionTypes.REBOOK_START,
});

export const rebookSuccess = (payload) => ({
  type: actionTypes.REBOOK_SUCCESS,
  payload,
});

export const rebookFailure = () => ({
  type: actionTypes.REBOOK_FAILURE,
});

export const loadStart = () => ({ type: actionTypes.LOAD_START });
export const loadSuccess = (submission) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: { submission },
});
export const loadFailure = (payload) => ({
  type: actionTypes.LOAD_FAILURE,
  payload,
});

export const getUpdatableMetaFieldsStart = () => ({
  type: actionTypes.GET_UPDATABLE_META_FIELDS_START,
});
export const getUpdatableMetaFieldsSuccess = (result) => ({
  type: actionTypes.GET_UPDATABLE_META_FIELDS_SUCCESS,
  payload: result,
});
export const getUpdatableMetaFieldsFailure = () => ({
  type: actionTypes.GET_UPDATABLE_META_FIELDS_FAILURE,
});

export const loadJournalStart = () => ({
  type: actionTypes.LOAD_JOURNAL_START,
});
export const loadJournalSuccess = (journal) => ({
  type: actionTypes.LOAD_JOURNAL_SUCCESS,
  payload: { journal },
});
export const loadJournalFailure = (payload) => ({
  type: actionTypes.LOAD_JOURNAL_FAILURE,
  payload,
});

export const cancelStart = () => ({
  type: actionTypes.CANCEL_START,
});
export const cancelSuccess = () => ({
  type: actionTypes.CANCEL_SUCCESS,
});
export const cancelFailure = () => ({
  type: actionTypes.CANCEL_FAILURE,
});

export const syncStart = () => ({
  type: actionTypes.SYNC_START,
});
export const syncSuccess = () => ({
  type: actionTypes.SYNC_SUCCESS,
});
export const syncFailure = () => ({
  type: actionTypes.SYNC_FAILURE,
});

export const getVideoAssetListStart = () => ({
  type: actionTypes.GET_VIDEO_ASSET_LIST_START,
});
export const getVideoAssetListSuccess = (payload) => ({
  type: actionTypes.GET_VIDEO_ASSET_LIST_SUCCESS,
  payload,
});
export const getVideoAssetListFailure = () => ({
  type: actionTypes.GET_VIDEO_ASSET_LIST_FAILURE,
});

export const downloadVideoAssetStart = () => ({
  type: actionTypes.DOWNLOAD_VIDEO_ASSET_START,
});
export const downloadVideoAssetSuccess = () => ({
  type: actionTypes.DOWNLOAD_VIDEO_ASSET_SUCCESS,
});
export const downloadVideoAssetFailure = () => ({
  type: actionTypes.DOWNLOAD_VIDEO_ASSET_FAILURE,
});

export const signalStart = ({ signalName }) => ({
  type: actionTypes.SIGNAL_START,
  payload: { signalName },
});

export const checkMetaModificationStart = (changedMeta) => ({
  type: actionTypes.CHECK_META_MODIFICATION_START,
  payload: { changedMeta },
});
export const checkMetaModificationSuccess = (result) => ({
  type: actionTypes.CHECK_META_MODIFICATION_SUCCESS,
  payload: { result },
});
export const checkMetaModificationFailure = () => ({
  type: actionTypes.CHECK_META_MODIFICATION_FAILURE,
});

export const setIsRebookDialogShown = (isShown) => ({
  type: actionTypes.SET_IS_REBOOK_DIALOG_SHOWN,
  payload: { isShown },
});
