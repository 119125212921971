import { actionTypes } from '../actions/self-rating';

const tableColumns = [
  { columnName: 'id', width: 100 },
  { columnName: 'title', width: 450 },
  { columnName: 'fullName', width: 180 },
  { columnName: 'companyName', width: 180 },
  { columnName: 'status', width: 170 },
  { columnName: 'createdOn', width: 230 },
];

export const selfRatingDefaultState = {
  rows: [],
  count: 0,
  tablePreferences: {
    columnOrder: tableColumns.map(({ columnName }) => columnName),
    columnWidths: tableColumns,
    rowsPerPage: 10,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = selfRatingDefaultState, action) => {
  switch (action.type) {
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: selfRatingDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    default:
      return state;
  }
};

export default reducer;