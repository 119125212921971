export const actionTypes = {
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_FAILURE: 'FETCH_ROLES_FAILURE',
  SAVE_ROLE: 'SAVE_ROLE',
  SAVE_ROLE_SUCCESS: 'SAVE_ROLE_SUCCESS',
  SAVE_ROLE_FAILURE: 'SAVE_ROLE_FAILURE',
  FETCH_ROLE: 'FETCH_ROLE',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  FETCH_ROLE_FAILED: 'FETCH_ROLE_FAILED',
  FETCH_ROLE_USERS: 'FETCH_ROLE_USERS',
  FETCH_ROLE_USERS_SUCCESS: 'FETCH_ROLE_USERS_SUCCESS',
  FETCH_ROLE_USERS_FAILED: 'FETCH_ROLE_USERS_FAILED',
  CLEAR_DETAILS: 'CLEAR_DETAILS', //TODO: check it
  EDIT_ROLE: 'EDIT_ROLE',
  EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
  EDIT_ROLE_FAILED: 'EDIT_ROLE_FAILED',
};

export const fetchRolesStarted = payload => ({ type: actionTypes.FETCH_ROLES, payload });
export const fetchRolesSuccess = payload => ({ type: actionTypes.FETCH_ROLES_SUCCESS, payload });
export const fetchRolesFailed = payload => ({ type: actionTypes.FETCH_ROLES_FAILURE, payload });

export const saveRole = () => ({ type: actionTypes.SAVE_ROLE });
export const saveRoleSucess = () => ({ type: actionTypes.SAVE_ROLE_SUCCESS });
export const saveRoleFailure = error => ({ type: actionTypes.SAVE_ROLE_FAILURE, payload: error });

export const fetchRole = id => ({ type: actionTypes.FETCH_ROLE, payload: id });
export const fetchRoleSuccess = details => ({ type: actionTypes.FETCH_ROLE_SUCCESS, payload: details });
export const fetchRoleFailed = error => ({ type: actionTypes.FETCH_ROLE_FAILED, payload: error });

export const fetchRoleUsers = id => ({ type: actionTypes.FETCH_ROLE_USERS, payload: id });
export const fetchRoleUsersSuccess = users => ({ type: actionTypes.FETCH_ROLE_USERS_SUCCESS, payload: users });
export const fetchRoleUsersFailed = error => ({ type: actionTypes.FETCH_ROLE_USERS_FAILED, payload: error });

export const editRoleStart = () => ({ type: actionTypes.EDIT_ROLE });
export const editRoleSuccess = () => ({ type: actionTypes.EDIT_ROLE_SUCCESS });
export const editRoleFailed = error => ({ type: actionTypes.EDIT_ROLE_FAILED, payload: error });

export const clearDetails = () => ({ type: actionTypes.CLEAR_DETAILS });
