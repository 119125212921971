export const actionTypes = {
  ADD_NEW_CLIENT_ORDER_START: 'ADD_NEW_CLIENT_ORDER_START',
  ADD_NEW_CLIENT_ORDER_SUCCESS: 'ADD_NEW_CLIENT_ORDER_SUCCESS',
  ADD_NEW_CLIENT_ORDER_FAILURE: 'ADD_NEW_CLIENT_ORDER_FAILURE',

  LOAD_CLIENT_ORDER_START: 'LOAD_CLIENT_ORDER_START',
  LOAD_CLIENT_ORDER_SUCCESS: 'LOAD_CLIENT_ORDER_SUCCESS',
  LOAD_CLIENT_ORDER_FAILURE: 'LOAD_CLIENT_ORDER_FAILURE',

  CREATE_EPISODIC_SUBMISSIONS_START: 'CREATE_EPISODIC_SUBMISSIONS_START',
  CREATE_EPISODIC_SUBMISSIONS_SUCCESS: 'CREATE_EPISODIC_SUBMISSIONS_SUCCESS',
  CREATE_EPISODIC_SUBMISSIONS_FAILURE: 'CREATE_EPISODIC_SUBMISSIONS_FAILURE',

  RESET_CLIENT_ORDER: 'RESET_CLIENT_ORDER',

  SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_START: 'SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_START',
  SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_SUCCESS: 'SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_SUCCESS',
  SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_FAILURE: 'SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_FAILURE',

  ADD_NEW_CLIENT_ORDER_PLACEHOLDER_START: 'ADD_NEW_CLIENT_ORDER_PLACEHOLDER_START',
  ADD_NEW_CLIENT_ORDER_PLACEHOLDER_SUCCESS: 'ADD_NEW_CLIENT_ORDER_PLACEHOLDER_SUCCESS',
  ADD_NEW_CLIENT_ORDER_PLACEHOLDER_FAILURE: 'ADD_NEW_CLIENT_ORDER_PLACEHOLDER_FAILURE',
};

export const addNewClientOrderStart = () => ({
  type: actionTypes.ADD_NEW_CLIENT_ORDER_START,
});
export const addNewClientOrderSuccess = clientOrder => ({
  type: actionTypes.ADD_NEW_CLIENT_ORDER_SUCCESS,
  payload: clientOrder,
});
export const addNewClientOrderFailure = () => ({
  type: actionTypes.ADD_NEW_CLIENT_ORDER_FAILURE,
});

export const sendClientOrderGetOffersSignalStart = () => ({
  type: actionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_START,
});
export const sendClientOrderGetOffersSignalSuccess = clientOrderId => ({
  type: actionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_SUCCESS,
  payload: { clientOrderId },
});
export const sendClientOrderGetOffersSignalFailure = () => ({
  type: actionTypes.SEND_CLIENT_ORDER_GET_OFFERS_SIGNAL_FAILURE,
});

export const loadStart = (id) => ({
  type: actionTypes.LOAD_CLIENT_ORDER_START,
  payload: { id },
});
export const loadSuccess = (submission) => ({
  type: actionTypes.LOAD_CLIENT_ORDER_SUCCESS,
  payload: { submission, updateOriginal: true },
});
export const loadFailure = (payload) => ({
  type: actionTypes.LOAD_CLIENT_ORDER_FAILURE,
  payload,
});

export const createEpisodicSubmissionsStart = (id) => ({
  type: actionTypes.CREATE_EPISODIC_SUBMISSIONS_START,
  payload: { id },
});
export const createEpisodicSubmissionsSuccess = (submission) => ({
  type: actionTypes.CREATE_EPISODIC_SUBMISSIONS_SUCCESS,
  payload: { submission, updateOriginal: true },
});
export const createEpisodicSubmissionsFailure = (payload) => ({
  type: actionTypes.CREATE_EPISODIC_SUBMISSIONS_FAILURE,
  payload,
});

export const resetClientOrder = () => ({
  type: actionTypes.RESET_CLIENT_ORDER,
});

export const addNewPlaceholderClientOrderStart = () => ({
  type: actionTypes.ADD_NEW_CLIENT_ORDER_PLACEHOLDER_START,
});

export const addNewPlaceholderClientOrderSuccess = clientOrder => ({
  type: actionTypes.ADD_NEW_CLIENT_ORDER_PLACEHOLDER_SUCCESS,
  payload: clientOrder,
});

export const addNewPlaceholderClientOrderFailure = () => ({
  type: actionTypes.ADD_NEW_CLIENT_ORDER_PLACEHOLDER_FAILURE,
});

const clientOrder = {
  addNewClientOrderStart,
  addNewClientOrderSuccess,
  addNewClientOrderFailure,
  resetClientOrder,
  addNewPlaceholderClientOrderStart,
  addNewPlaceholderClientOrderSuccess,
  addNewPlaceholderClientOrderFailure,
};

export default clientOrder;
