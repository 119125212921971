import { actionTypes } from '../actions/poller';

export const pollerDefaultState = {
  ids: {
    default: null,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = pollerDefaultState, { type, payload = {} }) => {
  switch (type) {
    case actionTypes.SAVE_POLLER_TIMEOUT:
      if (state.ids[payload.label]) {
        clearTimeout(state.ids[payload.label]);
      }
      return {
        ...state,
        ids: {
          ...state.ids,
          [payload.label]: payload.id,
        },
      };
    case actionTypes.CLEAR_POLLER_TIMEOUT:
      if (state.ids[payload.label]) {
        clearTimeout(state.ids[payload.label]);
      }
      return {
        ...state,
        ids: {
          ...state.ids,
          [payload.label]: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
