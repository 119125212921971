import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const googleProxyUrl = `${apiUrl}/google`;

console.log('GOOGLE PROXY URI:', googleProxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(googleProxyUrl, 'GET'),
  put: invoke(googleProxyUrl, 'PUT'),
};

export const getProductParams = productPath => api.get('/productParams', { query: { path: productPath } });

export const fileUploadToDrive = ({ file, userID, companyID, workOrderId, assetId }) => {
  const bodyFormData = new FormData();
  bodyFormData.set('userID', userID);
  bodyFormData.set('companyID', companyID);
  bodyFormData.set('workOrderID', workOrderId);
  bodyFormData.set('assetId', assetId);
  bodyFormData.append('file', file);
  return api.put(
    `/drive/folders/${assetId}/file-proxy`,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      body: bodyFormData,
    }
  );
};

export const createFolderInDrive = folderName => api.put(`/drive/folders/${folderName}`);

const googleClient = {
  getProductParams,
  fileUploadToDrive,
  createFolderInDrive,
};

export default googleClient;
