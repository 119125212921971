export const transformPricingResult = (res) => {
  const { premiums } = res;
  const header = [
    '',
    'Total fee',
  ];
  const rows = [
    ...premiums.flatMap(({ premium, actualPrice }) => {
      if (premium === '-') {
        return [];
      }
      const processedRow = [
        premium,
        `£${actualPrice}`,
      ];
      return [processedRow];
    }),
  ];
  return { header, rows };
};